import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { OrganizationCheckerInput } from 'src/components/organization/OrganizationCheckerInput/OrganizationCheckerInput';
import { ThemeValues } from 'src/constants/theme-values';
import { UserSettingKeys } from 'src/constants/user-settings-keys';
import { useRccContext } from 'src/contexts';
import { useUserSettingsContext } from 'src/contexts/UserSettingsContext';
import { Organization } from 'src/types';

export const InternalOrganizationPreferenceSwitcher = () => {
    const isInitialLoad = useRef(true);
    const inputRef = useRef<HTMLInputElement>();
    const [searchTerm, setSearchTerm] = useState<string>();
    const [activeTerm, setActiveTerm] = useState<string>();
    const { _TEMPORARY_userSettingsMutationEndpoint } = useRccContext();
    const {
        getUserSettingByKey,
        isUserSettingsLoading,
        isUserSettingsValidating,
        isUserSettingsMutating,
        updateUserSettings,
    } = useUserSettingsContext();

    useEffect(() => {
        if (isInitialLoad.current) {
            const defaultOrg = getUserSettingByKey(UserSettingKeys.DefaultOrg) || '';
            setSearchTerm(defaultOrg);
            setActiveTerm(defaultOrg);
            inputRef.current.value = defaultOrg;
            isInitialLoad.current = false;
        }
    }, [getUserSettingByKey]);

    const ctaClickHandler = (organization: Organization) => {
        updateUserSettings({
            key: UserSettingKeys.DefaultOrg,
            value: organization.organizationName,
        });
    };

    return (
        <Box width={ThemeValues.UserConfigSwitcherWidth}>
            <OrganizationCheckerInput
                ref={inputRef}
                onCtaClick={ctaClickHandler}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                activeTerm={activeTerm}
                setActiveTerm={setActiveTerm}
                listSxProps={{
                    rowGap: '8px',
                }}
                isDisabled={
                    isUserSettingsMutating ||
                    isUserSettingsValidating ||
                    isUserSettingsLoading ||
                    !_TEMPORARY_userSettingsMutationEndpoint
                }
            />
        </Box>
    );
};
