import { WeekDay } from '@cbo/shared-library';

export interface DaysConfig {
  [day: string]: {
    id: number;
    label: string;
  };
}

export const daysConfig: DaysConfig = {
  Sunday: {
    id: WeekDay.SUNDAY,
    label: 'Sunday',
  },
  Monday: {
    id: WeekDay.MONDAY,
    label: 'Monday',
  },
  Tuesday: {
    id: WeekDay.TUESDAY,
    label: 'Tuesday',
  },
  Wednesday: {
    id: WeekDay.WEDNESDAY,
    label: 'Wednesday',
  },
  Thursday: {
    id: WeekDay.THURSDAY,
    label: 'Thursday',
  },
  Friday: {
    id: WeekDay.FRIDAY,
    label: 'Friday',
  },
  Saturday: {
    id: WeekDay.SATURDAY,
    label: 'Saturday',
  },
};

export const dayToWeekDayValueMap: {
  [dayIndex: number]: WeekDay;
} = {
  0: WeekDay.SUNDAY,
  1: WeekDay.MONDAY,
  2: WeekDay.TUESDAY,
  3: WeekDay.WEDNESDAY,
  4: WeekDay.THURSDAY,
  5: WeekDay.FRIDAY,
  6: WeekDay.SATURDAY,
};
