import React, { createContext, useContext, useMemo, useEffect, useState } from 'react';
import { Res } from '@cbo/shared-library';

export interface AccountPreferences extends Res.Admin.UserPreferences {
  darkMode?: boolean;
  darkModeText?: string;
}

export interface IAccountPreferencesContext {
  preferences: AccountPreferences | null;
  arePreferencesLoading: boolean | null;
  setPreferences: (preferences: AccountPreferences | null) => void;
  setPreferencesLoading: (isLoading: boolean | null) => void;
}

export const AccountPreferencesContext = createContext<IAccountPreferencesContext>({
  preferences: null,
  arePreferencesLoading: null,
  setPreferences: (_preferences) => null,
  setPreferencesLoading: (_isLoading) => null,
});

AccountPreferencesContext.displayName = 'AccountPreferencesContext';

export const useAccountPreferences = () => useContext(AccountPreferencesContext);

type AccountPreferencesContextProviderProps = {
  preferencesToSet: AccountPreferences | null;
  children: React.ReactNode;
  setDarkMode: (darkMode: boolean) => void;
};

export function AccountPreferencesContextProvider({
  preferencesToSet,
  children,
  setDarkMode,
}: AccountPreferencesContextProviderProps) {
  const [preferences, setPreferences] = useState<AccountPreferences | null>(preferencesToSet);
  const [arePreferencesLoading, setPreferencesLoading] = useState<boolean | null>(null);
  const finalPreferences = useMemo(
    () => ({
      preferences,
      arePreferencesLoading,
      setPreferences,
      setPreferencesLoading,
    }),
    [preferences, arePreferencesLoading]
  );
  useEffect(() => {
    setDarkMode(preferences?.darkMode ?? false);
  }, [preferences, setDarkMode]);
  return <AccountPreferencesContext.Provider value={finalPreferences}>{children}</AccountPreferencesContext.Provider>;
}

export default AccountPreferencesContextProvider;
