import React, { useEffect, useState } from 'react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import { globalConfig } from '../../../configuration';
import CorsErrorModal from './CorsErrorModal';
import AuthRequiredModal from './AuthRequiredModal';
import { useUsers } from '../../contexts/userContext';

interface Props {
  children: React.ReactNode;
}

interface IAuthContext {
  setCorsErrorModalOpen: (open: boolean) => void;
  corsErrorModalOpen: boolean;
  setAuthRequiredModalOpen: (open: boolean) => void;
  authRequiredModalOpen: boolean;
}

const AuthContext = React.createContext<IAuthContext>({
  setCorsErrorModalOpen: (_corsErrorModalOpen) => null,
  corsErrorModalOpen: false,
  setAuthRequiredModalOpen: (_authRequiredModalOpen) => null,
  authRequiredModalOpen: false,
});

export const useAuth = () => React.useContext(AuthContext);

export function AuthWrapper(props: Props) {
  const { children } = props;
  const [oktaAuth] = useState(() =>
    globalConfig.config.oktaConfig ? new OktaAuth(globalConfig.config.oktaConfig.oidc) : null
  );
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState<boolean>(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState<boolean>(false);

  const history = useHistory(); // example from react-router
  const user = useUsers();

  const { t } = useTranslation();

  const triggerLogin = async () => {
    if (oktaAuth && user) {
      await oktaAuth.signInWithRedirect();
    }
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth ? oktaAuth.authStateManager.getPreviousAuthState() : '';
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    } else {
      setAuthRequiredModalOpen(true);
    }
  };

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  useEffect(() => {
    setCorsErrorModalOpen(false);
    setAuthRequiredModalOpen(false);
  }, [setCorsErrorModalOpen, setAuthRequiredModalOpen]);

  const value = React.useMemo(
    () => ({
      setCorsErrorModalOpen,
      corsErrorModalOpen,
      setAuthRequiredModalOpen,
      authRequiredModalOpen,
    }),
    [corsErrorModalOpen, authRequiredModalOpen]
  );

  return oktaAuth ? (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
      <>
        <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
        <AuthRequiredModal {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }} />
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      </>
    </Security>
  ) : (
    <p style={{ color: 'red', textAlign: 'center' }}>{t('errors.oktaConfigError')}</p>
  );
}
