export enum DateTimeStyle {
  FULL = 'full',
  LONG = 'long',
  MEDIUM = 'medium',
  SHORT = 'short',
}

export enum DateRangeValueTypes {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
}

export enum DateRangeSelectionTypes {
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
  YEAR = 'Year',
}

export interface DateFormatOptions {
  dateStyle?: DateTimeStyle;
  timeZone?: string;
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  day?: 'numeric' | '2-digit';
}

export interface DateFormat {
  locale?: string; // Optional. By default locale is determined from the key in the Region settings
  options?: DateFormatOptions;
  dateFormatString?: string;
  dateTimeFormatString?: string;
}

export interface TimeFormatOptions {
  hour12?: boolean;
  timeStyle?: DateTimeStyle;
  timeZone?: string;
}

export interface TimeFormat {
  locale?: string; // Optional. By default locale is determined from the key in the Region settings
  options?: TimeFormatOptions;
}
