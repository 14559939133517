/* eslint-disable i18next/no-literal-string */
// file: Components.tsx
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Ty from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Req } from '@cbo/shared-library';
import useCallBsl from '../../utils/hooks/useCallBsl';

/** Our fake 'app' page contents */
export function Page() {
  const [apiResult, setApiResult] = useState('');
  const callBsl = useCallBsl();

  // this is a sample BSP endpoint just for demo/testing purposes
  // 'https://api.ncr.com/security/role-grants/user-grants/self/effective-roles';
  const PROXY = 'security.role-grants';
  const PATH_SEGMENTS = ['user-grants', 'self', 'effective-roles'];

  // demo to show bsp call thru firebase function proxy
  async function fetchBspData(): Promise<void> {
    try {
      const res = await callBsl<{ type: 'expected-type-goes-here' }>({
        proxy: PROXY,
        pathSegments: PATH_SEGMENTS,
        verb: Req.Firebase.HttpVerb.GET,
      });

      setApiResult(JSON.stringify(res));
    } catch (err: unknown) {
      setApiResult(JSON.stringify(err));
    }
  }

  return (
    <Box
      display='flex'
      justifyContent='flexStart'
      paddingTop='100px'
      alignItems='center'
      height='100%'
      maxWidth='68ch'
      margin='auto'
      flexDirection='column'
      data-testid='temp-home-page'
    >
      <Button variant='outlined' sx={{ m: 1 }} onClick={() => fetchBspData()}>
        Send Mock API Call to BSP
      </Button>
      {apiResult && (
        <Ty align='center' variant='body2' sx={{ marginY: '24px' }}>
          BSP API Result: GET (thru firebase function proxy):
          <br />
          {apiResult}
        </Ty>
      )}
    </Box>
  );
}

/** The content that will go in the app bar */
export function AppBar({
  openSidebar,
  setSidebar,
  setRightPanel,
  openRightPanel,
}: {
  openSidebar: boolean;
  // eslint-disable-next-line no-unused-vars
  setSidebar: (value: boolean) => void;
  openRightPanel: 'primary' | 'secondary' | null;
  // eslint-disable-next-line no-unused-vars
  setRightPanel: (value: 'primary' | 'secondary' | null) => void;
}) {
  const toggleRightDrawer = (drawer: 'primary' | 'secondary') => () =>
    openRightPanel === drawer ? setRightPanel(null) : setRightPanel(drawer);
  const toggleSidebar = () => setSidebar(!openSidebar);

  return (
    <Box padding={2} display='flex' justifyContent='flex-start' alignItems='center' gap={2} width='100%' height='100%'>
      <IconButton onClick={toggleSidebar}>
        <MenuIcon />
      </IconButton>
      <Ty>Title</Ty>
      <Button sx={{ marginInlineStart: 'auto' }} onClick={toggleRightDrawer('primary')}>
        Primary
      </Button>
      <Button onClick={toggleRightDrawer('secondary')}>Secondary</Button>
    </Box>
  );
}

function makeDrawerContent(text: string) {
  return (
    <Box padding={6} width='100%' height='100%'>
      <Ty>{text}</Ty>
    </Box>
  );
}

export function Sidebar(): JSX.Element {
  return makeDrawerContent('Sidebar');
}
export function RightPanelPrimary(): JSX.Element {
  return makeDrawerContent('Right Panel Primary');
}
export function RightPanelSecondary(): JSX.Element {
  return makeDrawerContent('Right Panel Secondary');
}

export function NotFoundDemo() {
  return (
    <Box
      display='flex'
      justifyContent='flexStart'
      paddingTop='100px'
      alignItems='center'
      height='100%'
      maxWidth='68ch'
      margin='auto'
      flexDirection='column'
      data-testid='404'
    >
      <main>
        <Ty variant='h4' sx={{ marginBottom: '24px' }}>
          Page Not Found
        </Ty>
      </main>
      <nav>
        <Link to='/'>Home</Link>
      </nav>
    </Box>
  );
}
