import { MonthPattern } from '@cbo/shared-library';

export interface QuarterStylesConfig {
  [quarterStyle: string]: {
    id: string;
    label: string;
  };
}

export const quarterStylesConfig: QuarterStylesConfig = {
  '4-4-5': {
    id: MonthPattern['4-4-5'],
    label: MonthPattern['4-4-5'],
  },
  '4-5-4': {
    id: MonthPattern['4-5-4'],
    label: MonthPattern['4-5-4'],
  },
  '5-4-4': {
    id: MonthPattern['5-4-4'],
    label: MonthPattern['5-4-4'],
  },
};
