import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../contexts/SnackbarContext';

type ErrorFallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

function ErrorFallback(props: ErrorFallbackProps) {
  const { error, resetErrorBoundary } = props;
  const { setSnackbarState } = useSnackbar();
  const { t } = useTranslation();
  const errorMessage = t(`${error.message ?? error}`);

  useEffect(() => {
    setSnackbarState({ open: true, message: `${errorMessage}`, color: 'error' });
    resetErrorBoundary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
}

export default ErrorFallback;
