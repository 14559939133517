import { Switcher } from 'src/components/common/Switcher/Switcher';
import HierarchyIcon from 'src/components/common/Icons/Hierarchy';
import {
    OrganizationInternalDialog
} from 'src/components/organization/OrganizationInternalDialog/OrganizationInternalDialog';
import { useOrganizationContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { Fragment, ReactNode } from 'react';


export const InternalOrganizationSwitcher = () => {
    const { t } = useTranslationNs();
    const { setIsOrgDialogOpen, organization, isOrgDialogOpen, isLoading } =
        useOrganizationContext();

    let switcherLabel: ReactNode;
    if (!organization) {
        switcherLabel = t('ORG_CONTEXT.SWITCHER.SELECT');
    } else switcherLabel = organization?.displayName || organization?.organizationName;

    const handleOpenInternalDialog = () => {
        setIsOrgDialogOpen(true);
    };

    const handleCloseInternalDialog = () => {
        setIsOrgDialogOpen(false);
    };

    return (
        <Fragment>
            <Switcher
                tooltipTitle={
                    organization
                        ? organization.organizationName
                        : t('ORG_CONTEXT.ACCESSIBILITY.SWITCHER')
                }
                enableSwitcher={true}
                isLoading={isLoading}
                onClick={handleOpenInternalDialog}
                startIcon={<HierarchyIcon />}
            >
                {switcherLabel}
            </Switcher>
            <OrganizationInternalDialog
                isOpen={isOrgDialogOpen}
                onClose={handleCloseInternalDialog}
            />
        </Fragment>
    );
};
