import { ListItemProps, SxProps, Theme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { ReactNode } from 'react';

function DialogListItemActionButton({
  children,
  sx,
  selected,
  onListItemButtonClick,
  ...listItemProps
}: {
  children: ReactNode;
  sx: SxProps<Theme>;
  selected: boolean;
  onListItemButtonClick: () => void;
} & ListItemProps) {
  return (
    <ListItem sx={sx} {...listItemProps}>
      <ListItemButton
        selected={selected}
        sx={{
          margin: '4px 0',
          padding: '8px 0px 8px 12px',
          minHeight: '48px',
          borderRadius: '8px',
        }}
        onClick={onListItemButtonClick}
      >
        {children}
      </ListItemButton>
    </ListItem>
  );
}

export default DialogListItemActionButton;
