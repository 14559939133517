import { createContext, useContext, useMemo } from 'react';
import type { ReactNode } from 'react';
import { normalizePath } from './navigationUtils';

export type NavigationPath = string | { pathname: string };

export type NavigationProps = {
  selection: NavigationPath;
  view: NavigationPath;
};

export const NavigationContext = createContext<NavigationProps>({
  selection: '',
  view: '',
});

export const useNavigation = () => useContext(NavigationContext);

export function NavigationProvider(
  props: {
    // eslint-disable-next-line react/require-default-props
    children?: ReactNode;
  } & NavigationProps
) {
  const { children, selection: selectionProp, view: viewProp } = props;

  const selection = useMemo(() => normalizePath(selectionProp), [selectionProp]);
  const view = useMemo(() => normalizePath(viewProp), [viewProp]);

  return (
    <NavigationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        selection,
        view,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
}
