import { PersonaRole } from '@cbo/shared-library';
import { User } from '../models/User';

export const loggedInAsPersonaOnly = (user: User, personaRole: PersonaRole): boolean => {
  const userPersonas = user.bslAuth?.personas || [];

  return userPersonas.includes(personaRole) && userPersonas.length === 1;
};

export const loggedInAsPersona = (user: User, personaRoles: PersonaRole[]): boolean => {
  const userPersonas = user.bslAuth?.personas || [];

  return personaRoles.some((item) => userPersonas.includes(item));
};

export const loggedInAsAccountantOnly = (user: User): boolean => loggedInAsPersonaOnly(user, PersonaRole.ACCOUNTANT);

export const loggedInAsEmployeeOnly = (user: User): boolean => loggedInAsPersonaOnly(user, PersonaRole.EMPLOYEE);
