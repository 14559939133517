import { Person } from '@mui/icons-material';
import { Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Fragment, MutableRefObject, useRef, useState } from 'react';
import { Global } from 'src/constants/global';
import { useOrganizationContext, useRccContext } from 'src/contexts';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { UserProfileOption } from 'src/types/user-profile.type';
import { ProfileIcon } from '../common/ProfileIcon/ProfileIcon';
import { UserProfileSettingsDrawer } from './UserProfileSettingsDrawer';
import { UserProfileSettingsMenu } from './UserProfileSettingsMenu';
import { UserSettingsModal } from '../user-settings/UserSettingsModal';
import { getContextRedirectUrl } from 'src/utils/navigation.utils';
import SettingsIcon from '@mui/icons-material/Settings';

/**
 * MARKET-7454
 * showSettings is temporary until the default organizations feature is ready for broad use
 */
export const UserProfileSettings = ({
    inputOptions = [],
    showSettings = false,
    accountUrlOverride,
}: {
    inputOptions?: UserProfileOption[];
    showSettings?: boolean;
    accountUrlOverride?: string;
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [isUserSettingsModalOpen, setIsUserSettingsModalOpen] = useState(false);
    const { t } = useTranslationNs({
        keyPrefix: 'USER_PROFILE',
    });
    const { organization } = useOrganizationContext();
    const isMobile = useMobileView();
    const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const { userInfo } = useRccContext();

    const navigationSettingOptions: UserProfileOption[] = [
        {
            icon: <Person color='inherit' fontSize='medium' />,
            label: t('ACCOUNT'),
            url:
                accountUrlOverride ||
                getContextRedirectUrl(
                    organization?.organizationName,
                    `${Global.IdmUrl}/user-profile`,
                ),
            linkProps: {
                target: '_blank',
            },
        },
        {
            icon: <SettingsIcon color='inherit' fontSize='medium' />,
            label: t('SETTINGS'),
            hidden: !showSettings,
            onClick: () => {
                setIsUserSettingsModalOpen(true);
            },
        },
        ...inputOptions,
    ].filter((option: UserProfileOption) => !option.hidden);

    const handleMenuOpen = () => {
        setIsMenuOpen(true);
    };
    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };
    const handleUserSettingsModalClose = () => {
        setIsUserSettingsModalOpen(false);
    };

    if (!userInfo) {
        return (
            <Skeleton
                variant='circular'
                sx={{
                    margin: '8px',
                    height: '32px',
                    width: '32px',
                }}
            />
        );
    }

    return (
        <Fragment>
            <Button
                onClick={handleMenuOpen}
                ref={buttonRef}
                type='button'
                sx={{ borderRadius: '50%', padding: '8px', minWidth: 'unset' }}
            >
                <ProfileIcon userInfo={userInfo} />
            </Button>
            {isMobile ? (
                <UserProfileSettingsDrawer
                    menuOpen={isMenuOpen}
                    options={navigationSettingOptions}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                />
            ) : (
                <UserProfileSettingsMenu
                    options={navigationSettingOptions}
                    anchorRef={buttonRef}
                    menuOpen={isMenuOpen}
                    onMenuClose={handleMenuClose}
                />
            )}
            <UserSettingsModal
                onClose={handleUserSettingsModalClose}
                isOpen={isUserSettingsModalOpen}
            />
        </Fragment>
    );
};
