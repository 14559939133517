import { Theme } from '@mui/material';
import { forwardRef } from 'react';
import { Switcher, SwitcherProps } from 'src/components/common/Switcher/Switcher';
import { ThemeValues } from 'src/constants/theme-values';

export type PrefrenceSwitcherProps = Omit<SwitcherProps, 'loadingButtonSx' | 'labelSx'>;

export const PreferenceSwitcher = forwardRef<HTMLButtonElement, PrefrenceSwitcherProps>(
    ({ children, ...props }: PrefrenceSwitcherProps, ref) => {
        return (
            <Switcher
                {...props}
                ref={ref}
                loadingButtonSx={{
                    border: '1px solid',
                    borderColor: (theme: Theme) => theme.palette.action.active,
                    padding: '10px 12px',
                    width: ThemeValues.UserConfigSwitcherWidth,
                    maxWidth: 'unset',
                    '.MuiLoadingButton-loadingIndicator': {
                        left: '12px',
                    },
                }}
                labelSx={{
                    fontWeight: 500,
                }}
            >
                {children}
            </Switcher>
        );
    },
);
