import { Link } from 'react-router-dom';
import { forwardRef, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import { isExternalLink } from '../../utils';

interface LinkComponentProps {
  href: string | undefined;
  className: string | undefined;
}

export const LinkComponent = forwardRef<HTMLAnchorElement, PropsWithChildren<LinkComponentProps>>((props, ref) => {
  if (!props.href) {
    return <Box>{props.children}</Box>;
  }
  return isExternalLink(props.href) ? (
    <Link {...props} to={{ pathname: props.href }} target='_blank' ref={ref}>
      {props.children}
    </Link>
  ) : (
    <Link {...props} to={props.href} ref={ref}>
      {props.children}
    </Link>
  );
});

export default LinkComponent;
