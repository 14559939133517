import { useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Pagination from '@mui/material/Pagination';
import Ty from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Res } from '@cbo/shared-library';
import DialogWrapper from '../../components/Dialog/DialogWrapper';
import NotificationCard from '../../components/RightPanel/Notifications/NotificationCard/NotificationCard';
import { useAdminRequests } from '../requests';
import { useNotificationsDataQuery } from '../requests/queries';
import { useUsers } from '../../contexts/userContext';

interface NotificationsDialogProps {
  open: boolean;
  notifications: Res.Notifications.Notification[];
  page: number;
  itemsPerPage: number;
  numberOfPages: number;
  isFullScreen: boolean;
  handleClose: () => void;
  handlePageChange: (_event: React.ChangeEvent<unknown>, value: number) => void;
}

interface NotificationDialogTitleProps {
  title: string;
  unreadNotifications: number;
  handleClose: () => void;
}

// Dialog title component
function NotificationsDialogTitle(props: NotificationDialogTitleProps) {
  const user = useUsers();
  const { refetch: refetchNotifications } = useNotificationsDataQuery(user.fullyAuthenticated === 'authenticated');

  const { t } = useTranslation();
  const { markAllNotificationAsRead } = useAdminRequests();
  const { title, unreadNotifications, handleClose } = props;

  async function markAllAsRead() {
    await markAllNotificationAsRead();
    await refetchNotifications();
  }

  return (
    <Box>
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Ty data-testid='notifications-dialog-title' variant='h6'>
          {title}
        </Ty>
        <IconButton
          data-testid='notifications-dialog-close-btn'
          aria-label={t('buttonText.close')}
          onClick={handleClose}
          sx={{
            position: 'relative',
            top: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {/* sub header */}
      <Box
        sx={{
          paddingTop: 3.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Ty data-testid='new-notifications-indicator' variant='caption' color='text.secondary'>
          {t('admin.allNotificationsDialog.newIndicator', {
            indicatorNumber: unreadNotifications,
          })}
        </Ty>
        <Button size='large' onClick={() => markAllAsRead()}>
          {t('admin.allNotificationsDialog.markAllAsRead')}
        </Button>
      </Box>
    </Box>
  );
}

function NotificationsDialog(props: NotificationsDialogProps) {
  const { t } = useTranslation();
  const { open, notifications, page, itemsPerPage, numberOfPages, isFullScreen, handleClose, handlePageChange } = props;
  const [paginationPadding, setPaginationPadding] = useState('0px');

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, page * itemsPerPage - notifications.length) : 0;
  const minHeight = isFullScreen ? 131 : 108;

  const unreadNotifications = notifications.filter((item) => !item.read).length;

  const calculatePaginationPadding = () => {
    if (isFullScreen) {
      return '24px 0px 16px 0px';
    }

    if (itemsPerPage < 5) {
      return '16px 0px';
    }

    return '24px 0px 50px 0px';
  };

  useEffect(() => {
    setPaginationPadding(calculatePaginationPadding());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage]);

  return (
    <DialogWrapper
      title={
        <NotificationsDialogTitle
          title={t('admin.allNotificationsDialog.title')}
          unreadNotifications={unreadNotifications}
          handleClose={handleClose}
        />
      }
      dialogOptions={{
        open,
        onClose: handleClose,
        maxWidth: 'xl',
        fullWidth: true,
        fullScreen: isFullScreen,
        sx: {
          '& .MuiDialogTitle-root': {
            padding: isFullScreen ? '28px 16px 0px 16px' : '16px 32px 0px 32px',
          },
          '& .MuiDialogContent-root': {
            padding: isFullScreen ? '0px 0px 16px 0px' : '0px 32px 20px 32px',
          },
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Notification list */}
          <Box>
            <Divider />
            <List data-testid='all-notifications-list' disablePadding>
              {notifications.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((notification, index) => (
                <Box key={`notification-card-${notification.id}`}>
                  <ListItem data-testid={`notification-card-${index}`} disablePadding>
                    <NotificationCard notification={notification} renderDelete minHeightInPx={`${minHeight}px`} />
                  </ListItem>

                  <Divider />
                </Box>
              ))}
            </List>

            {emptyRows > 0 && (
              <Box
                data-testid='notifications-empty-rows'
                sx={{
                  height: minHeight * emptyRows,
                }}
              />
            )}
          </Box>

          {/* Pagination control */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: paginationPadding,
            }}
          >
            <Pagination
              data-testid='notifications-pagination'
              count={numberOfPages}
              page={page}
              onChange={handlePageChange}
              defaultPage={1}
              size={isFullScreen ? 'medium' : 'large'}
            />
          </Box>
        </Box>
      </DialogContent>
    </DialogWrapper>
  );
}

export default NotificationsDialog;
