import { useTranslation } from 'react-i18next';
import LaborRuleBaseConfirmationModal, {
  ConfirmationFormValues,
} from '../LaborRuleBaseConfirmationModal/LaborRuleBaseConfirmationModal';

interface DeleteRulesModalProps {
  handleDelete: (confirmData: ConfirmationFormValues) => Promise<void>;
  handleClose: () => void;
  loading?: boolean;
}

export default function DeleteRulesModal(props: DeleteRulesModalProps) {
  const { handleDelete, handleClose, loading } = props;
  const { t } = useTranslation();

  return (
    <LaborRuleBaseConfirmationModal
      title={t('laborRules.deleteRuleModal.title')}
      dataTestId='delete-rules-confirmation-modal'
      ctaText={t('buttonText.delete')}
      subHeaderText={t('laborRules.deleteRuleModal.subHeaderText')}
      handleClose={handleClose}
      ctaAction={handleDelete}
      dateReadOnly
      ctaRed
      loading={loading}
    />
  );
}

DeleteRulesModal.defaultProps = {
  loading: false,
};
