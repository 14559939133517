import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { LaborRuleTemplateProps } from '../LaborRuleTemplate/LaborRuleTemplate';
import RuleTypography from '../RuleTypography/RuleTypography';
import RuleTypographyForm from '../RuleTypographyForm/RuleTypographyForm';
import { ChangeType, LaborRule } from '../models/LaborRuleConfiguration';
import { getPredictabilityPayTemplates, isPredictabilityPayRule } from '../utils/utils';

function ConditionalFrame(IfComponent: JSX.Element, ThenComponent: JSX.Element) {
  const { t } = useTranslation();
  const block = (affix: string, components: JSX.Element | JSX.Element[] | undefined, id: number) => (
    <Grid item key={affix + id} sx={{ display: 'flex' }}>
      <Typography sx={{ fontWeight: 'bold', width: '4.75rem' }}>{affix}</Typography>
      {components}
    </Grid>
  );
  const componentTuples: [string, JSX.Element | JSX.Element[] | undefined][] = [
    [t('laborRules.if'), IfComponent],
    [t('laborRules.then'), ThenComponent],
  ];
  return (
    <Grid container sx={{ p: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {componentTuples.map((tuple, i) => tuple[1] && block(...tuple, i))}
    </Grid>
  );
}

export default function ConditionalTemplate(props: LaborRuleTemplateProps) {
  const { editable, id, rule } = props;
  const [currentRule, setCurrentRule] = useState(rule);
  const { t } = useTranslation();

  /**
   * This ensures changing the dropdown values updates the template string immediately
   * Useful for rules like MissedBreakRule and RightToRestRule
   * where changing the dropdown values requires getting a new template string
   */
  const handleSelectChange = (updatedRule: LaborRule) => {
    setCurrentRule(updatedRule);
  };

  let ifTemplate;
  let thenTemplate;
  if (isPredictabilityPayRule(currentRule)) {
    [ifTemplate, thenTemplate] = getPredictabilityPayTemplates(currentRule, t);
  } else {
    ifTemplate = t(`laborRules.rules.${currentRule.type}.template.if`);
    thenTemplate = t(`laborRules.rules.${currentRule.type}.template.then`);
  }

  const form = (template: string) => (
    <RuleTypographyForm
      data-testid={`rule-typography-form-${id}`}
      formRuleIndex={id}
      templateString={template}
      onSelectChange={handleSelectChange}
    />
  );
  const staticTypography = (changeOrRule: LaborRule | ChangeType, template: string) => (
    <RuleTypography
      data-testid={`rule-typography-${id}`}
      rule={changeOrRule}
      templateString={template}
      isDrawer={false}
    />
  );
  return editable
    ? ConditionalFrame(form(ifTemplate), form(thenTemplate))
    : ConditionalFrame(staticTypography(currentRule, ifTemplate), staticTypography(currentRule, thenTemplate));
}
