import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, Stack, Tab, Tabs, Theme, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { ThemeValues } from 'src/constants/theme-values';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { UserPreferences } from './preferences/UserPreferences';

const a11yTabPrefix = 'account-tab';
const a11yTabPanelPrefix = 'account-tabpanel';
const a11yProps = (index: number) => ({
    id: `${a11yTabPrefix}-${index}`,
    'aria-controls': `${a11yTabPanelPrefix}-${index}`,
});

const wrapperStyles: { width: string; maxWidth: string } = {
    width: '100%',
    maxWidth: '820px',
};

type TabPanelProps = {
    children?: ReactNode;
    index: number;
    value: number;
};

type TabData = {
    title: string;
    component: ReactNode;
};

const UserSettingsTabPanel = ({ children, value, index }: TabPanelProps) => {
    return (
        <Box
            role='tabpanel'
            hidden={value !== index}
            id={`${a11yTabPanelPrefix}-${index}`}
            aria-labelledby={`${a11yTabPrefix}-${index}`}
        >
            {value === index && (
                <Stack alignItems='center' padding={ThemeValues.UserSettingsPadding}>
                    <Box {...wrapperStyles}>{children}</Box>
                </Stack>
            )}
        </Box>
    );
};

export const UserSettingsModal = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) => {
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS',
    });
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const tabs: TabData[] = [
        {
            title: t('PREFERENCES.TITLE'),
            component: <UserPreferences />,
        },
    ];

    const handleTabChange = (_, newValue: number) => {
        setActiveTabIndex(newValue);
    };

    return (
        <Modal onClose={onClose} open={isOpen}>
            <Box
                width='100%'
                height='100%'
                sx={{
                    backgroundColor: (theme: Theme) => theme.palette.background.default,
                }}
            >
                <Stack
                    sx={{ padding: '12px', width: '100%' }}
                    justifyContent='flex-end'
                    direction='row'
                >
                    <IconButton title={t('CLOSE_BUTTON_TITLE')} onClick={onClose}>
                        <Close />
                    </IconButton>
                </Stack>
                <Stack
                    alignItems='center'
                    sx={{
                        padding: `8px ${ThemeValues.UserSettingsPadding} 0px`,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <Stack alignItems='flex-start' rowGap='24px' {...wrapperStyles}>
                        <Typography fontWeight={600} variant='h4'>
                            {t('TITLE')}
                        </Typography>

                        <Tabs value={activeTabIndex} onChange={handleTabChange}>
                            {tabs.map((td: TabData, idx: number) => (
                                <Tab
                                    sx={{ textTransform: 'none' }}
                                    key={td.title}
                                    label={td.title}
                                    {...a11yProps(idx)}
                                />
                            ))}
                        </Tabs>
                    </Stack>
                </Stack>
                {tabs.map((td: TabData, idx: number) => (
                    <UserSettingsTabPanel key={td.title} value={activeTabIndex} index={idx}>
                        {td.component}
                    </UserSettingsTabPanel>
                ))}
            </Box>
        </Modal>
    );
};
