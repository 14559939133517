import { IDate, IDateRange, ITranslatableKey, MultiselectOption, Period, SiteInfo } from '@cbo/shared-library';
import { IPeriodFilter } from '@cbo/shared-library/response/calendar.response';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { DateRangeSelectionTypes, DateRangeValueTypes } from '../../models/config';

export type Map = {
  [key: string]: string;
};

const formatFilterDate = (value: IDate): IDate => ({ date: dayjs(value.date).format('YYYY/MM/DD') });

const formatFilterDateRange = (value: IDateRange): IDateRange => ({
  startDate: dayjs(value.startDate).format('YYYY/MM/DD'),
  endDate: dayjs(value.endDate).format('YYYY/MM/DD'),
});

export const findCalendarFilter = (
  filters: IPeriodFilter[],
  key: string,
  dateRange = true
): IDate | IDateRange | undefined => {
  const filter = filters.find(
    (periodFilter) => (periodFilter.current as ITranslatableKey<Period | string>).key === key
  );
  if (!filter) return undefined;
  return dateRange ? formatFilterDateRange(filter.current as IDateRange) : formatFilterDate(filter.current as IDate);
};

export const getFilterSites = (sitesRecords: Record<string, SiteInfo>, siteIdSiteNames: string) => {
  let sites = Object.keys(sitesRecords);
  if (siteIdSiteNames) {
    const splitSites = siteIdSiteNames.split(',');
    if (splitSites.length > 0) {
      sites = splitSites.map((x) => {
        const fSite = Object.values(sitesRecords).find((site) =>
          site.referenceId ? `${site.referenceId} - ${site.name}` === x : `${site.name} - ${site.name}` === x
        );
        return fSite?.enterpriseUnitId || '';
      });
    }
  }

  return sites;
};

// Function to get filter request data after updating
export const getFormattedFilterRequest = (
  dateRange: string | undefined,
  sites: Record<string, SiteInfo>,
  siteIdSiteName: string
) => {
  const dates =
    dateRange && dateRange !== DateRangeValueTypes.TODAY && dateRange !== DateRangeValueTypes.YESTERDAY
      ? dateRange.split(' to ')
      : [];

  const enterpriseUnitIds = getFilterSites(sites, siteIdSiteName);
  const formattedStartDate = dayjs(dates[0]).format('YYYY-MM-DD');
  const formattedEndDate = dayjs(dates[1]).subtract(1, 'd').format('YYYY-MM-DD');

  const validRequest = dayjs(formattedEndDate).diff(formattedStartDate, 'day') >= 0;
  const request = {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    siteIds: enterpriseUnitIds,
  };

  return { validRequest, request };
};

// Function for new MUI filters
// TODO Anisha clean up: Replace function above once all report filters are done
export const getMuiFormattedFilterRequest = (
  startDate: string | null,
  endDate: string | null,
  sites: MultiselectOption[]
) => {
  if (!startDate || !endDate || sites.length === 0) {
    return {
      validRequest: false,
      request: {
        startDate: '',
        endDate: '',
        siteIds: [],
      },
    };
  }

  const enterpriseUnitIds = sites.map((site) => site.enterpriseUnitId ?? '');
  const validRequest = dayjs(endDate).diff(startDate, 'day') >= 0;
  const request = {
    startDate,
    endDate,
    siteIds: enterpriseUnitIds,
  };
  return { validRequest, request };
};

export const getOptionLabels = (options: MultiselectOption[]) => options.map((option) => option.label);

export const siteAndDateSchema = yup.object({
  sites: yup.array().of(yup.object().shape({ label: yup.string(), enterpriseUnitId: yup.string() })),
  dateRange: yup.object().shape({ startDate: yup.date().required(), endDate: yup.date().required() }),
});

export const siteAndDateComparisonSchema = yup.object({
  sites: yup.array().of(yup.object().shape({ label: yup.string(), enterpriseUnitId: yup.string() })),
  dateRange: yup.object().shape({ startDate: yup.date().required(), endDate: yup.date().required() }),
  comparedDateRange: yup.object().shape({ startDate: yup.date().required(), endDate: yup.date().required() }),
});

export const getSchemaResolver = (extraFields: string[] = [], hasComparedDateRange = false) => {
  const newValues: Record<string, yup.AnySchema> = {};
  extraFields.forEach((element) => {
    newValues[element] = yup
      .array()
      .of(yup.object().shape({ label: yup.string(), enterpriseUnitId: yup.string(), subtext: yup.string() }));
  });
  return yup.object({
    sites: yup.array().of(yup.object().shape({ label: yup.string(), enterpriseUnitId: yup.string() })),
    dateRange: yup.object().shape({ startDate: yup.date().required(), endDate: yup.date().required() }),
    ...(hasComparedDateRange && {
      comparedDateRange: yup.object().shape({ startDate: yup.date().required(), endDate: yup.date().required() }),
    }),
    ...newValues,
  });
};

export const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');

export const today = dayjs().format('YYYY-MM-DD');

export const handleSelectionType = (startDate: string, endDate: string) => {
  // This is just an approximation to handle setting the x-axis scale of visualizations
  const startDateDayjs = dayjs(startDate);
  const endDateDayjs = dayjs(endDate);

  const dateDiff = endDateDayjs.diff(startDateDayjs, 'day');
  if (dateDiff === 0) {
    return DateRangeSelectionTypes.DAY;
  }
  if (dateDiff > 0 && dateDiff < 8) {
    return DateRangeSelectionTypes.WEEK;
  }
  if (dateDiff > 7 && dateDiff < 32) {
    return DateRangeSelectionTypes.MONTH;
  }
  return DateRangeSelectionTypes.YEAR;
};

// Helper function to get end date for filters if you are disabling future dates
export const getEndDate = (date: string) => {
  if (dayjs(date).isAfter(dayjs())) {
    return dayjs().format('YYYY-MM-DD');
  }
  return dayjs(date).format('YYYY-MM-DD');
};
