import { Organization } from '@ncr-voyix-commerce/react-common-components';

const EffectiveOrgExcemtpionList = ['customers', 'solution-partners', 'teams', 'internal-teams', 'commerce'];

const getOrgShortName = (orgName: string) => {
  const splitName = orgName.split('/');
  return splitName.length === 1 ? orgName : splitName.slice(-2)[0];
};

const formatOrganizations = (orgs: Organization[]): Organization[] => {
  const filtered = orgs.filter((o: Organization) => {
    const orgHierarchy: string[] = o.organizationName.split('/').filter((s) => !!s);
    return !(orgHierarchy.length === 1 && EffectiveOrgExcemtpionList.includes(orgHierarchy[0]));
  });

  const childrenIds: Set<string> = new Set();

  for (let i = 0; i < filtered.length; i += 1) {
    const grantedChildren = filtered.filter(
      (o: Organization) =>
        o.organizationName.startsWith(filtered[i].organizationName) &&
        o.organizationName.length > filtered[i].organizationName.length
    );

    const children = [];

    for (let j = 0; j < grantedChildren.length; j += 1) {
      children.push(getOrgShortName(grantedChildren[j].organizationName));
      childrenIds.add(grantedChildren[j].id);
    }

    filtered[i].children = children;
  }

  const filteredAndGrouped = filtered.filter((o: Organization) => !childrenIds.has(o.id));

  for (let i = 0; i < filteredAndGrouped.length; i += 1) {
    filteredAndGrouped[i].organizationName = getOrgShortName(filteredAndGrouped[i].organizationName);
    filteredAndGrouped[i].children ??= [];
  }

  return filteredAndGrouped.sort((a, b) => a.organizationName.localeCompare(b.organizationName));
};

export default formatOrganizations;
