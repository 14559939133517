import { Res } from '@cbo/shared-library';

// eslint-disable-next-line import/prefer-default-export
export const formatStringList = (list: string[], preferences?: Res.Admin.UserPreferences): string => {
  const listFormatter = new Intl.ListFormat(preferences?.language ?? 'en-US', { style: 'long', type: 'conjunction' });

  return listFormatter.format(list);
};

export const truncateString = (str: string, maxLength: number): string => {
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength)}...`;
  }
  return str;
};
