/* eslint-disable react/jsx-props-no-spreading */
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material';
import Event from '@mui/icons-material/Event';
import { useRef } from 'react';

interface Props {
  name: string;
  startProps: TextFieldProps;
  variant: 'filled' | 'standard' | 'outlined' | undefined;
  helperText: string | undefined;
  placeholder: string | undefined;
  hideIcon: boolean | undefined;
  setDateRangePopoverAnchorEl: (anchorEl: null | HTMLElement) => void;
  useStaticDateRange?: boolean;
}

function DateRangePickerTextField({
  name,
  startProps,
  variant,
  helperText,
  placeholder,
  hideIcon,
  setDateRangePopoverAnchorEl,
  useStaticDateRange,
}: Props) {
  const startDateRangePickerRef = useRef<null | HTMLDivElement>(null);

  const staticDateRangeStyling = {
    paddingBottom: '16px',
    width: '100%',
  };

  return (
    <TextField
      {...startProps}
      variant={variant}
      helperText={helperText}
      inputRef={startDateRangePickerRef}
      name={name}
      id={name}
      data-testid={`date-range-${name}`}
      onFocus={(event) => {
        setDateRangePopoverAnchorEl(event.currentTarget);
      }}
      onClick={(event) => {
        setDateRangePopoverAnchorEl(event.currentTarget);
      }}
      inputProps={{
        ...startProps.inputProps,
        placeholder,
      }}
      sx={useStaticDateRange ? staticDateRangeStyling : null}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        endAdornment: hideIcon ? undefined : (
          <InputAdornment position='end' sx={{ padding: 0 }}>
            <IconButton
              data-testid={`${name}-calendar-icon`}
              onClick={() => {
                startDateRangePickerRef?.current?.click();
              }}
              tabIndex={-1}
            >
              <Event />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

DateRangePickerTextField.defaultProps = {
  useStaticDateRange: false,
};

export default DateRangePickerTextField;
