import { Month } from '@cbo/shared-library';

export interface MonthsConfig {
  [month: string]: {
    id: number;
    label: string;
  };
}

export const monthsConfig: MonthsConfig = {
  January: {
    id: Month.JANUARY,
    label: 'January',
  },
  February: {
    id: Month.FEBRUARY,
    label: 'February',
  },
  March: {
    id: Month.MARCH,
    label: 'March',
  },
  April: {
    id: Month.APRIL,
    label: 'April',
  },
  May: {
    id: Month.MAY,
    label: 'May',
  },
  June: {
    id: Month.JUNE,
    label: 'June',
  },
  July: {
    id: Month.JULY,
    label: 'July',
  },
  August: {
    id: Month.AUGUST,
    label: 'August',
  },
  September: {
    id: Month.SEPTEMBER,
    label: 'September',
  },
  October: {
    id: Month.OCTOBER,
    label: 'October',
  },
  November: {
    id: Month.NOVEMBER,
    label: 'November',
  },
  December: {
    id: Month.DECEMBER,
    label: 'December',
  },
};

export const monthsWith31Days = [
  Month.JANUARY,
  Month.MARCH,
  Month.MAY,
  Month.JULY,
  Month.AUGUST,
  Month.OCTOBER,
  Month.DECEMBER,
];

export const monthsWith30Days = [Month.APRIL, Month.JUNE, Month.SEPTEMBER, Month.NOVEMBER];

export const monthsWith28Days = [Month.FEBRUARY];

export const monthValueToStringMap: {
  [monthIndex: number]: string;
} = {
  0: 'january',
  1: 'february',
  2: 'march',
  3: 'april',
  4: 'may',
  5: 'june',
  6: 'july',
  7: 'august',
  8: 'september',
  9: 'october',
  10: 'november',
  11: 'december',
};

export const weekDayValueToStringMap: {
  [dayIndex: number]: string;
} = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};
