import { SwitcherSkeleton } from 'src/components/common/Switcher/SwitcherSkeleton';
import { useRccContext } from 'src/contexts';
import { InternalOrganizationSwitcher } from './InternalOrganizationSwitcher';
import { NonInternalOrganizationSwitcher } from './NonInternalOrganizationSwitcher';

export const OrganizationSwitcher = () => {
    const { isInternalUser } = useRccContext();

    if (isInternalUser === undefined) {
        return <SwitcherSkeleton />;
    }

    if (isInternalUser === true) {
        return <InternalOrganizationSwitcher />;
    }

    return <NonInternalOrganizationSwitcher />;
};
