// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import UrlPattern from 'url-pattern';
import { useEffect, useState } from 'react';
import { NavigationViewProps } from './NavigationView';
import { NavigationPath } from './NavigationContext';

export const normalizePath = (...paths: NavigationPath[]) =>
  [...paths]
    // map path objs to string (for react router support)
    .map((x) => (typeof x === 'string' ? x : x?.pathname || ''))
    // convert to string
    .join('/')
    // remove duplicate slashes
    .replace(/[\\/]{2,}/, '/');

// match the path and pattern using the url-pattern library
export const matchPath = (path?: NavigationPath, pattern?: NavigationPath) => {
  // https://github.com/snd/url-pattern
  const matches = new UrlPattern(normalizePath(pattern || '/')).match(normalizePath(path || '/')) !== null;
  return matches;
};

/**
 * getLevel is a utility function that takes in the props of a NavigationView
 * component and returns the level of the view. The level is determined by counting
 * the number of slashes in the path prop. This is intended to be overridden by
 * explicitly setting the `level` prop on the NavigationView.
 */
export const getLevel = (childProps?: NavigationViewProps) =>
  childProps
    ? (childProps.level !== undefined
        ? childProps.level
        : // eslint-disable-next-line no-useless-escape
          normalizePath(childProps.path).split(/[\*\/]+/).length - 1) || 0
    : 0;

/**
 * useNavigationView is a hook that allows to keep track of the current view, it
 * takes location as an argument and returns an array with the current view and a
 * function to update it. It also uses the useEffect hook to update the view
 * whenever the location changes.
 */
export const useNavigationView = (location?: NavigationPath) => {
  const path = typeof location === 'string' ? location : location?.pathname || '';

  const [view, setView] = useState(path);

  useEffect(() => {
    setView(path);
  }, [path]);

  return [view, setView] as const;
};

export interface CustomDesignComponentStyles {
  emptyHovered: string;
  emptyActive: string;
  mainActive: string;
}

export interface CustomDesignComponentTheme {
  darkMode: CustomDesignComponentStyles;
  lightMode: CustomDesignComponentStyles;
}

export const customDesignComponentTheme: CustomDesignComponentTheme = {
  darkMode: {
    emptyHovered: '#163349',
    emptyActive: '#1e3756',
    mainActive: '#b2dbfc',
  },
  lightMode: {
    emptyHovered: '#eaf5fe',
    emptyActive: '#d7edff',
    mainActive: '#134085',
  },
};

export const getCustomDesignTheme = (mode: string): CustomDesignComponentStyles => {
  if (mode && mode === 'dark') {
    return customDesignComponentTheme.darkMode;
  }
  return customDesignComponentTheme.lightMode;
};
