import Typography from '@mui/material/Typography';
import { Theme } from '@mui/system/createTheme';
import { SxProps } from '@mui/system/styleFunctionSx';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import ComparisonRange from '../../config/comparisonRange';
import { useAccountPreferences } from '../../contexts/accountPreferencesContext';
import { formatNumber } from '../../utils/formatters/formatNumber';
import { DateComparisonRanges } from '../GlobalFilterBar/types';

interface DeltaChipIndicatorProps {
  value?: string | number;
  isImprovement: boolean;
  dataTestId: string;
  comparisonRange?: ComparisonRange | DateComparisonRanges | null;
}

export default function DeltaChipIndicator(props: DeltaChipIndicatorProps) {
  const { comparisonRange, value, isImprovement, dataTestId } = props;
  const { t } = useTranslation();
  const { preferences } = useAccountPreferences();
  const styles: SxProps<Theme> | undefined = {
    display: 'flex',
    flexDirection: 'row',
    verticalAlign: 'middle',
    marginTop: '18px',
    justifyContent: 'center',
  };

  const upArrow = '↑';
  const downArrow = '↓';
  const arrow = Number(value) > 0 ? upArrow : downArrow;

  const backgroundGreen = '#eff7ee';
  const arrowGreen = '#2d8630';
  const contentGreen = '#1e4620';
  const backgroundRed = '#fdecea';
  const contentRed = '#621b16';
  const arrowRed = '#e91607';

  const label = comparisonRange ? t(`deltaIndicatorChip.${comparisonRange}`) : t('deltaIndicatorChip.none');

  return (
    <Box data-testid={dataTestId} sx={styles}>
      {comparisonRange && (
        <Typography
          sx={{
            backgroundColor: isImprovement ? backgroundGreen : backgroundRed,
            color: isImprovement ? contentGreen : contentRed,
            borderRadius: '16px',
            fontSize: '13px',
            fontWeight: '500',
            letterSpacing: '0.16px',
            height: '26px',
            padding: '4px',
            marginRight: '6px',
          }}
        >
          <span style={{ color: isImprovement ? arrowGreen : arrowRed, marginRight: '4px' }}>{arrow}</span>
          {`${formatNumber(Number(value) * 100, preferences ?? undefined, undefined, 1)}%`}
        </Typography>
      )}
      <Typography
        color='secondary'
        data-testid={`${dataTestId}-label`}
        sx={{ fontSize: '14px', letterSpacing: '0px', fontWeight: '500', marginTop: '2px' }}
      >
        {label}
      </Typography>
    </Box>
  );
}

DeltaChipIndicator.defaultProps = {
  value: undefined,
  comparisonRange: undefined,
};
