/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { MinorProhibitedHoursRule } from '../models/LaborRuleConfiguration';
import SelectDaysField from '../../labor/Scheduling/SelectDaysField/SelectDaysField';
import LabeledText from '../../components/LabaledText/LabeledText';
import { getFullDate } from '../utils/utils';

interface SchoolDayDefinitionTypographyProps {
  rule: MinorProhibitedHoursRule;
}

export default function SchoolDayDefinitionTypography(props: SchoolDayDefinitionTypographyProps) {
  const { rule } = props;
  const { t } = useTranslation();
  const prefix = 'laborRules.rules.MinorSchoolDayDefinitionRule';
  const formattedStartDate = getFullDate(rule.start, false, t);
  const formattedEndDate = getFullDate(rule.end, false, t);

  return (
    <Grid container>
      <Grid item container xs={12} sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
        <Grid item xs={2.35}>
          <Typography>{t(`${prefix}.SchoolDays.name`)}</Typography>
        </Grid>
        <Grid item sx={{ ml: 0.5 }}>
          <SelectDaysField selectedDays={rule.schoolDays} weekStart={0} isReadOnly />
        </Grid>
      </Grid>
      <Grid item container xs={12} sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
        <Grid item xs={2.5}>
          <Typography sx={{ mr: 4 }}>{t(`${prefix}.SchoolNotInSession.name`)}</Typography>
        </Grid>
        {rule?.start && rule?.end ? (
          <Grid item>
            <LabeledText
              label={t(`${prefix}.SchoolNotInSession.template`)}
              dataTestId='minor-summer-vacation-school-not-in-session'
              value={`${formattedStartDate} - ${formattedEndDate}`}
              styles={{ width: 280 }}
            />
          </Grid>
        ) : (
          <Grid item sx={{ ml: 1.5 }}>
            <Typography>{t('laborRules.rulesGuidance.noGuidance')}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
