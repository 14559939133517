/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { MinimumWageRule } from '../models/LaborRuleConfiguration';
import LabeledText from '../../components/LabaledText/LabeledText';

interface TippedMinWageTypographyProps {
  templateString: string;
  rule: MinimumWageRule;
}

export default function TippedMinWageTypography(props: TippedMinWageTypographyProps) {
  const { templateString, rule } = props;
  const { t } = useTranslation();
  const prefix = 'laborRules.rules.MinimumWageRule.Tipped';
  const maxTipCredit = (rule.stdMinWageAmount! - rule.amount).toFixed(2);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
        <LabeledText
          label={t(`${prefix}.standardMinWage`)}
          dataTestId='standard-min-wage-labeledtext'
          value={rule.stdMinWageAmount! ?? 0}
          currency
          styles={{ width: 200 }}
        />
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Typography sx={{ mx: 2 }}> - </Typography>
        <LabeledText
          label={t(`${prefix}.minWageForEmployee`)}
          dataTestId='min-wage-for-employee-labeledtext'
          value={rule.amount}
          currency
          required
          styles={{ width: 280 }}
        />
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Typography sx={{ mx: 2 }}> = </Typography>
        <LabeledText
          label={t(`${prefix}.maxTipCredit`)}
          dataTestId='max-tip-credit-labeledtext'
          value={rule.stdMinWageAmount! ? maxTipCredit : rule.amount}
          currency
          styles={{ width: 150 }}
        />
      </Grid>
      <Grid item xs={12}>
        {templateString}
      </Grid>
    </Grid>
  );
}

TippedMinWageTypography.defaultProps = {};
