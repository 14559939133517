import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  DataGridPremium,
  GridColumns,
  gridClasses,
  GridEventListener,
  GridRowId,
  GridInitialState,
  DataGridPremiumProps,
  GridLocaleText,
  GridRowModesModel,
  GridFeatureMode,
  DATA_GRID_PREMIUM_PROPS_DEFAULT_VALUES,
  GridFilterModel,
  GridSelectionModel,
  GridInputSelectionModel,
  GridSortModel,
  GridSlotsComponent,
  GridRowParams,
  MuiEvent,
  GridColumnVisibilityModel,
  GridCellParams,
  GridCallbackDetails,
} from '@mui/x-data-grid-premium';
import { useState, useCallback, MutableRefObject } from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { generateToolbar } from './helpers';
import CustomSkeletonLoadingOverlay from './CustomSkeletonLoadingOverlay';

export interface BaseGridRow {
  [key: string]: unknown;
  id: GridRowId;
}

export interface BaseDataGridProps extends DataGridPremiumProps {
  columns: GridColumns;
  rows: BaseGridRow[];
  itemsPerPage?: number;
  rowsPerPageOptions?: number[];
  sx?: SxProps<Theme>;
  components?: Partial<GridSlotsComponent> | undefined;
  hideToolbar?: boolean;
  hideFooter?: boolean;
  disableColumnSelector?: boolean;
  disableColumnFilter?: boolean;
  disableExport?: boolean;
  disableDensitySelector?: boolean;
  disableQuickFilter?: boolean;
  disableMultipleColumnsFiltering?: boolean;
  disableMultipleColumnsSorting?: boolean;
  disableColumnMenu?: boolean;
  disableSelectionOnClick?: boolean;
  disableVirtualization?: boolean;
  disableColumnResize?: boolean;
  disableColumnReorder?: boolean;
  initialState?: GridInitialState;
  dataTestId?: string;
  onRowClick?: GridEventListener<'rowClick'>;
  onRowEdit?: (params: unknown, secondary?: unknown) => Promise<void>;
  onCellKeyDown?: (params: GridCellParams, event: MuiEvent<React.KeyboardEvent>, details: GridCallbackDetails) => void;
  loading?: boolean;
  localeText?: Partial<GridLocaleText>;
  rowModesModel?: GridRowModesModel;
  rowCount?: number;
  page?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  paginationMode?: GridFeatureMode;
  autoHeight?: boolean;
  apiRef?: MutableRefObject<GridApiPremium>;
  onStateChange?: GridEventListener<'stateChange'>;
  filterMode?: GridFeatureMode;
  sortingMode?: GridFeatureMode;
  onFilterModelChange?: (filterModel: GridFilterModel) => void;
  checkboxSelection?: boolean;
  keepNonExistentRowsSelected?: boolean;
  onSelectionModelChange?: (selectionModel: GridSelectionModel) => void;
  selectionModel?: GridInputSelectionModel;
  sortModel?: GridSortModel;
  onSortModelChange?: (sortModel: GridSortModel) => void;
  columnVisibilityModel?: GridColumnVisibilityModel | undefined;
  defaultGroupingExpansionDepth?: number;
  isRowSelectable?: (params: GridRowParams) => boolean;
  rowGroupingModel?: string[];
}
function DataGrid(props: BaseDataGridProps) {
  const {
    experimentalFeatures,
    columns,
    rows,
    itemsPerPage,
    density,
    rowsPerPageOptions,
    sx,
    components,
    hideToolbar,
    hideFooter,
    disableColumnSelector,
    disableColumnFilter,
    disableMultipleColumnsFiltering,
    disableMultipleColumnsSorting,
    disableExport,
    disableDensitySelector,
    disableQuickFilter,
    disableColumnMenu,
    disableSelectionOnClick,
    disableVirtualization,
    disableColumnResize,
    disableColumnReorder,
    initialState,
    dataTestId,
    onRowClick,
    onRowEdit,
    onCellKeyDown,
    columnVisibilityModel,
    loading,
    localeText,
    rowModesModel,
    rowCount,
    page,
    onPageChange,
    onPageSizeChange,
    processRowUpdate,
    paginationMode,
    autoHeight,
    apiRef,
    onStateChange,
    filterMode,
    sortingMode,
    onFilterModelChange,
    checkboxSelection,
    keepNonExistentRowsSelected,
    onSelectionModelChange,
    selectionModel,
    sortModel,
    onSortModelChange,
    hideFooterSelectedRowCount,
    treeData,
    getTreeDataPath,
    groupingColDef,
    getRowHeight,
    getRowClassName,
    pinnedColumns,
    defaultGroupingExpansionDepth,
    onCellClick,
    isRowSelectable,
    rowGroupingModel,
    rowGroupingColumnMode,
  } = props;
  const [pageSize, setPageSize] = useState<number | undefined>(itemsPerPage);

  const handleRowEditStart = (_rows: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    // eslint-disable-next-line no-param-reassign
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (_params, event) => {
    // eslint-disable-next-line no-param-reassign
    event.defaultMuiPrevented = true;
  };

  const defaultProcessRowUpdate = useCallback(
    async (newRow: unknown, oldRow: unknown): Promise<BaseGridRow> => {
      if (onRowEdit) {
        try {
          await onRowEdit(newRow, oldRow);
          return newRow as BaseGridRow;
        } catch {
          return oldRow as BaseGridRow;
        }
      }
      throw new Error('onRowEdit prop not defined');
    },
    [onRowEdit]
  );

  const handleProcessRowUpdateError = useCallback((err: unknown): void => {
    // TODO: add snackbar with error message if processRowUpdate fails
    // eslint-disable-next-line no-console
    console.error(err);
  }, []);

  return (
    <DataGridPremium
      density={density ?? 'standard'}
      data-testid={dataTestId}
      sx={{
        backgroundColor: 'background.paper',
        // using minHeight to stabilize loading skeleton
        minHeight: loading && autoHeight ? 670 : undefined,
        height: 670,
        width: '100%',
        border: 1,
        borderColor: '#B8B7B7',
        borderRadius: '8px',
        '& .actionButton': {
          display: 'none',
        },
        [`& .${gridClasses.row}:hover`]: {
          '.actionButton': {
            display: 'block',
          },
        },
        '& .MuiDataGrid-cell': {
          padding: '0px 16px',
        },
        '& .MuiDataGrid-columnHeader': {
          padding: '0px 16px',
        },
        '& .MuiDataGrid-columnHeaderCheckbox': {
          padding: '0px 0px',
        },
        '& .MuiDataGrid-virtualScroller': {
          overflow: loading ? 'hidden' : 'auto',
        },
        '& .MuiDataGrid-pinnedColumnHeaders': {
          backgroundColor: 'background.paper',
          width: 100,
          alignContent: 'center',
          alignItems: 'center',
          boxShadow: '-3px 0px 8px 0px rgba(51, 51, 51, 0.25)',
        },
        '& .MuiDataGrid-pinnedColumns': {
          backgroundColor: 'background.paper',
          boxShadow: '-3px 0px 8px 0px rgba(51, 51, 51, 0.25)',
          width: 100,
        },
        // This next sx change is to remove the last column vertical line separator
        '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-toolbarContainer': {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        ...sx,
      }}
      columns={columns}
      rows={rows}
      components={{
        ...components,
        LoadingOverlay: CustomSkeletonLoadingOverlay,
        Toolbar: useCallback(
          () =>
            generateToolbar({
              hideToolbar,
              disableColumnFilter,
              disableColumnSelector,
              disableDensitySelector,
              disableExport,
              disableQuickFilter,
            }),
          [
            hideToolbar,
            disableColumnFilter,
            disableColumnSelector,
            disableDensitySelector,
            disableExport,
            disableQuickFilter,
          ]
        ),
      }}
      componentsProps={{
        panel: {
          sx: {
            '& .MuiDataGrid-panelWrapper': {
              maxHeight: '392px',
            },
            zIndex: 1100,
          },
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      disableMultipleColumnsFiltering={disableMultipleColumnsFiltering}
      disableMultipleColumnsSorting={disableMultipleColumnsSorting}
      disableVirtualization={disableVirtualization}
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      onCellKeyDown={onCellKeyDown}
      disableColumnMenu={disableColumnMenu}
      disableSelectionOnClick={disableSelectionOnClick}
      hideFooter={hideFooter}
      experimentalFeatures={{ ...experimentalFeatures, newEditingApi: true }}
      initialState={initialState}
      pageSize={pageSize}
      rowsPerPageOptions={rowsPerPageOptions}
      onPageSizeChange={(newPageSize) => {
        onPageSizeChange?.(newPageSize);
        setPageSize(newPageSize);
      }}
      pagination={!hideFooter}
      autoHeight={autoHeight}
      columnBuffer={12}
      onRowClick={onRowClick}
      processRowUpdate={processRowUpdate ?? defaultProcessRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      loading={loading && rows.length === 0}
      localeText={localeText}
      editMode='row'
      rowModesModel={rowModesModel}
      page={page}
      rowCount={rowCount}
      onPageChange={onPageChange}
      paginationMode={paginationMode}
      apiRef={apiRef}
      onStateChange={onStateChange}
      sortingMode={sortingMode}
      filterMode={filterMode}
      onFilterModelChange={onFilterModelChange}
      checkboxSelection={checkboxSelection}
      keepNonExistentRowsSelected={keepNonExistentRowsSelected}
      onSelectionModelChange={onSelectionModelChange}
      selectionModel={selectionModel}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      hideFooterSelectedRowCount={hideFooterSelectedRowCount}
      treeData={treeData}
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColDef}
      getRowHeight={getRowHeight}
      getRowClassName={getRowClassName}
      pinnedColumns={pinnedColumns}
      defaultGroupingExpansionDepth={defaultGroupingExpansionDepth}
      onCellClick={onCellClick}
      disableColumnResize={disableColumnResize}
      disableColumnReorder={disableColumnReorder}
      isRowSelectable={isRowSelectable}
      rowGroupingModel={rowGroupingModel}
      rowGroupingColumnMode={rowGroupingColumnMode}
    />
  );
}

DataGrid.defaultProps = {
  ...DATA_GRID_PREMIUM_PROPS_DEFAULT_VALUES,
  sx: {},
  hideFooter: false,
  hideToolbar: false,
  components: {},
  disableColumnSelector: true,
  disableColumnFilter: false,
  disableMultipleColumnsSorting: false,
  disableMultipleColumnsFiltering: false,
  disableExport: true,
  disableDensitySelector: false,
  disableQuickFilter: false,
  disableColumnMenu: true,
  disableSelectionOnClick: false,
  disableVirtualization: false,
  disableColumnResize: false,
  disableColumnReorder: false,
  initialState: {},
  dataTestId: '',
  itemsPerPage: 0,
  rowsPerPageOptions: [5, 10, 20],
  onRowClick: undefined,
  onRowEdit: undefined,
  onCellKeyDown: undefined,
  loading: false,
  localeText: {},
  rowModesModel: undefined,
  autoHeight: true,
  apiRef: undefined,
  onStateChange: undefined,
  checkboxSelection: false,
  keepNonExistentRowsSelected: false,
};

export default DataGrid;
