import { useTranslation } from 'react-i18next';
import LaborRuleBaseConfirmationModal, {
  ConfirmationFormValues,
} from '../LaborRuleBaseConfirmationModal/LaborRuleBaseConfirmationModal';

interface DeactivateModalProps {
  ruleName: string;
  jurisdiction: string;
  handleDeactivate: (confirmData: ConfirmationFormValues) => Promise<void>;
  handleClose: () => void;
  loading: boolean;
}

export default function DeactivateModal(props: DeactivateModalProps) {
  const { handleDeactivate, handleClose, ruleName, jurisdiction, loading } = props;
  const { t } = useTranslation();

  const secondCheckbox = {
    name: 'deactivateAcknowledgement',
    label: t(`laborRules.deactivateModal.acknowledgementCheckbox`),
    datatestid: 'deactivate-acknowledgement-checkbox-input',
    value: 'deactivateAcknowledgement',
    readOnly: false,
    required: true,
    disabled: loading,
  };

  return (
    <LaborRuleBaseConfirmationModal
      dataTestId='deactivate-rule-confirmation-modal'
      title={t('laborRules.deactivateModal.title', { ruleName })}
      ctaText={t('laborRules.deactivateModal.ctaButtonText')}
      subHeaderText={t('laborRules.deactivateModal.subHeaderText', { jurisdiction })}
      handleClose={handleClose}
      ctaAction={handleDeactivate}
      secondCheckbox={secondCheckbox}
      ctaRed
      dateReadOnly
    />
  );
}
