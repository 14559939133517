import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeValues } from 'src/constants/theme-values';

const useMobileView = () => {
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down(ThemeValues.Breakpoints.sm));
    return isMobileView;
};

export default useMobileView;
