import DomainIcon from '@mui/icons-material/Domain';
import { Collapse, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import { DialogListItemActionButton } from 'src/components/common/Dialog/DialogListItemActionButton';
import { useOrganizationDialogContext } from '../OrganizationProviders/OrganizationDialogProvider';
import { Organization } from 'src/types';
import { useOrganizationContext } from 'src/contexts';

export const OrganizationHierarchyCollapse = ({ organization }: { organization: Organization }) => {
    const { organization: activeOrganization } = useOrganizationContext();

    const { handleOrganizationClick, searchValue, expandedOrg } = useOrganizationDialogContext();

    return (
        <ListItem disablePadding>
            <Collapse sx={{ width: '100%' }} in={expandedOrg.has(organization.id)}>
                <List sx={{ paddingBottom: '0px !important' }}>
                    {organization.children
                        .filter((childOrg) => childOrg.includes(searchValue))
                        .map((childOrg) => {
                            return (
                                <DialogListItemActionButton
                                    selected={childOrg === activeOrganization?.organizationName}
                                    key={`${childOrg}`}
                                    sx={{
                                        paddingLeft: 3,
                                    }}
                                    onListItemButtonClick={() => handleOrganizationClick(childOrg)}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: '0',
                                            marginRight: '16px',
                                        }}
                                    >
                                        <DomainIcon />
                                    </ListItemIcon>
                                    <Typography variant='body1'>{childOrg}</Typography>
                                </DialogListItemActionButton>
                            );
                        })}
                </List>
            </Collapse>
        </ListItem>
    );
};
