import { Req } from '@cbo/shared-library';

const kind = 'single';
export default function toQueryParams(
  params: Record<string, string | undefined | null>
): Req.Firebase.SingleQueryParam[] {
  return Object.entries(params)
    .filter((entry): entry is [string, string] => entry[1] != null)
    .map(([key, value]) => ({ kind, key, value }));
}
