import { ListItem, ListItemButton, useTheme, Link } from '@mui/material';
import { SwitcherDrawer } from 'src/components/common/Switcher/SwitcherDrawer';
import { AppConstants } from 'src/constants/app';
import { ThemeValues } from 'src/constants/theme-values';
import { useApplicationsContext, useOrganizationContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ApplicationModel } from 'src/types/application.type';
import { getContextRedirectUrl } from 'src/utils/navigation.utils';

export const ApplicationSwitcherDrawer = ({
    menuOpen,
    onMenuOpen,
    onMenuClose,
}: {
    menuOpen: boolean;
    onMenuOpen: () => void;
    onMenuClose: () => void;
}) => {
    const { t } = useTranslationNs();
    const { userApplications, setIsAppDialogOpen, application } = useApplicationsContext();
    const { organization } = useOrganizationContext();

    const theme = useTheme();

    const handleAppClick = () => {
        onMenuClose();
    };

    const handleViewAll = () => {
        onMenuClose();
        setIsAppDialogOpen(true);
    };

    const allApps = userApplications
        .slice(0, AppConstants.AppMenuMax)
        .map((app: ApplicationModel) => (
            <ListItem key={app.name} disablePadding>
                <ListItemButton
                    sx={{
                        ...theme.typography.subtitle1,
                        padding: ThemeValues.DrawerListItemPadding,
                    }}
                    component={Link}
                    href={getContextRedirectUrl(organization.organizationName, app.redirectUrl)}
                    target='_blank'
                    selected={app.name === application?.name}
                    onClick={handleAppClick}
                >
                    {app?.displayName}
                </ListItemButton>
            </ListItem>
        ));

    return (
        <SwitcherDrawer
            menuOpen={menuOpen}
            menuTitle={t('APPLICATION_CONTEXT.MENU.TITLE')}
            menuCloseTitle={t('APPLICATION_CONTEXT.ACCESSIBILITY.CLOSE')}
            ctaTitle={t('APPLICATION_CONTEXT.MENU.MANAGE_ALL_APPS')}
            onMenuClose={onMenuClose}
            onMenuOpen={onMenuOpen}
            onCtaClick={handleViewAll}
        >
            {allApps}
        </SwitcherDrawer>
    );
};
