import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

let firestore: ReturnType<typeof getFirestore>;
export default () => {
  if (firestore) {
    return firestore;
  }
  firestore = getFirestore();
  if (process.env.REACT_APP_USE_LOCAL === 'true') {
    connectFirestoreEmulator(firestore, 'localhost', 8080);
  }

  return firestore;
};
