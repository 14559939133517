import Stack from '@mui/material/Stack';
import { StackProps } from '@mui/material';

function SidebarContent(props: StackProps) {
  const { children, ...rest } = props;

  return (
    <Stack flex={1} {...rest}>
      {children}
    </Stack>
  );
}

export default SidebarContent;
