import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useContext, useMemo, useState } from 'react';

export type IOrganizationDialogContext = {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  expandedOrg: Set<string>;
  handleOrganizationClick: (value: string) => void;
  handleExpandOrgClick: (orgId: string) => void;
  resetExpandedOrgs: () => void;
};

export const OrganizationDialogContext = createContext<IOrganizationDialogContext>({} as IOrganizationDialogContext);

export const useOrganizationDialogContext = () => useContext(OrganizationDialogContext);

export function OrganizationDialogProvider({
  updateOrganization,
  setIsOrgDialogOpen,
  children,
}: {
  updateOrganization: (org: string) => void;
  setIsOrgDialogOpen: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
}) {
  const [searchValue, setSearchValue] = useState('');
  const [expandedOrg, setExpandedOrg] = useState<Set<string>>(new Set());

  const resetExpandedOrgs = useCallback(() => {
    setExpandedOrg(new Set());
  }, [setExpandedOrg]);

  const handleOrganizationClick = useCallback(
    (value: string) => {
      setIsOrgDialogOpen(false);
      setSearchValue('');
      updateOrganization(value);
      setExpandedOrg(new Set<string>());
    },
    [setIsOrgDialogOpen, setSearchValue, updateOrganization, setExpandedOrg]
  );

  const handleExpandOrgClick = useCallback((orgId: string) => {
    setExpandedOrg((expandedOrgs: Set<string>) => {
      const newSet = new Set(expandedOrgs);
      if (newSet.has(orgId)) {
        newSet.delete(orgId);
      } else {
        newSet.add(orgId);
      }
      return newSet;
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      searchValue,
      setSearchValue,
      expandedOrg,
      handleOrganizationClick,
      handleExpandOrgClick,
      resetExpandedOrgs,
    }),
    [searchValue, setSearchValue, expandedOrg, handleOrganizationClick, handleExpandOrgClick, resetExpandedOrgs]
  );

  return <OrganizationDialogContext.Provider value={contextValue}>{children}</OrganizationDialogContext.Provider>;
}
