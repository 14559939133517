import Close from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, IconButton, Theme, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { ReactNode, forwardRef } from 'react';
import { ThemeValues } from 'src/constants/theme-values';
import useMobileView from 'src/hooks/useMobileView';
import { DialogSearchBar } from '../Search/DialogSearchBar';
const Breakpoints = ThemeValues.Breakpoints;

const MobileTransition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const WebTransition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Fade ref={ref} {...props} />;
});

export const SwitcherDialog = ({
    isOpen,
    dialogTitle,
    dialogCloseTitle,
    onClose,
    onSearch,
    children,
}: {
    isOpen: boolean;
    dialogTitle: string;
    dialogCloseTitle: string;
    onClose: () => void;
    onSearch: (query: string) => void;
    children: ReactNode;
}) => {
    const isMobileView = useMobileView();
    const theme = useTheme<Theme>();

    return (
        <Dialog
            fullScreen={isMobileView}
            maxWidth={isMobileView ? undefined : false}
            open={isOpen}
            onClose={onClose}
            TransitionComponent={isMobileView ? MobileTransition : WebTransition}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: {
                        xs: 'flex-end',
                        sm: 'center',
                    },
                },
                '& .MuiPaper-root': {
                    borderRadius: ThemeValues.BorderRadius,
                    [theme.breakpoints.up(Breakpoints.sm)]: {
                        height: '812px',
                        maxHeight: '80%',
                    },
                    [theme.breakpoints.down(Breakpoints.sm)]: {
                        borderRadius: 0,
                    },
                    width: '600px',
                },
                '& .MuiDialog-paperFullWidth': {
                    m: 0,
                    width: '100%',
                },
                '.MuiList-root': {
                    padding: '0px 12px 20px 12px',
                },
            }}
        >
            <Box padding='16px 10px 16px 24px'>
                <DialogTitle sx={{ padding: 0, fontWeight: 700 }} variant='h6'>
                    {dialogTitle}
                </DialogTitle>
            </Box>
            <IconButton
                title={dialogCloseTitle}
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    top: '12px',
                    right: '16px',
                }}
            >
                <Close />
            </IconButton>
            <Box sx={{ padding: '0px 24px 12px' }}>
                <DialogSearchBar onChange={onSearch} />
            </Box>
            <DialogContent sx={{ overflowY: 'auto', padding: 0 }}>{children}</DialogContent>
        </Dialog>
    );
};
