import LanRoundedIcon from '@mui/icons-material/LanRounded';
import { MenuItem, Typography } from '@mui/material';
import {
    MutableRefObject,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { SwitcherMenu } from 'src/components/common/Switcher/SwitcherMenu';
import {
    SwitcherProvider,
    getDefaultConfig,
} from 'src/components/common/Switcher/SwitcherProvider';
import { ThemeValues } from 'src/constants/theme-values';
import { UserSettingKeys } from 'src/constants/user-settings-keys';
import { SwitcherOptions, useOrganizationContext, useRccContext } from 'src/contexts';
import { useUserSettingsContext } from 'src/contexts/UserSettingsContext';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { Organization } from 'src/types';
import { PreferenceSwitcher } from '../../../common/PreferenceSwitcher';

export const NonInternalOrganizationPreferenceSwitcher = ({
    config = getDefaultConfig(),
}: {
    config?: SwitcherOptions;
}) => {
    const { t } = useTranslationNs();
    const [switcherSelection, setSwitcherSelection] = useState<string>('');
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const { isLoading, userOrganizations } = useOrganizationContext();
    const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const { _TEMPORARY_userSettingsMutationEndpoint } = useRccContext();
    const {
        getUserSettingByKey,
        isUserSettingsLoading,
        isUserSettingsValidating,
        isUserSettingsMutating,
        updateUserSettings,
    } = useUserSettingsContext();
    const defaultOrg = getUserSettingByKey(UserSettingKeys.DefaultOrg);

    const handleOpenOrgMenu = () => {
        setMenuOpen(true);
    };

    const handleCloseOrgMenu = () => {
        setMenuOpen(false);
    };

    const handleOrgClick = useCallback(
        async (orgName: string) => {
            setSwitcherSelection(orgName);
            setMenuOpen(false);
            await updateUserSettings({
                key: UserSettingKeys.DefaultOrg,
                value: orgName,
            });
        },
        [setMenuOpen, updateUserSettings],
    );

    useEffect(() => {
        setSwitcherSelection(defaultOrg);
    }, [defaultOrg, setSwitcherSelection]);

    const effectiveOrgs = useMemo(() => {
        return userOrganizations?.map((org: Organization) => (
            <MenuItem
                sx={{
                    padding: ThemeValues.MenuItemPadding,
                }}
                selected={org.organizationName === defaultOrg}
                key={org.id}
                onClick={() => handleOrgClick(org.organizationName)}
            >
                <Typography variant='subtitle2'>{org.displayName}</Typography>
            </MenuItem>
        ));
    }, [userOrganizations, handleOrgClick, defaultOrg]);

    let switcherLabel: ReactNode;
    if (isLoading) switcherLabel = t('COMMON.LOADING');
    else if (!switcherSelection) {
        switcherLabel = t('ORG_CONTEXT.SWITCHER.EMPTY');
    } else {
        switcherLabel =
            switcherSelection || t('USER_SETTINGS.PREFERENCES.DEFAULTS.ORGANIZATION.EMPTY');
    }

    return (
        <SwitcherProvider config={config || {}}>
            <PreferenceSwitcher
                tooltipTitle={t('USER_SETTINGS.PREFERENCES.DEFAULTS.ORGANIZATION.TOOLTIP')}
                disabled={
                    userOrganizations?.length <= 1 ||
                    isUserSettingsValidating ||
                    isUserSettingsMutating ||
                    !_TEMPORARY_userSettingsMutationEndpoint
                }
                enableSwitcher={userOrganizations?.length !== 1}
                isLoading={isLoading || isUserSettingsLoading}
                onClick={handleOpenOrgMenu}
                startIcon={<LanRoundedIcon fontSize='small' />}
                ref={buttonRef}
            >
                {switcherLabel}
            </PreferenceSwitcher>
            <SwitcherMenu
                anchorRef={buttonRef}
                menuOpen={menuOpen}
                onMenuClose={handleCloseOrgMenu}
                menuPaperSx={{
                    maxHeight: '235px',
                }}
            >
                {effectiveOrgs}
            </SwitcherMenu>
        </SwitcherProvider>
    );
};
