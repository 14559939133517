export const ThemeValues = {
    BorderRadius: 'var(--rcc-styles-border-radius)',
    DrawerListItemPadding: 'var(--rcc-styles-drawer-list-item-padding)',
    MenuItemPadding: 'var(--rcc-styles-menu-item-padding)',
    UserSettingsPadding: 'var(--rcc-styles-user-settings-padding)',
    UserConfigSwitcherWidth: 'var(--rcc-styles-config-switcher-width)',
    Breakpoints: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
    },
};
