import { Req } from '@cbo/shared-library';
import { useErrorHandler } from 'react-error-boundary';
import { List } from 'lodash';
import useCallBslAndHandleErrors from '../../lib/firebaseApiHandleErrors';
import { LaborRuleConfigData, EnterpriseGroupResponse, CreateChangeSetRequest } from '../models/LaborRuleConfiguration';
import { JurisdictionSitesRow } from '../JurisdictionSitesDataGrid/JurisdictionSitesDataGrid';
import { EulaRecordData, EulaRecordRequest } from '../models/EulaRecord';
import { getLocalityUUID, isEnterpriseGroupResponse } from '../utils/utils';

export type LaborRulesRequests = {
  getDefaultRuleConfigurationsByJurisdiction: (jurisdiction: string) => Promise<List<LaborRuleConfigData> | void>;
  getRuleConfigurationsForEnterprise: (jurisdiction: string) => Promise<List<LaborRuleConfigData> | void>;
  postRuleConfigurationForEnterprise: (reqBody: CreateChangeSetRequest) => Promise<void>;
  deleteRuleConfigurations: (changesetIds: number[]) => Promise<unknown[]>;
  getEulaAgreement: () => Promise<EulaRecordData | void>;
  updateEulaAgreement: (payload: EulaRecordRequest) => Promise<EulaRecordData | void>;
  getUnitSitesByGroupName: (
    localityNames: string[]
  ) => Promise<(({ id: string; hierarchy: string[] } | undefined)[] | undefined)[]>;
};

export const useLaborRulesRequests = (): LaborRulesRequests => {
  const handleError = useErrorHandler();
  const callBslAndHandleErrors = useCallBslAndHandleErrors();

  const proxies = {
    rule_configs: 'labor-rules',
  };

  const Verb = Req.Firebase.HttpVerb;

  /**
   * RULE CONFIGURATIONS
   */
  const getDefaultRuleConfigurationsByJurisdiction = async (
    jurisdiction: string
  ): Promise<List<LaborRuleConfigData> | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.rule_configs,
        pathSegments: ['config', jurisdiction],
        verb: Verb.GET,
      },
      handleError
    );

  const getRuleConfigurationsForEnterprise = async (jurisdiction: string): Promise<List<LaborRuleConfigData> | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.rule_configs,
        pathSegments: ['config', 'org', jurisdiction],
        verb: Verb.GET,
      },
      handleError
    );

  const postRuleConfigurationForEnterprise = async (reqBody: CreateChangeSetRequest): Promise<void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.rule_configs,
        pathSegments: ['config', 'org', 'changeset'],
        verb: Verb.POST,
        payload: reqBody,
      },
      handleError
    );

  const deleteRuleConfigurations = async (changesetIds: number[]): Promise<unknown[]> =>
    Promise.all(
      changesetIds.map((id) =>
        callBslAndHandleErrors(
          {
            proxy: proxies.rule_configs,
            pathSegments: ['config', 'org', 'changeset', id.toString()],
            verb: Verb.DELETE,
          },
          handleError
        )
      )
    );

  /**
   * SITES BY ENTERPRISE UNIT ID
   */

  const getUnitSitesByGroupName = async (
    localityNames: string[]
  ): Promise<(({ id: string; hierarchy: string[] } | undefined)[] | undefined)[]> =>
    Promise.all(
      localityNames.map(async (name: string) => {
        const groupRes = await callBslAndHandleErrors<EnterpriseGroupResponse, unknown>(
          {
            proxy: 'provisioning.enterprise-unit-groups',
            pathSegments: [getLocalityUUID(name)],
            verb: Req.Firebase.HttpVerb.GET,
          },
          handleError
        );
        if (isEnterpriseGroupResponse(groupRes)) {
          const results = Promise.all(
            groupRes.enterpriseUnits.map(async (unit: { enterpriseUnitId: string }) => {
              const { enterpriseUnitId } = unit;
              const site = await callBslAndHandleErrors<JurisdictionSitesRow, unknown>(
                {
                  proxy: 'site.v1',
                  pathSegments: ['sites', enterpriseUnitId],
                  verb: Req.Firebase.HttpVerb.GET,
                },
                handleError
              );
              if (site && String(site.status).toUpperCase() === 'ACTIVE')
                return { ...site, hierarchy: [name.replaceAll('_', ' '), site.id] };
              return undefined;
            })
          );
          return results;
        }
        return undefined;
      })
    );

  /**
   * END USER LICENSE AGREEMENTS
   */
  const getEulaAgreement = async (): Promise<EulaRecordData | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.rule_configs,
        pathSegments: ['eula'],
        verb: Verb.GET,
      },
      handleError
    );

  const updateEulaAgreement = async (payload: EulaRecordRequest): Promise<EulaRecordData | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.rule_configs,
        pathSegments: ['eula'],
        verb: Verb.POST,
        payload,
      },
      handleError
    );

  return {
    getDefaultRuleConfigurationsByJurisdiction,
    getRuleConfigurationsForEnterprise,
    postRuleConfigurationForEnterprise,
    deleteRuleConfigurations,
    getEulaAgreement,
    updateEulaAgreement,
    getUnitSitesByGroupName,
  };
};
