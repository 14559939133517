import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    SwipeableDrawer,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { UserProfileOption } from 'src/types/user-profile.type';
import LogoutButton from '../common/LogoutButton/LogoutButton';
import { ProfileIcon } from '../common/ProfileIcon/ProfileIcon';
import { SwipeableDrawerFooter, SwipeableDrawerHeader } from '../common/SwipeableDrawer';
import { useRccContext } from 'src/contexts';
import { Link } from 'src/components/common/Link/Link';
import { useLocation } from 'src/utils/search-params.utils';

const DrawerIconOption = ({
    option,
    selected,
    onClick,
}: {
    option: UserProfileOption;
    selected: boolean;
    onClick: () => void;
}) => {
    const { linkProps = {}, onClick: onClickOption, icon, url, label } = option;

    const listButton = (
        <ListItemButton
            onClick={() => {
                if (onClickOption) onClickOption();
                onClick();
            }}
            sx={{
                padding: '12px 16px',
            }}
            selected={selected}
        >
            {icon && (
                <Box
                    display='flex'
                    lineHeight={1}
                    color={(theme: Theme) => theme.palette.action.active}
                    paddingRight='8px'
                >
                    {icon}
                </Box>
            )}
            <Typography variant='body1' textAlign='center'>
                {label}
            </Typography>
        </ListItemButton>
    );

    return (
        <ListItem
            disablePadding
            sx={{
                a: {
                    textDecoration: 'none',
                    width: '100%',
                    color: 'inherit',
                },
            }}
            key={url}
        >
            {url !== undefined ? (
                <Link {...linkProps} to={url}>
                    {listButton}
                </Link>
            ) : (
                listButton
            )}
        </ListItem>
    );
};

export const UserProfileSettingsDrawer = ({
    menuOpen,
    onMenuOpen,
    onMenuClose,
    options,
}: {
    menuOpen: boolean;
    onMenuOpen: () => void;
    onMenuClose: () => void;
    options: UserProfileOption[];
}) => {
    const { userInfo } = useRccContext();
    const theme = useTheme();
    const location = useLocation();
    const [hasUserName, setHasUserName] = useState<boolean>(false);

    useEffect(() => {
        setHasUserName(Boolean(userInfo && userInfo));
    }, [userInfo]);

    return (
        <SwipeableDrawer
            disableDiscovery={true}
            disableSwipeToOpen={true}
            anchor='bottom'
            open={menuOpen}
            onClose={onMenuClose}
            onOpen={onMenuOpen}
        >
            <SwipeableDrawerHeader>
                {hasUserName && (
                    <Fragment>
                        <Box
                            sx={{
                                padding: '12px 8px',
                            }}
                            display='flex'
                            alignItems='center'
                        >
                            <Box paddingRight='16px'>
                                <ProfileIcon userInfo={userInfo} size='laptop' />
                            </Box>
                            <Typography variant='body1'>{userInfo?.name}</Typography>
                        </Box>
                        <Divider />
                    </Fragment>
                )}
            </SwipeableDrawerHeader>
            <List>
                {options.map((option: UserProfileOption) => (
                    <DrawerIconOption
                        key={option.label}
                        option={option}
                        selected={option?.url === location.pathname}
                        onClick={onMenuClose}
                    />
                ))}
            </List>
            <SwipeableDrawerFooter
                sx={{
                    padding: 0,
                }}
            >
                <Box sx={{ px: '8px' }}>
                    <Divider />
                </Box>
                <LogoutButton
                    size='large'
                    sx={{
                        width: '100%',
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                        padding: '12px 16px',
                        color: theme.palette.text.primary,
                        fontWeight: theme.typography.body1.fontWeight,
                        fontSize: theme.typography.body1.fontSize,
                    }}
                />
            </SwipeableDrawerFooter>
        </SwipeableDrawer>
    );
};
