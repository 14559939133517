import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useFieldArray, useFormContext } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {
  CustomLaborRuleConfigForm,
  MinorRuleTypes,
  NO_CONCURRENT_RULES,
  RuleFormValues,
  WagesRuleTypes,
} from '../models/LaborRuleConfiguration';
import LaborRuleTemplate from '../LaborRuleTemplate/LaborRuleTemplate';
import { isPredictabilityPayRule } from '../utils/utils';
import { Exemptions } from '../Exemptions/Exemptions';

export interface EditRuleFormProps {
  generateEmptyConfig: () => CustomLaborRuleConfigForm;
  onRuleDelete?: () => void;
  locality?: string;
}

export default function EditRuleForm(props: EditRuleFormProps) {
  const { generateEmptyConfig, onRuleDelete, locality } = props;
  const { t } = useTranslation();
  const methods = useFormContext<RuleFormValues>();
  const watchType = methods.watch('ruleType');

  const { fields, remove, append, update } = useFieldArray<RuleFormValues, 'rules', 'id'>({
    name: 'rules',
    control: methods.control,
  });
  const deleteRule = (index: number): void => {
    if (watchType === WagesRuleTypes.MinimumWageRule || watchType === MinorRuleTypes.MinorSchoolDayDefinitionRule) {
      /**
       * This handles both MinimumWageRule and TippedMinimumWageRule
       * For these 2 rules, we don't really remove the newly added rule
       * We let the parent know the delete button is clicked
       */
      if (onRuleDelete) onRuleDelete();
    } else if (fields.length === 1) {
      methods.setValue('rules', []);
    } else {
      remove(index);
    }
  };

  const deleteEndAdornment = (index: number) => (
    <IconButton aria-label='delete rule' data-testid={`delete-rule-${index}-button`} onClick={() => deleteRule(index)}>
      <DeleteIcon />
    </IconButton>
  );

  const concurrentRulesAllowed = !NO_CONCURRENT_RULES.includes(watchType);
  return (
    <Grid container item xs={12} data-testid='labor-rule-container'>
      <Grid item xs={12}>
        {fields.map((config, index) => (
          <LaborRuleTemplate
            // eslint-disable-next-line react/no-array-index-key
            key={`edit-rule-${index}-${config.rule?.type}-${config.id ? config.id : 'new'}`}
            id={index}
            rule={config.rule}
            sx={{ m: 0, mt: 3 }}
            editable
            focusMode
            action={deleteEndAdornment(index)}
            locality={locality}
          />
        ))}
        {isPredictabilityPayRule(fields[0]?.rule) && (
          <Exemptions selectedExemptions={fields[0]?.rule.exemptions} editable fields={fields} update={update} />
        )}
      </Grid>
      {concurrentRulesAllowed && (
        <Grid item xs={12}>
          <Button
            sx={{ mt: 3 }}
            variant='outlined'
            data-testid='add-rule-button'
            aria-label='Add concurrent rule'
            onClick={(e) => {
              append(generateEmptyConfig());
            }}
          >
            {t('laborRules.editRuleForm.addConcurrentRule')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

EditRuleForm.defaultProps = {
  onRuleDelete: undefined,
  locality: undefined,
};
