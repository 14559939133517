import { UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { SwrStaticKeys } from 'src/types/swr-keys.type';
import useSWR from 'swr';
import { useCallback, useEffect } from 'react';

export const useGetOktaUser = (): {
    userInfo: UserClaims;
    refreshUserInfo: () => Promise<void>;
} => {
    const { authState, oktaAuth } = useOktaAuth();

    const { data: userInfo, mutate } = useSWR(
        authState?.isAuthenticated ? SwrStaticKeys.OKTA_GET_USER : null,
        async () => {
            return await oktaAuth.getUser();
        },
        {
            revalidateOnFocus: false,
        },
    );

    const refreshUserInfo = useCallback(async () => {
        await mutate();
    }, [mutate]);

    return { userInfo, refreshUserInfo };
};
