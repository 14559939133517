import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React, { ReactElement, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs from 'dayjs';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/system/useTheme';
import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import useYupValidationResolver from '../../../utils/formUtils/yupValidationResolver';
import AcknowledgementInputArea, {
  AcknowledgementCheckboxProps,
  AcknowledgementDateInputProps,
  AcknowledgementInputProps,
} from './AcknowledgementInputArea';
import { formatDate } from '../../../utils';
import { RuleChangeReason } from '../../models/LaborRuleConfiguration';
import { getDateWithAddedDays } from '../../utils/utils';

export type ConfirmationFormValues = {
  [x: string]: boolean | string | RuleChangeReason;
  effectiveDate: string;
  changeReason: RuleChangeReason | '';
  acknowledgementCheckbox: boolean;
};
interface LaborRuleBaseConfirmationModalProps {
  title: string;
  dataTestId: string;
  subHeaderText: string;
  body?: ReactElement;
  ctaText: string;
  dateReadOnly?: boolean;
  dateEffectiveDateDefault?: string;
  loading?: boolean;
  handleClose: () => void;
  ctaAction: (confirmData: ConfirmationFormValues) => Promise<void>;
  ctaRed?: boolean;
  secondCheckbox?: AcknowledgementCheckboxProps;
  dialogMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export default function LaborRuleBaseConfirmationModal(props: LaborRuleBaseConfirmationModalProps) {
  const {
    title,
    dataTestId,
    body,
    subHeaderText,
    ctaText,
    handleClose,
    ctaAction,
    dateReadOnly,
    dateEffectiveDateDefault,
    loading,
    ctaRed,
    secondCheckbox,
    dialogMaxWidth,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const [isPastEffectiveDate, setIsPastEffectiveDate] = useState(false);

  const effectiveDate =
    dateEffectiveDateDefault ||
    formatDate(getDateWithAddedDays(1), null, {
      options: { year: 'numeric', month: '2-digit', day: '2-digit' },
    });

  const defaultValues: ConfirmationFormValues = {
    effectiveDate,
    changeReason: '',
    acknowledgementCheckbox: false,
  };
  if (secondCheckbox) {
    defaultValues[secondCheckbox.name] = false;
  }

  const validationObj = secondCheckbox
    ? {
        acknowledgementCheckbox: yup.boolean().required().isTrue(),
        [secondCheckbox.name]: yup.boolean().required().isTrue(),
        effectiveDate: yup
          .date()
          .required()
          .min(getDateWithAddedDays(-28), t('laborRules.saveRuleModal.errorDay'))
          .typeError(t('laborRules.saveRuleModal.errorType')),
        changeReason: yup.string().required(),
      }
    : {
        acknowledgementCheckbox: yup.boolean().required().isTrue(),
        effectiveDate: yup
          .date()
          .required()
          .min(getDateWithAddedDays(-28), t('laborRules.saveRuleModal.errorDay'))
          .typeError(t('laborRules.saveRuleModal.errorType')),
        changeReason: yup.string().required(),
      };
  const validationSchema = yup.object(validationObj);

  const resolver = useYupValidationResolver(validationSchema);

  const formContext = useForm({ defaultValues, resolver });
  const { control, watch } = formContext;
  const {
    formState: { errors },
  } = formContext;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (data: ConfirmationFormValues, e: any) => {
    const confirmationForm = {
      ...data,
      effectiveDate: new Date(Date.parse(data.effectiveDate)).toISOString().split('T')[0],
    };
    e.preventDefault();
    await ctaAction(confirmationForm);
  };

  const checkboxOne: AcknowledgementCheckboxProps = {
    name: 'acknowledgementCheckbox',
    label: t(`laborRules.laborRuleModalBase.acknowledgementCheckbox`),
    datatestid: 'acknowledgement-checkbox-input',
    value: 'acknowledgementCheckbox',
    readOnly: false,
    required: true,
    disabled: loading || false,
    control,
    gridProps: { xs: 12 },
  };

  const checkboxTwo = secondCheckbox ? { ...secondCheckbox, control, gridProps: { xs: 12 } } : null;

  const changeReasonSelect: AcknowledgementInputProps = {
    name: 'changeReason',
    dataTestId: `change-reason-select`,
    readOnly: false,
    required: true,
    variant: 'select',
    placeholder: 'Select one',
    options: Object.values(RuleChangeReason)
      .filter((val) => val !== RuleChangeReason.INITIALIZATION)
      .map((val) => ({
        label: val.charAt(0).toUpperCase() + val.slice(1).toLocaleLowerCase().replace('_', ' '),
        value: val,
      })),
  };

  const dateField: AcknowledgementDateInputProps = {
    name: 'effectiveDate',
    dataTestId: 'effectiveDate-textfield',
    readOnly: dateReadOnly || false,
    disableUnderline: dateReadOnly || false,
    required: true,
    variant: dateReadOnly ? 'standard' : 'date',
  };

  const watchEffectiveDate = watch('effectiveDate');

  useEffect(() => {
    const isPastDate = dayjs(watchEffectiveDate).isBefore(dayjs(), 'day');
    setIsPastEffectiveDate(isPastDate);
  }, [watchEffectiveDate]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Dialog maxWidth={dialogMaxWidth} open onClose={loading ? () => {} : handleClose} data-testid={dataTestId}>
      <DialogTitle>
        <Grid container justifyContent='space-between' alignItems='baseline'>
          {title}
          <IconButton
            aria-label={t('buttonText.close')}
            data-testid='close-icon-btn'
            disabled={loading}
            onClick={handleClose}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid>
          <Grid item mb={2}>
            <Typography variant='body1'>{subHeaderText}</Typography>
          </Grid>

          {isPastEffectiveDate && (
            <Alert
              severity='warning'
              data-testid='past-effective-date-alert'
              sx={{
                marginTop: 3,
                marginBottom: 2,
              }}
            >
              <AlertTitle>{t('laborRules.laborRuleModalBase.pastEffectiveDateWarningModalTitle')}</AlertTitle>
              <Typography variant='body2'>
                {t('laborRules.laborRuleModalBase.pastEffectiveDateWarningModalBody')}
              </Typography>
            </Alert>
          )}

          {body && <Grid item>{body}</Grid>}
          <Grid item container direction='column' data-testid='acknowledgement-div'>
            <Typography variant='caption' sx={{ color: theme.palette.grey[600] }}>
              {t('laborRules.allFieldsRequired')}
            </Typography>
            <Typography
              variant='caption'
              sx={{
                mt: 2,
                color: errors?.acknowledgementCheckbox?.message ? theme.palette.error.main : theme.palette.grey[600],
              }}
            >
              {t('laborRules.pleaseAccept')}
            </Typography>
          </Grid>
          <Grid item>
            <FormContainer formContext={formContext}>
              <AcknowledgementInputArea
                firstInput={changeReasonSelect}
                dateInput={dateField}
                checkboxOne={checkboxOne}
                checkboxTwo={checkboxTwo}
                tKey='laborRules.laborRuleModalBase'
              />
              <DialogActions>
                <Button
                  data-testid='go-back-button'
                  aria-label='Go back'
                  disabled={loading}
                  onClick={() => handleClose()}
                >
                  {t('laborRules.laborRuleModalBase.goBackButton')}
                </Button>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  color={ctaRed ? 'error' : 'primary'}
                  data-testid='confirm-action-button'
                  loading={loading}
                  aria-label={ctaText}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => formContext.handleSubmit(handleSubmit)(e)}
                >
                  {ctaText}
                </LoadingButton>
              </DialogActions>
            </FormContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

LaborRuleBaseConfirmationModal.defaultProps = {
  body: undefined,
  dateReadOnly: false,
  dateEffectiveDateDefault: '',
  loading: false,
  ctaRed: false,
  secondCheckbox: null,
  dialogMaxWidth: 'sm',
};
