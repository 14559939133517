export enum CompactDisplay {
  SHORT = 'short',
  LONG = 'long',
  UNDEFINED = '',
}

export enum SignDisplay {
  AUTO = 'auto',
  NEVER = 'never',
  ALWAYS = 'always',
  EXCEPT_ZERO = 'exceptZero',
  UNDEFINED = '',
}

export enum CurrencyDisplay {
  SYMBOL = 'symbol',
  NARROW_SYMBOL = 'narrowSymbol',
  CODE = 'code',
}

export enum IsoCurrency {
  US_DOLLAR = 'USD',
  MEXICAN_PESO = 'MXN',
  CANADIAN_DOLLAR = 'CAD',
}

export enum LocaleMatcher {
  BEST_FIT = 'best fit',
  LOOKUP = 'lookup',
}

export enum CurrencySign {
  ACCOUNTING = 'accounting',
  STANDARD = 'standard',
}

/**
 * CurrencyFormatOptions is a subset of Intl.NumberFormatOptions
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
 * ----------------------------------------------------
 *
 * compactDisplay?: "short" | "long" | undefined;
 *   Only used when notation is "compact". Takes either "short" (default) or "long"
 *
 * currency (REQUIRED):
 *   The currency to use in currency formatting. Possible values are the ISO 4217 currency codes,
 *   such as "USD" for the US dollar, "EUR" for the euro, or "CNY" for the Chinese RMB
 *     - see the Current currency & funds code list:
 *       https://www.six-group.com/en/products-services/financial-information/data-standards.html#scrollTo=currency-codes
 *
 * signDisplay?: "auto" | "never" | "always" | "exceptZero" | undefined;
 *   When to display the sign for the number. The default is "auto".
 *   - "auto": sign display for negative numbers only, including negative zero.
 *   - "always": always display sign.
 *   - "exceptZero": sign display for positive and negative numbers, but not zero.
 *   - "negative": sign display for negative numbers only, excluding negative zero. Experimental
 *   - "never": never display sign.
 *
 * currencyDisplay?: string | undefined;
 *   How to display the currency in currency formatting. The default is "symbol".
 *   - "symbol": use a localized currency symbol such as €.
 *   - "narrowSymbol": use a narrow format symbol ("$100" rather than "US$100").
 *   - "code": use the ISO currency code.
 *   - "name": use a localized currency name such as "dollar".
 *
 * currencySign?: string | undefined;
 *   In many locales, accounting format means to wrap the number with parentheses instead of appending
 *   a minus sign. You can enable this formatting by setting the currencySign option to "accounting".
 *   - The default value is "standard".
 *
 * localeMatcher: 'best fit' | 'lookup'
 *   The locale matching algorithm to use. Possible values are "lookup" and "best fit"; the default is "best fit".
 *   For information about this option, see the Intl page:
 *   - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locale_negotiation
 *
 * minimumFractionDigits: number
 *   The maximum number of fraction digits to use. Possible values are from 0 to 20; the default for
 *   plain number formatting is the larger of minimumFractionDigits and 3; the default for currency
 *   formatting is the larger of minimumFractionDigits and the number of minor unit digits provided
 *   by the ISO 4217 currency code list (2 if the list doesn't provide that information); the default
 *   for percent formatting is the larger of minimumFractionDigits and 0.
 */
export interface CurrencyFormatOptions {
  currency: IsoCurrency;
  compactDisplay?: CompactDisplay;
  signDisplay?: SignDisplay;
  currencyDisplay?: CurrencyDisplay | string;
  currencySign?: CurrencySign;
  localeMatcher?: LocaleMatcher;
  minimumFractionDigits?: number;
}

export interface CurrencyFormat {
  locale?: string; // Optional. By default locale is determined from the key in the Region settings
  options?: CurrencyFormatOptions;
}
