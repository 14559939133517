import Close from '@mui/icons-material/Close';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrgContext } from '../CommonComponentWrapper';
import OrganizationAllOrgsList from './OrganizationAllOrgsList';
import { OrganizationDialogProvider, useOrganizationDialogContext } from './OrganizationDialogProvider';
import OrganizationSearchBar from './OrganizationSearchBar';

const MobileTransition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction='up' ref={ref} {...props} />
);

const WebTransition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Fade ref={ref} {...props} />
);

function OrganizationSwitcherDialogInner() {
  const theme = useTheme<Theme>();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const { setSearchValue, searchValue, handleExpandOrgClick, resetExpandedOrgs, expandedOrg } =
    useOrganizationDialogContext();
  const { isOrgDialogOpen, setIsOrgDialogOpen, userOrganizations } = useOrgContext();

  const handleSearchValueChange = (value: string) => {
    setSearchValue(value);
    if (value) {
      userOrganizations.forEach((org) => {
        if (expandedOrg.has(org.id)) {
          return;
        }

        if (org.children.some((child) => child.includes(searchValue))) {
          handleExpandOrgClick(org.id);
        }
      });
    } else {
      resetExpandedOrgs();
    }
  };

  const handleDialogClose = () => {
    setIsOrgDialogOpen(false);
    resetExpandedOrgs();
  };

  return (
    <Dialog
      fullScreen={isMobileView}
      maxWidth={isMobileView ? undefined : false}
      open={isOrgDialogOpen}
      onClose={handleDialogClose}
      TransitionComponent={isMobileView ? MobileTransition : WebTransition}
      sx={{
        '& .MuiDialog-container': {
          alignItems: {
            xs: 'flex-end',
            sm: 'center',
          },
        },
        '& .MuiPaper-root': {
          borderRadius: '8px',
          [theme.breakpoints.up('sm')]: {
            height: '812px',
            maxHeight: '80%',
          },
          [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
          },
          width: '600px',
        },
        '& .MuiDialog-paperFullWidth': {
          m: 0,
          width: '100%',
        },
        '.MuiList-root': {
          padding: '0px 12px 20px 12px',
        },
      }}
    >
      <Box padding='16px 10px 16px 24px'>
        <DialogTitle sx={{ padding: 0 }} variant='h6'>
          {t('mockOrgSwitcher.menu.title')}
        </DialogTitle>
      </Box>
      <IconButton
        title={t('mockOrgSwitcher.accessibility.close')}
        onClick={handleDialogClose}
        sx={{
          position: 'absolute',
          top: '12px',
          right: '16px',
        }}
      >
        <Close />
      </IconButton>
      <Box sx={{ padding: '0px 24px 24px' }}>
        <OrganizationSearchBar onChange={handleSearchValueChange} />
      </Box>
      <DialogContent sx={{ overflowY: 'auto', padding: 0 }}>
        <Box
          sx={{
            padding: '8px 24px',
            width: '100%',
          }}
        >
          <Typography color={theme.palette.text.secondary} variant='subtitle2'>
            {t('mockOrgSwitcher.dialog.allTitle')}
          </Typography>
        </Box>
        <OrganizationAllOrgsList />
      </DialogContent>
    </Dialog>
  );
}

function OrganizationSwitcherDialog() {
  const { setIsOrgDialogOpen, updateOrganization } = useOrgContext();

  return (
    <OrganizationDialogProvider setIsOrgDialogOpen={setIsOrgDialogOpen} updateOrganization={updateOrganization}>
      <OrganizationSwitcherDialogInner />
    </OrganizationDialogProvider>
  );
}

export default OrganizationSwitcherDialog;
