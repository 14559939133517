import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import ContextProviderProps from '../models/ContextProviderProps';

export enum DialogContextKeys {
  NONE = 'NONE',
  VENDOR_ITEMS_UPLOAD = 'VENDOR_ITEMS_UPLOAD',
  VENDOR_DETAILS_UPLOAD = 'VENDOR_DETAILS_UPLOAD',
  GL_ACCOUNTS_UPLOAD = 'GL_ACCOUNTS_UPLOAD',
}

interface DrawerContextApi {
  open: () => void;
  close: () => void;
  isOpen: () => boolean;
}

interface IDialogContextCreation {
  handleDialog: (dialogKey: DialogContextKeys) => DrawerContextApi;
}

export const DialogContext = createContext<IDialogContextCreation>({
  handleDialog: (_dialogKey: DialogContextKeys) => ({
    open: () => null,
    close: () => null,
    isOpen: () => false,
  }),
});

export const useDialog = () => useContext(DialogContext);

export function DialogContextProvider({ children }: ContextProviderProps) {
  const [selectedKey, setSelectedKey] = useState<DialogContextKeys>(DialogContextKeys.NONE);
  const [open, setOpen] = useState<boolean>(false);

  const openDialog = (dialogKey: DialogContextKeys) => {
    if (selectedKey !== dialogKey) {
      setSelectedKey(dialogKey);
    }
    setOpen(true);
  };

  const closeDialog = (dialogKey: DialogContextKeys) => {
    if (selectedKey !== dialogKey) {
      setSelectedKey(dialogKey);
    }
    setOpen(false);
  };

  const isOpen = useCallback((dialogKey: DialogContextKeys) => selectedKey === dialogKey && open, [selectedKey, open]);

  const handleDialog = useCallback(
    (dialogKey: DialogContextKeys) => ({
      open: () => openDialog(dialogKey),
      close: () => closeDialog(dialogKey),
      isOpen: () => isOpen(dialogKey),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedKey, open]
  );

  const DialogValue = useMemo(() => ({ handleDialog }), [handleDialog]);
  return <DialogContext.Provider value={DialogValue}>{children}</DialogContext.Provider>;
}

export default DialogContextProvider;
