import React, { createContext, useContext, useMemo, useState } from 'react';
import { Res } from '@cbo/shared-library';

export interface ICompanyLinksContext {
  links: Res.Firebase.Link[] | null;
  sidebarLinks: Res.Firebase.Link[] | null;
  areLinksLoading: boolean | null;
  shouldRefetchLinks: boolean;
  setLinks: (links: Res.Firebase.Link[] | null) => void;
  setSidebarLinks: (links: Res.Firebase.Link[] | null) => void;
  setLinksLoading: (isLoading: boolean | null) => void;
  setShouldRefetchLinks: (shouldRefetch: boolean) => void;
}

export const CompanyLinksContext = createContext<ICompanyLinksContext>({
  links: null,
  sidebarLinks: null,
  areLinksLoading: null,
  shouldRefetchLinks: false,
  setLinks: (_links) => null,
  setSidebarLinks: (_links) => null,
  setLinksLoading: (_isLoading) => null,
  setShouldRefetchLinks: (_shouldRefetch) => false, // Use this to determine if table should refetch data
});

CompanyLinksContext.displayName = 'Company Links Context';

export const useCompanyLinks = () => useContext(CompanyLinksContext);

type CompanyLinksContextProviderProps = {
  linksToSet: Res.Firebase.Link[] | null;
  sidebarLinksToSet: Res.Firebase.Link[] | null;
  children: React.ReactNode;
};

export function CompanyLinksContextProvider({
  linksToSet,
  sidebarLinksToSet,
  children,
}: CompanyLinksContextProviderProps) {
  const [links, setLinks] = useState<Res.Firebase.Link[] | null>(linksToSet);
  const [sidebarLinks, setSidebarLinks] = useState<Res.Firebase.Link[] | null>(sidebarLinksToSet);
  const [areLinksLoading, setLinksLoading] = useState<boolean | null>(null);
  const [shouldRefetchLinks, setShouldRefetchLinks] = useState<boolean>(false);

  const finalPreferences = useMemo(
    () => ({
      links,
      sidebarLinks,
      areLinksLoading,
      shouldRefetchLinks,
      setLinks,
      setSidebarLinks,
      setLinksLoading,
      setShouldRefetchLinks,
    }),
    [links, sidebarLinks, areLinksLoading, shouldRefetchLinks]
  );
  return <CompanyLinksContext.Provider value={finalPreferences}>{children}</CompanyLinksContext.Provider>;
}

export default CompanyLinksContextProvider;
