import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material';
import useScrollPosition from '../../utils/hooks/useScrollPosition';

type StickyFooterProps = {
  children: React.ReactNode;
  position?: 'fixed' | 'sticky';
  addBoxShadow?: boolean;
};

function StickyFooter({ children, position, addBoxShadow }: StickyFooterProps) {
  const theme = useTheme();
  const { isAtBottomOfPage } = useScrollPosition();

  return (
    <AppBar
      position={position}
      sx={{
        top: 'auto',
        bottom: 0,
        backgroundColor: theme.palette.background.default,
        border: 'none',
        boxShadow: isAtBottomOfPage || !addBoxShadow ? 0 : '0px -4px 12px 0px rgba(0,0,0,0.08)',
      }}
      data-testid='sticky-footer'
    >
      {children}
    </AppBar>
  );
}

StickyFooter.defaultProps = {
  position: 'sticky',
  addBoxShadow: false,
};

export default StickyFooter;
