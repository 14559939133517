import { DashboardConfig } from '@cbo/shared-library/request/admin.request';
import { ScaleStyle } from '@cbo/shared-library';
import { KPIDataResponse } from '../../sales/requests/requests';

export enum ReportMetric {
  FORECAST_SALES = 'forecastSales',
  LABOR_PERCENTAGE = 'laborPercentage',
  SALES_PER_LABOR_HOUR = 'salesPerLaborHour',
  NUMBER_OF_GUESTS = 'numberOfGuests',
  GUEST_CHECK_AVERAGE = 'guestCheckAverage',
  TOTAL_DISCOUNTS = 'totalDiscounts',
  TOTAL_VOIDS = 'totalVoids',
  NUMBER_OF_CHECKS = 'numberOfChecks',
  NET_SALES = 'netSales',
}

export type KPIMap = {
  lookId: number;
  reportMetric: ReportMetric;
  cardInfo: {
    title: string;
    currency: boolean;
    percentage: boolean;
    positiveResultsPreferred: boolean;
    value: number;
    change: number;
  };
};

export type KPIResult = {
  reportMetric: ReportMetric;
  cardInfo: {
    title: string;
    currency: boolean;
    percentage: boolean;
    positiveResultsPreferred: boolean;
    value: number;
    change: number;
  };
};

// Because the lookIds in the dashboard configuration determine
// the which metrics we want, we must manually map them.
// These constants are currently defined in the 'manifest.lkml'
// files for each environment (14xx is dev, 6xx is stg, 16xx/17xx is prod).
export const getRequestedMetrics = (dashboardConfig: DashboardConfig[]): ReportMetric[] => {
  const metrics: ReportMetric[] = [];

  for (let i = 0; i < dashboardConfig.length; i += 1) {
    if (dashboardConfig[i].style === ScaleStyle.SMALL) {
      switch (dashboardConfig[i].lookIds[0].lookId) {
        case 1404:
        case 674:
        case 1693:
          metrics.push(ReportMetric.NET_SALES);
          break;
        case 1405:
        case 675:
        case 1694:
          metrics.push(ReportMetric.FORECAST_SALES);
          break;
        case 1408:
        case 678:
        case 1699:
          metrics.push(ReportMetric.GUEST_CHECK_AVERAGE);
          break;
        case 1421:
        case 687:
        case 1719:
          metrics.push(ReportMetric.LABOR_PERCENTAGE);
          break;
        case 1406:
        case 676:
        case 1697:
          metrics.push(ReportMetric.NUMBER_OF_CHECKS);
          break;
        case 1407:
        case 677:
        case 1698:
          metrics.push(ReportMetric.NUMBER_OF_GUESTS);
          break;
        case 1417:
        case 685:
        case 1716:
          metrics.push(ReportMetric.SALES_PER_LABOR_HOUR);
          break;
        case 1409:
        case 679:
        case 1692:
          metrics.push(ReportMetric.TOTAL_DISCOUNTS);
          break;
        case 1410:
        case 680:
        case 1695:
          metrics.push(ReportMetric.TOTAL_VOIDS);
          break;
        default:
          break;
      }
    } else {
      for (let j = 0; j < dashboardConfig[i].lookIds.length; j += 1) {
        if (
          dashboardConfig[i].lookIds[j].lookId === 1404 ||
          dashboardConfig[i].lookIds[j].lookId === 674 ||
          dashboardConfig[i].lookIds[j].lookId === 1693
        ) {
          metrics.push(ReportMetric.NET_SALES);
        }
      }
    }
  }

  return metrics;
};

export const defaultKPIData = {
  title: 'admin.dashboardPage.KPICards.notFound',
  value: 0,
  currency: false,
  percentage: false,
  isImprovement: false,
  arrowDirection: 'up',
  comparisonValue: 0,
  comparisonRange: 'year',
};

// Because the lookIds in the dashboard configuration determine
// the order of the cards, we must manually map them
export const mapKPIResponse = (response: KPIDataResponse): Map<number, KPIResult> => {
  const map: Map<number, KPIResult> = new Map();

  map.set(1405, {
    reportMetric: ReportMetric.FORECAST_SALES,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.forecastSales',
      currency: true,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.FORECAST_SALES].currentValue,
      change: response.reportMetricResults[ReportMetric.FORECAST_SALES].change,
    },
  });
  map.set(675, {
    reportMetric: ReportMetric.FORECAST_SALES,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.forecastSales',
      currency: true,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.FORECAST_SALES].currentValue,
      change: response.reportMetricResults[ReportMetric.FORECAST_SALES].change,
    },
  });
  map.set(1694, {
    reportMetric: ReportMetric.FORECAST_SALES,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.forecastSales',
      currency: true,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.FORECAST_SALES].currentValue,
      change: response.reportMetricResults[ReportMetric.FORECAST_SALES].change,
    },
  });

  map.set(1408, {
    reportMetric: ReportMetric.GUEST_CHECK_AVERAGE,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.guestCheckAverage',
      currency: true,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.GUEST_CHECK_AVERAGE].currentValue,
      change: response.reportMetricResults[ReportMetric.GUEST_CHECK_AVERAGE].change,
    },
  });
  map.set(678, {
    reportMetric: ReportMetric.GUEST_CHECK_AVERAGE,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.guestCheckAverage',
      currency: true,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.GUEST_CHECK_AVERAGE].currentValue,
      change: response.reportMetricResults[ReportMetric.GUEST_CHECK_AVERAGE].change,
    },
  });
  map.set(1699, {
    reportMetric: ReportMetric.GUEST_CHECK_AVERAGE,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.guestCheckAverage',
      currency: true,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.GUEST_CHECK_AVERAGE].currentValue,
      change: response.reportMetricResults[ReportMetric.GUEST_CHECK_AVERAGE].change,
    },
  });

  map.set(1421, {
    reportMetric: ReportMetric.LABOR_PERCENTAGE,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.laborPercentage',
      currency: false,
      percentage: true,
      positiveResultsPreferred: false,
      value: response.reportMetricResults[ReportMetric.LABOR_PERCENTAGE].currentValue,
      change: response.reportMetricResults[ReportMetric.LABOR_PERCENTAGE].change,
    },
  });
  map.set(687, {
    reportMetric: ReportMetric.LABOR_PERCENTAGE,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.laborPercentage',
      currency: false,
      percentage: true,
      positiveResultsPreferred: false,
      value: response.reportMetricResults[ReportMetric.LABOR_PERCENTAGE].currentValue,
      change: response.reportMetricResults[ReportMetric.LABOR_PERCENTAGE].change,
    },
  });
  map.set(1719, {
    reportMetric: ReportMetric.LABOR_PERCENTAGE,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.laborPercentage',
      currency: false,
      percentage: true,
      positiveResultsPreferred: false,
      value: response.reportMetricResults[ReportMetric.LABOR_PERCENTAGE].currentValue,
      change: response.reportMetricResults[ReportMetric.LABOR_PERCENTAGE].change,
    },
  });

  map.set(1406, {
    reportMetric: ReportMetric.NUMBER_OF_CHECKS,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.numberOfChecks',
      currency: false,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.NUMBER_OF_CHECKS].currentValue,
      change: response.reportMetricResults[ReportMetric.NUMBER_OF_CHECKS].change,
    },
  });
  map.set(676, {
    reportMetric: ReportMetric.NUMBER_OF_CHECKS,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.numberOfChecks',
      currency: false,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.NUMBER_OF_CHECKS].currentValue,
      change: response.reportMetricResults[ReportMetric.NUMBER_OF_CHECKS].change,
    },
  });
  map.set(1697, {
    reportMetric: ReportMetric.NUMBER_OF_CHECKS,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.numberOfChecks',
      currency: false,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.NUMBER_OF_CHECKS].currentValue,
      change: response.reportMetricResults[ReportMetric.NUMBER_OF_CHECKS].change,
    },
  });

  map.set(1407, {
    reportMetric: ReportMetric.NUMBER_OF_GUESTS,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.numberOfGuests',
      currency: false,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.NUMBER_OF_GUESTS].currentValue,
      change: response.reportMetricResults[ReportMetric.NUMBER_OF_GUESTS].change,
    },
  });
  map.set(677, {
    reportMetric: ReportMetric.NUMBER_OF_GUESTS,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.numberOfGuests',
      currency: false,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.NUMBER_OF_GUESTS].currentValue,
      change: response.reportMetricResults[ReportMetric.NUMBER_OF_GUESTS].change,
    },
  });
  map.set(1698, {
    reportMetric: ReportMetric.NUMBER_OF_GUESTS,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.numberOfGuests',
      currency: false,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.NUMBER_OF_GUESTS].currentValue,
      change: response.reportMetricResults[ReportMetric.NUMBER_OF_GUESTS].change,
    },
  });

  map.set(1417, {
    reportMetric: ReportMetric.SALES_PER_LABOR_HOUR,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.salesPerLaborHour',
      currency: true,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.SALES_PER_LABOR_HOUR].currentValue,
      change: response.reportMetricResults[ReportMetric.SALES_PER_LABOR_HOUR].change,
    },
  });
  map.set(685, {
    reportMetric: ReportMetric.SALES_PER_LABOR_HOUR,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.salesPerLaborHour',
      currency: true,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.SALES_PER_LABOR_HOUR].currentValue,
      change: response.reportMetricResults[ReportMetric.SALES_PER_LABOR_HOUR].change,
    },
  });
  map.set(1716, {
    reportMetric: ReportMetric.SALES_PER_LABOR_HOUR,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.salesPerLaborHour',
      currency: true,
      percentage: false,
      positiveResultsPreferred: true,
      value: response.reportMetricResults[ReportMetric.SALES_PER_LABOR_HOUR].currentValue,
      change: response.reportMetricResults[ReportMetric.SALES_PER_LABOR_HOUR].change,
    },
  });

  map.set(1409, {
    reportMetric: ReportMetric.TOTAL_DISCOUNTS,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.totalDiscounts',
      currency: true,
      percentage: false,
      positiveResultsPreferred: false,
      value: response.reportMetricResults[ReportMetric.TOTAL_DISCOUNTS].currentValue,
      change: response.reportMetricResults[ReportMetric.TOTAL_DISCOUNTS].change,
    },
  });
  map.set(679, {
    reportMetric: ReportMetric.TOTAL_DISCOUNTS,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.totalDiscounts',
      currency: true,
      percentage: false,
      positiveResultsPreferred: false,
      value: response.reportMetricResults[ReportMetric.TOTAL_DISCOUNTS].currentValue,
      change: response.reportMetricResults[ReportMetric.TOTAL_DISCOUNTS].change,
    },
  });
  map.set(1692, {
    reportMetric: ReportMetric.TOTAL_DISCOUNTS,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.totalDiscounts',
      currency: true,
      percentage: false,
      positiveResultsPreferred: false,
      value: response.reportMetricResults[ReportMetric.TOTAL_DISCOUNTS].currentValue,
      change: response.reportMetricResults[ReportMetric.TOTAL_DISCOUNTS].change,
    },
  });

  map.set(1410, {
    reportMetric: ReportMetric.TOTAL_VOIDS,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.totalVoids',
      currency: true,
      percentage: false,
      positiveResultsPreferred: false,
      value: response.reportMetricResults[ReportMetric.TOTAL_VOIDS].currentValue,
      change: response.reportMetricResults[ReportMetric.TOTAL_VOIDS].change,
    },
  });
  map.set(680, {
    reportMetric: ReportMetric.TOTAL_VOIDS,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.totalVoids',
      currency: true,
      percentage: false,
      positiveResultsPreferred: false,
      value: response.reportMetricResults[ReportMetric.TOTAL_VOIDS].currentValue,
      change: response.reportMetricResults[ReportMetric.TOTAL_VOIDS].change,
    },
  });
  map.set(1695, {
    reportMetric: ReportMetric.TOTAL_VOIDS,
    cardInfo: {
      title: 'admin.dashboardPage.KPICards.totalVoids',
      currency: true,
      percentage: false,
      positiveResultsPreferred: false,
      value: response.reportMetricResults[ReportMetric.TOTAL_VOIDS].currentValue,
      change: response.reportMetricResults[ReportMetric.TOTAL_VOIDS].change,
    },
  });

  return map;
};

export const xAxisFormatter = (value: number, isSameDay: boolean, xAxis: string[]): string => {
  if (isSameDay) {
    if (value === 0) {
      return '12am';
    }
    if (value === 12) {
      return '12pm';
    }
    if (value > 12) {
      return (value - 12).toString();
    }
    return value.toString();
  }
  return xAxis[value];
};
