import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ListProps, SwitchProps, useTheme } from '@mui/material';
import { Control, Controller, ControllerRenderProps, FieldValues, UseFormResetField } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React, { ForwardedRef, useEffect, useRef, useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Search from '@mui/icons-material/Search';
import ToggleButton from '../ToggleButton/ToggleButton';

export interface IOption {
  group: string;
  title: string;
}

export interface MultiselectProps extends SwitchProps {
  control: Control;
  options: IOption[];
  name: string;
  plural: string;
  singular: string;
  label: string;
  resetField: UseFormResetField<FieldValues>;
  collapsibleGroups?: boolean;
}

export interface CustomListboxProps {
  setOpen: (open: boolean) => void;
  setSearching: (searching: boolean) => void;
  setIsEmpty: (isEmpty: boolean) => void;
  setQuery: (query: string) => void;
  query: string | undefined;
  options: IOption[];
  reset: () => void;
}

export interface GroupProps {
  label: string;
  children: React.ReactNode;
  isSearching: boolean;
  collapsibleGroups: boolean;
  field: ControllerRenderProps<FieldValues, string>;
  checkGroup: (group: string, field: ControllerRenderProps<FieldValues, string>) => boolean;
  selectGroup: (group: string, field: ControllerRenderProps<FieldValues, string>) => void;
  isLastGroup: boolean;
}

const filterByQuery = (options: IOption[], query: string) =>
  options.filter((opt) => opt.title.toLowerCase().includes(query.trim().toLowerCase()));

function Group({
  label,
  children,
  isSearching,
  collapsibleGroups,
  field,
  checkGroup,
  selectGroup,
  isLastGroup,
}: GroupProps) {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List>
      <ListItemButton onClick={handleClick} sx={{ justifyContent: 'space-between' }}>
        {!isSearching && collapsibleGroups && (open ? <ExpandLess /> : <ExpandMore />)}
        <Typography>{label}</Typography>
        <ToggleButton
          label=''
          labelPlacement='start'
          name='group'
          checked={checkGroup(label, field)}
          onChange={() => {
            // Don't change state of the collapse menu if the toggle button was clicked.
            // You should not use open directly because Sonarqube marks it as a bug
            const tempVar = open;
            setOpen(tempVar);
            selectGroup(label, field);
          }}
        />
      </ListItemButton>
      {isSearching || !collapsibleGroups ? <div>{children}</div> : <Collapse in={open}>{children}</Collapse>}
      {!isSearching && !isLastGroup && <Divider sx={{ width: '90%', marginLeft: '5%' }} />}
    </List>
  );
}

function CustomListbox({ setOpen, setSearching, setIsEmpty, setQuery, query, options, reset }: CustomListboxProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const searchRef = useRef<HTMLDivElement>(null);

  return function CustomListboxContent(props: ListProps, ref: ForwardedRef<HTMLDivElement>) {
    // Work around to keep search text field in focus while typing
    useEffect(() => {
      searchRef?.current?.focus();
    }, []);

    return (
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box sx={{ overflow: 'hidden', paddingTop: '17px' }}>
          <Box display='flex' justifyContent='center'>
            <TextField
              inputRef={searchRef}
              label={t('multiselect.search')}
              inputProps={{
                'data-testid': 'search-field',
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                ),
                value: query,
                onClick: () => searchRef?.current?.focus(),
                onFocus: () => setOpen(true),
                onChange: (e) => {
                  if (e.target.value.length > 0) {
                    setSearching(true);
                  } else {
                    setSearching(false);
                  }
                  // If query produces no results, need to disable rendering group
                  const results = filterByQuery(options, e.target.value);
                  if (results.length === 0) {
                    setIsEmpty(true);
                  } else {
                    setIsEmpty(false);
                  }
                  setQuery(e.target.value);
                },
              }}
              variant='standard'
              sx={{ marginTop: '8px', width: '88%' }}
            />
          </Box>
          <List {...props} />
          <Box
            display='flex'
            justifyContent='flex-end'
            sx={{
              borderTopWidth: '1px',
              borderTopColor: theme.palette.divider,
              borderTopStyle: 'solid',
              height: '60px',
            }}
          >
            <Button
              sx={{
                textTransform: 'none',
                alignSelf: 'center',
                height: '26px',
                paddingRight: '30px',
              }}
              onClick={() => {
                reset();
              }}
            >
              {t('multiselect.resetSelection')}
            </Button>
          </Box>
        </Box>
      </ClickAwayListener>
    );
  };
}

function Multiselect({
  control,
  options,
  name,
  plural,
  singular,
  label,
  resetField,
  collapsibleGroups,
}: MultiselectProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;
  const [open, setOpen] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [query, setQuery] = useState<string>();
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const customListBox = React.forwardRef(
    CustomListbox({
      setOpen,
      setSearching,
      setIsEmpty,
      setQuery,
      query,
      options,
      reset: () => {
        resetField(name);
        setQuery('');
        setSearching(false);
      },
    })
  );
  const searchItem: IOption = {
    group: t('multiselect.searchGroup'),
    title: t('multiselect.noResults'),
  };

  const checkOption = (option: IOption, field: ControllerRenderProps<FieldValues, string>) =>
    (field.value as IOption[]).some((opt) => opt.title === option.title);

  const checkGroup = (group: string, field: ControllerRenderProps<FieldValues, string>) => {
    const values = field.value as IOption[];
    let groupLength: number;
    let selectedGroupLength: number;
    if (searching && query && query?.length > 0) {
      groupLength = filterByQuery(options, query).length;
      selectedGroupLength = filterByQuery(values, query).length;
    } else {
      groupLength = options.filter((opt) => opt.group === group).length;
      selectedGroupLength = values.filter((opt) => opt.group === group).length;
    }
    return groupLength === selectedGroupLength;
  };

  const selectGroup = (group: string, field: ControllerRenderProps<FieldValues, string>) => {
    const values = field.value as IOption[];
    let groupedOptions: IOption[];
    let selectedGroupOpts: IOption[];
    if (searching && query && query?.length > 0) {
      groupedOptions = filterByQuery(options, query);
      selectedGroupOpts = filterByQuery(values, query);
    } else {
      groupedOptions = options.filter((opt) => opt.group === group);
      selectedGroupOpts = values.filter((opt) => opt.group === group);
    }

    let updatedOptions: IOption[];
    if (selectedGroupOpts.length === 0) {
      // select all
      updatedOptions = [...values, ...groupedOptions];
    } else if (selectedGroupOpts.length !== groupedOptions.length) {
      // on partial select, select rest in group
      const optsToAdd = groupedOptions.filter((opt) => !selectedGroupOpts.some((val) => opt.title === val.title));
      updatedOptions = [...values, ...optsToAdd];
    } else {
      // unselect all
      updatedOptions = searching
        ? values.filter((opt) => !selectedGroupOpts.some((val) => opt.title === val.title))
        : values.filter((opt) => opt.group !== group);
    }
    field.onChange(updatedOptions);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          id={name}
          options={options}
          value={field.value}
          getOptionDisabled={(option) => option.title === searchItem.title}
          groupBy={isEmpty ? undefined : (option) => (searching ? searchItem.group : option.group)}
          getOptionLabel={(option) => option.title}
          sx={{
            maxWidth: 275,
          }}
          renderInput={({ inputProps, ...params }) => (
            <TextField
              {...params}
              variant='standard'
              label={label}
              inputProps={{ ...inputProps, readOnly: true }}
              onClick={() => {
                setOpen(!open);
              }}
              data-testid={`${label}-multiselect-input`}
            />
          )}
          onChange={(_, value) => {
            field.onChange(value);
          }}
          renderGroup={
            isEmpty
              ? undefined
              : (params) => (
                  <Group
                    label={params.group}
                    key={params.key}
                    isSearching={searching}
                    collapsibleGroups={!!collapsibleGroups} // This is always set by default props, but typescript doesn't believe it.
                    field={field}
                    selectGroup={selectGroup}
                    checkGroup={checkGroup}
                    isLastGroup={options[options.length - 1].group === params.group}
                  >
                    {params.children}
                  </Group>
                )
          }
          renderOption={(props, option) => (
            <ListItem
              {...props}
              secondaryAction={
                <Button
                  data-testid={`only-button-${option.title}`}
                  variant='text'
                  style={{ padding: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    field.onChange([option]);
                  }}
                  sx={{
                    visibility: 'hidden',
                  }}
                >
                  {t('sales.POSEventLogReport.only')}
                </Button>
              }
              sx={{
                '&:hover .MuiListItemSecondaryAction-root .MuiButtonBase-root': {
                  visibility: 'inherit',
                },
              }}
            >
              {option.title !== searchItem.title && (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={checkOption(option, field)}
                  data-testid={`${option.group}-${option.title}-checkbox`}
                />
              )}
              {option.title}
            </ListItem>
          )}
          multiple
          disableCloseOnSelect
          renderTags={(value) => `(${value.length}) ${value.length > 1 ? plural : singular} selected`}
          disableClearable
          ListboxProps={{
            sx: {
              width: 328,
              maxHeight: 350,
              '.MuiAutocomplete-option[aria-selected="true"]': {
                backgroundColor: theme.palette.background.paper,
              },
              // Focused
              '.MuiAutocomplete-option.Mui-focused': {
                backgroundColor: theme.palette.background.paper,
              },
              // Selected and Focused
              '.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                backgroundColor: theme.palette.background.paper,
              },
              // Disabled
              '.MuiAutocomplete-option[aria-disabled="true"]': {
                opacity: 1,
              },
            },
          }}
          ListboxComponent={customListBox}
          componentsProps={{ popper: { placement: 'bottom-start', style: { width: 'fit-content' } } }}
          open={open} // We have to control the open state, otherwise going to the search bar closes the popper.
          isOptionEqualToValue={(option, value) =>
            searching
              ? option.title === value.title || value.title === searchItem.title
              : option.group === value.group && option.title === value.title
          }
          data-testid={`${name}-multiselect`}
          filterOptions={(opts) => {
            if (query && query.length > 0) {
              const filteredOpts = filterByQuery(opts, query);
              // If filtered list is empty, return no results item. This workaround prevents
              // the default no options overlay, which removes our custom list box resulting in
              // no way to update the search or options list.
              return filteredOpts.length > 0 ? filteredOpts : [searchItem];
            }
            return opts;
          }}
        />
      )}
    />
  );
}

Multiselect.defaultProps = {
  collapsibleGroups: true,
};

export default Multiselect;
