import { Req } from '@cbo/shared-library';
import { FirebaseApp } from 'firebase/app';
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import {
  Functions,
  FunctionsErrorCode,
  HttpsCallable,
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export interface FirebaseAPI {
  initFirebaseApi: () => void;
  functions: Functions | null;
  auth: Auth | null;
  exchangeOktaToken: HttpsCallable<Req.Firebase.FirebaseTokenRequest, string> | null;
  bslProxy: HttpsCallable<Req.Firebase.BslProxyRequestNcrId, unknown> | null;
  refreshAuth: HttpsCallable<{ oktaJwt: string }, void> | null;
  emulatorInitiated: boolean;
}

export const FirebaseApiContext = createContext<FirebaseAPI>({
  initFirebaseApi(): void {
    throw new Error('Function not implemented.');
  },
  emulatorInitiated: false,
  functions: null,
  auth: null,
  exchangeOktaToken: null,
  bslProxy: null,
  refreshAuth: null,
});

function initFirebaseApi(currentEmulatorState: boolean) {
  const functions = getFunctions();
  const auth = getAuth();
  let emulatorInitiated = currentEmulatorState;
  if (process.env.REACT_APP_USE_LOCAL === 'true' && !currentEmulatorState) {
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectAuthEmulator(auth, 'http://localhost:9099');
    emulatorInitiated = true;
  }
  const exchangeOktaToken: HttpsCallable<Req.Firebase.FirebaseTokenRequest, string> = httpsCallable(
    functions,
    'getfirebasetoken'
  );
  const bslProxy: HttpsCallable<Req.Firebase.BslProxyRequestNcrId, unknown> = httpsCallable(functions, 'bslproxyncrid');
  const refreshAuth: HttpsCallable<{ oktaJwt: string }, void> = httpsCallable(functions, 'refreshauth');
  return {
    functions,
    auth,
    emulatorInitiated,
    exchangeOktaToken,
    bslProxy,
    refreshAuth,
  };
}
export type FirebaseApiContextProps = PropsWithChildren<{
  app: FirebaseApp | null;
}>;
export function FirebaseApiContextWrapper({ children, app }: FirebaseApiContextProps) {
  const [functions, setFunctions] = useState<Functions | null>(null);
  const [auth, setAuth] = useState<Auth | null>(null);
  const [{ exchangeOktaToken }, setExchangeOktaToken] = useState<{
    exchangeOktaToken: HttpsCallable<Req.Firebase.FirebaseTokenRequest, string> | null;
  }>({ exchangeOktaToken: null });
  const [{ bslProxy }, setBslProxy] = useState<{
    bslProxy: HttpsCallable<Req.Firebase.BslProxyRequestNcrId, unknown> | null;
  }>({ bslProxy: null });
  const [{ refreshAuth }, setRefreshAuth] = useState<{ refreshAuth: HttpsCallable<{ oktaJwt: string }, void> | null }>({
    refreshAuth: null,
  });
  const [emulatorInitiated, setEmulatorInitiated] = useState(false);

  const init = useCallback(() => {
    const res = initFirebaseApi(emulatorInitiated);
    setFunctions(res.functions);
    setAuth(res.auth);
    setExchangeOktaToken({ exchangeOktaToken: res.exchangeOktaToken });
    setBslProxy({ bslProxy: res.bslProxy });
    setEmulatorInitiated(res.emulatorInitiated);
    setRefreshAuth({ refreshAuth: res.refreshAuth });
  }, [emulatorInitiated]);

  useEffect(() => {
    if (app) {
      init();
    }
    // do not trigger when init changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app]);

  const value: FirebaseAPI = useMemo(
    () => ({
      initFirebaseApi: init,
      functions,
      auth,
      bslProxy,
      exchangeOktaToken,
      emulatorInitiated,
      refreshAuth,
    }),
    [init, functions, auth, bslProxy, exchangeOktaToken, emulatorInitiated, refreshAuth]
  );
  return <FirebaseApiContext.Provider value={value}>{children}</FirebaseApiContext.Provider>;
}

export const useFirebaseApi = () => useContext(FirebaseApiContext);

export const bslError = Symbol('BSP Error');

// Map gRPC (firebase) error codes => REST
export const transformFirebaseFunctionsErrorCode = (firebaseFunctionCode: FunctionsErrorCode): number => {
  const errorCodeMapping: Record<FunctionsErrorCode, number> = {
    'functions/ok': 200,
    'functions/invalid-argument': 400,
    'functions/unauthenticated': 401,
    'functions/permission-denied': 403,
    'functions/not-found': 404,
    'functions/already-exists': 409,
    'functions/failed-precondition': 412,
    'functions/resource-exhausted': 429,
    'functions/cancelled': 499,
    'functions/internal': 500,
    'functions/unavailable': 503,
    'functions/deadline-exceeded': 504,
    'functions/unknown': 500,
    'functions/aborted': 409,
    'functions/out-of-range': 400,
    'functions/unimplemented': 501,
    'functions/data-loss': 500,
  };
  return errorCodeMapping[firebaseFunctionCode] ?? 500;
};

export type HttpsError = Error & { code: FunctionsErrorCode; details: unknown };

export const checkFirebaseErrorCodeExists = (error: HttpsError, codeToCheck: FunctionsErrorCode) =>
  Object.prototype.hasOwnProperty.call(error, 'code') && typeof error.code === 'string' && error.code === codeToCheck;
