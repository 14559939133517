import { useContext } from 'react';
import { AccountPreferencesContext } from '../../contexts/accountPreferencesContext';
import { formatCurrency } from '../../utils/formatters';

type CurrencyProps = {
  value: string | number;
  replaceZeroWithEmptyString?: boolean;
};

const defaultProps = {
  replaceZeroWithEmptyString: false,
};

function Currency({ value, replaceZeroWithEmptyString }: CurrencyProps) {
  const { preferences } = useContext(AccountPreferencesContext);
  const parsed = Number(value);

  if ((replaceZeroWithEmptyString && parsed === 0) || Number.isNaN(parsed)) {
    return <span> </span>;
  }

  return <span>{formatCurrency(Number(value), preferences)}</span>;
}

Currency.defaultProps = defaultProps;

export default Currency;
