import { useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import { useOktaAuth } from '@okta/okta-react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import alohaLogo from '../../../assets/alohaLogo.svg';

interface SplashScreenProps {
  children: JSX.Element;
}

function SplashScreen(props: SplashScreenProps) {
  const { children } = props;
  const { oktaAuth, authState: oktaAuthState } = useOktaAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showLoginBtn] = useState(sessionStorage.getItem('showLogin') === 'true');
  const { t } = useTranslation();

  const login = useCallback(async () => {
    await oktaAuth.signInWithRedirect();
  }, [oktaAuth]);

  useEffect(() => {
    if (!oktaAuthState) {
      return;
    }

    if (oktaAuthState.isAuthenticated === false) {
      if (!showLoginBtn) {
        login().catch((e) => {
          sessionStorage.setItem('showLogin', 'true');
        });
      }
    }
  }, [oktaAuth, oktaAuthState, showLoginBtn, login]);

  if (oktaAuthState && oktaAuthState.isAuthenticated) {
    return children;
  }

  if (oktaAuthState && !oktaAuthState.isAuthenticated) {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#431A70',
        }}
        data-testid='splash-screen'
      >
        <img src={alohaLogo} alt='aloha-by-ncr-voyix' />
        <Button
          variant='text'
          size='large'
          onClick={login}
          data-testid='login-button'
          sx={{
            visibility: showLoginBtn ? 'visible' : 'hidden',
            marginTop: isMobile ? 3 : 4,
            backgroundColor: theme.palette.primary.contrastText,
            color: '#431A70',
            '&:hover': {
              backgroundColor: theme.palette.primary.contrastText,
            },
          }}
        >
          {t('buttonText.signIn')}
        </Button>
      </Box>
    );
  }

  return <div data-testid='empty-splash-screen' />;
}

export default SplashScreen;
