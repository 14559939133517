import React from 'react';
import Box from '@mui/material/Box';
import Ty from '@mui/material/Typography';

type PlaceholderPageProps = {
  contentText: string;
  dataTestId: string;
};

function PlaceholderPage(props: PlaceholderPageProps) {
  const { contentText, dataTestId } = props;
  return (
    <Box
      display='flex'
      justifyContent='flexStart'
      paddingTop='100px'
      alignItems='center'
      height='100%'
      maxWidth='68ch'
      margin='auto'
      flexDirection='column'
      data-testid={dataTestId}
    >
      <Ty variant='h4'>{contentText}</Ty>
    </Box>
  );
}

export default PlaceholderPage;
