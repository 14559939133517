/* eslint-disable react/require-default-props */
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers-pro';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material';
import { Control, Controller, ControllerProps, FieldError, FieldValues, Path } from 'react-hook-form';
import { useAccountPreferences } from '../../contexts/accountPreferencesContext';
import { getDateTimeFormat, getDateTimeFormatForDisplay } from '../../utils';

export declare type ParseableDate<TDate> = string | number | Date | null | undefined | TDate;

export type DateTimePickerElementProps<T extends FieldValues, TInputDate, TDate = TInputDate> = Omit<
  DateTimePickerProps<TInputDate, TDate>,
  'value' | 'onChange' | 'renderInput' | 'onBlur'
> & {
  name: Path<T>;
  required?: boolean;
  isDate?: boolean;
  parseError?: (error: FieldError) => string;
  validation?: ControllerProps['rules'];
  parseDate?: (value: TDate, keyboardInputValue?: string) => TDate;
  control?: Control<T>;
  inputProps?: TextFieldProps;
  inputFormat?: string;
  disablePlaceholder?: boolean;
  disableMaskedInput?: boolean;
  disableOpenPicker?: boolean;
  helperText?: TextFieldProps['helperText'];
  handleBlur?: () => Promise<void>;
  disabled?: boolean;
};

export default function DateTimePickerElement<TFieldValues extends FieldValues>({
  isDate,
  parseError,
  name,
  required,
  parseDate,
  validation = {},
  inputProps,
  inputFormat,
  disablePlaceholder,
  disableMaskedInput,
  disableOpenPicker,
  control,
  handleBlur,
  disabled,
  ...rest
}: DateTimePickerElementProps<TFieldValues, unknown, unknown>): JSX.Element {
  if (required && !validation.required) {
    // eslint-disable-next-line no-param-reassign
    validation.required = 'This field is required';
  }

  const { preferences } = useAccountPreferences();

  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
        /* eslint-disable @typescript-eslint/no-shadow */
        <DateTimePicker
          {...rest}
          disabled={disabled}
          inputFormat={getDateTimeFormat(preferences, inputFormat)}
          disableOpenPicker={disableOpenPicker}
          disableMaskedInput={disableMaskedInput}
          value={value || ''}
          views={['year', 'month', 'day']}
          // https://github.com/mui/mui-x/issues/4487#issuecomment-1098259542

          onChange={(value, keyboardInputValue) => {
            if (handleBlur) {
              /**
               * Validate time field if keyboardInput is present or is undefined (using month picker returns undefined keyboardInputValue)
               * before making API call
               * */
              if (keyboardInputValue?.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/) || !keyboardInputValue) {
                handleBlur();
              }
            }
            onChange(value, keyboardInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params?.inputProps,
                ...(!value && {
                  value: '',
                  placeholder: getDateTimeFormatForDisplay(preferences),
                }),
              }}
              {...inputProps}
              required={!!required}
              error={invalid}
              onBlur={handleBlur}
              helperText={
                // eslint-disable-next-line no-nested-ternary
                error
                  ? typeof parseError === 'function'
                    ? parseError(error)
                    : error.message
                  : inputProps?.helperText || rest.helperText
              }
            />
          )}
        />
      )}
    />
  );
}
