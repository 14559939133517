import { useCallback, useState } from 'react';
import { AppConstants } from 'src/constants/app';
import { UpdateConfigSettingsDto, UpdateUserSettingsDto, UserSettings } from 'src/types/user-settings.type';
import { DefaultErrorHandler } from 'src/utils/services.utils';
import useSWR from 'swr';
import { ApiError } from '..';
import { Global } from '../constants/global';
import { get, put } from './common.fetchers';

const baseUrl = () => Global.Services.Provisioning;
const settingsUrl = '/user-settings/self/';

const _updateUserSettingsByName = (key: string, name: string, setting: UpdateConfigSettingsDto): Promise<null> => {
    return put<null, UpdateUserSettingsDto>(`${key}`, {
        configurationSetId: {
            name
        },
        configurationSettings: [setting]
    }, {
        headers: {
            [AppConstants.OrgHeader]: null
        }
    })
        .catch(DefaultErrorHandler)
}

const fetchUserSettingsByName = (key: string) => {
    return get<UserSettings>(`${baseUrl()}${key}`, {
        headers: {
            [AppConstants.OrgHeader]: null
        }
    }).catch(DefaultErrorHandler);
}

export const useGetUserSettingsByName = (name: string, isAuthenticated: boolean, mutationEndpoint?: string) => {
    const [isUserSettingsMutating, setIsUserSettingsMutating] = useState<boolean>(false);
    const [userSettingsMutationError, setUserSettingsMutationError] = useState<ApiError>();
    const { data: userSettings, isValidating: isUserSettingsValidating, isLoading: isUserSettingsLoading, error: getUserSettingsError, mutate } = useSWR(
        isAuthenticated ? `${settingsUrl}${name}` : null, fetchUserSettingsByName
    )

    const updateUserSettings = useCallback(async (setting: UpdateConfigSettingsDto) => {
        try {
            setIsUserSettingsMutating(true);
            await _updateUserSettingsByName(mutationEndpoint || `${baseUrl()}${settingsUrl}`, name, setting).catch(DefaultErrorHandler);
            await mutate();
            setIsUserSettingsMutating(false);
        } catch (e: unknown) {
            setUserSettingsMutationError(e as ApiError);
        }
    }, [name])

    return {
        userSettings,
        isUserSettingsLoading,
        isUserSettingsMutating,
        isUserSettingsValidating,
        userSettingsMutationError,
        getUserSettingsError,
        updateUserSettings
    }
}