import * as yup from 'yup';
import { useEffect, useMemo, useState, useCallback, Dispatch, SetStateAction } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/system/Box';
import Skeleton from '@mui/material/Skeleton';
import { useTranslation, Trans } from 'react-i18next';
import { isEqual, isNil, difference } from 'lodash';
import { FieldValues, useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import {
  GridColumns,
  DataGridPremiumProps,
  GridValueGetterParams,
  GridComparatorFn,
  gridStringOrNumberComparator,
  GridSelectionModel,
  GridRenderCellParams,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridCellParams,
  useGridApiRef,
  GridRowId,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridFilterOperator,
  GridFilterItem,
  getGridStringOperators,
} from '@mui/x-data-grid-premium';
import { alpha } from '@mui/material/styles';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import { CboRole } from '@cbo/shared-library';
import { useSnackbar } from '../../contexts/SnackbarContext';
import createFormTextField from '../../components/FormTextFields/FormTextFields';
import useYupValidationResolver from '../../utils/formUtils/yupValidationResolver';
import { useGlAccountsQuery, useGetGlAccountFilters } from '../requests/queries';
import { useUpdateIsActiveGlAccountsMutation } from '../requests/mutations';
import { GlAccount } from '../requests/requests';
import Multiselect, { IOption } from '../../components/Multiselect/Multiselect';
import CustomActiveCell from '../../components/DataGrid/CustomCells/CustomActiveCell';
import CustomTreeGroupCell from '../../components/DataGrid/CustomCells/CustomTreeGroupCell';
import GeneralLedgerAccountsSidebar, { GlAccountsSidebarState } from './GeneralLedgerAccountsSidebar';
import BulkActionDataGrid from '../../components/BulkActionDataGrid/BulkActionDataGrid';
import isPermitted from '../../lib/permissions';
import { useUsers } from '../../contexts/userContext';
import CustomActionsMenuCell, {
  DataGridActionsMenuItems,
} from '../../components/DataGrid/CustomCells/CustomActionsMenuCell';
import { DynamicButtonInfo } from '../../models/DynamicButtonInfo';
import SalesUtilities from '../utilities';

export enum GlAccountsFilterIsActive {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export interface GlAccountsFilters {
  isActive: string;
  glCategory: string[];
  glType: string[];
  glDetailType: string[];
}

interface GlAccountRow {
  [key: string]: unknown;
  hierarchy: string[];
  id: string;
}

export type GlAccountRows = GlAccount & GlAccountRow;

export type GeneralLedgerAccountsManageReportProps = {
  isGlAccountRowOpen: boolean;
  setIsGlAccountRowOpen: Dispatch<SetStateAction<boolean>>;
  sidebarState: GlAccountsSidebarState;
  setSidebarState: Dispatch<SetStateAction<GlAccountsSidebarState>>;
};

function GeneralLedgerAccountsManageReport(props: GeneralLedgerAccountsManageReportProps) {
  const { isGlAccountRowOpen, setIsGlAccountRowOpen, sidebarState, setSidebarState } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [selectedFilters, setSelectedFilters] = useState<(GlAccountsFilters | undefined)[]>();
  const isMobile = useMediaQuery((themes: Theme) => themes.breakpoints.down('sm'));
  const validationSchema = useMemo(() => yup.object({}), []);
  const resolver = useYupValidationResolver(validationSchema);
  const [glCategories, setGlCategories] = useState<string[]>([]);
  const [glCategoryNameToId, setGlCategoryNameToId] = useState<{ [key: string]: number }>({});
  const [glType, setGlType] = useState<string[]>([]);
  const [glDetailType, setGlDetailType] = useState<string[]>([]);
  const [filtersLoading, setFiltersLoading] = useState<boolean>(true); // Used to load the initial filter options
  const user = useUsers();
  const { mutateAsync: updateIsActiveGlAccounts } = useUpdateIsActiveGlAccountsMutation();
  const { setSnackbarState } = useSnackbar();

  const formContext = useForm({
    mode: 'onChange',
    resolver,
    defaultValues: {
      isActive: [],
      glCategory: [],
      glType: [],
      glDetailType: [],
    },
  });

  const { register, watch } = formContext;

  const glAccountsFilterConfig: FieldValues[] = useMemo(
    () => [
      {
        name: 'isActive',
        isDashboardFilter: true,
        required: false,
        showCheckbox: true,
        multiple: true,
        dataTestId: 'isActive',
        variant: 'autocomplete',
        gridProps: { width: 275, maxWidth: 275, paddingTop: 2, flexGrow: 1 },
        options: [GlAccountsFilterIsActive.ACTIVE, GlAccountsFilterIsActive.INACTIVE],
      },
      {
        name: 'glCategory',
        isDashboardFilter: true,
        required: false,
        showCheckbox: true,
        multiple: true,
        dataTestId: 'glCategory',
        variant: 'autocomplete',
        gridProps: { width: 275, maxWidth: 275, paddingTop: 2, flexGrow: 1 },
        options: glCategories,
      },
    ],
    [glCategories]
  );

  const glTypeFilter = useMemo(() => {
    const filter = (
      <Multiselect
        name='glType'
        control={formContext.control}
        plural={t('admin.glAccountConfiguration.generalLedgerAccounts.glTypes')}
        singular={t('admin.glAccountConfiguration.generalLedgerAccounts.glType')}
        options={glType.map((e) => ({
          group: t('admin.glAccountConfiguration.generalLedgerAccounts.selectAllGlTypes'),
          title: e,
        }))}
        label={t('admin.glAccountConfiguration.generalLedgerAccounts.glType')}
        resetField={formContext.resetField}
        collapsibleGroups={false}
      />
    );
    return filter;
  }, [formContext, t, glType]);

  const glDetailTypeFilter = useMemo(() => {
    const filter = (
      <Multiselect
        name='glDetailType'
        control={formContext.control}
        plural={t('admin.glAccountConfiguration.generalLedgerAccounts.glDetailTypes')}
        singular={t('admin.glAccountConfiguration.generalLedgerAccounts.glDetailType')}
        options={glDetailType.map((e) => ({
          group: t('admin.glAccountConfiguration.generalLedgerAccounts.selectAllGlDetailTypes'),
          title: e,
        }))}
        label={t('admin.glAccountConfiguration.generalLedgerAccounts.glDetailType')}
        resetField={formContext.resetField}
        collapsibleGroups={false}
      />
    );
    return filter;
  }, [formContext, t, glDetailType]);

  const { glAccounts, setGlAccountsContext, isError, isLoading, refetchGlAccounts } = useGlAccountsQuery(
    user.org?.bslId
  );

  const { glAccountFilters, isGetGlAccountFiltersDataLoading, isGetGlAccountFiltersError } = useGetGlAccountFilters();

  const handleRefetch = async () => {
    await refetchGlAccounts();
  };

  const handleFilterChange = async (params: {
    isActive: string;
    glCategory: string[];
    glType: IOption[];
    glDetailType: IOption[];
  }) => {
    setGlAccountsContext({
      isActive: params.isActive,
      categoryIds: params.glCategory.map((e) => glCategoryNameToId[e]),
      accountTypes: params.glType.map((type) => type.title),
      accountDetailTypes: params.glDetailType.map((detailType) => detailType.title),
    });
  };

  useEffect(() => {
    // Get all available filters
    if (
      !isNil(glAccountFilters) &&
      !isGetGlAccountFiltersError &&
      !isGetGlAccountFiltersDataLoading &&
      filtersLoading
    ) {
      setGlType(glAccountFilters.accountTypes);
      setGlDetailType(glAccountFilters.accountDetailTypes);
      const categoryNamesToIdsMap = glAccountFilters.categoryInfo
        .map((category) => ({
          [`${category.categoryName}`]: category.categoryId,
        }))
        .reduce((a, b) => ({ ...a, ...b }));
      setGlCategories(Object.keys(categoryNamesToIdsMap));
      setGlCategoryNameToId(categoryNamesToIdsMap);
      setFiltersLoading(false);
    }
  }, [filtersLoading, glAccountFilters, isGetGlAccountFiltersDataLoading, isGetGlAccountFiltersError]);

  // Watch for changes to update filters...
  const data = watch();

  useEffect(() => {
    if (isEqual(selectedFilters, data)) {
      return;
    }

    handleFilterChange({
      isActive: SalesUtilities.updateGlAccountsIsActiveFilter(data.isActive as string[]),
      glCategory: data.glCategory,
      glType: data.glType,
      glDetailType: data.glDetailType,
    });
    setSelectedFilters(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // data grid
  const apiRef = useGridApiRef();
  const [selectedRow, setSelectedRow] = useState<GlAccountRows | undefined>(undefined);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [showDeletionDialog, setShowDeletionDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [isDeactivate, setIsDeactivate] = useState(false);
  const [bulkActionAccountTree, setBulkActionAccountTree] = useState<GlAccount[] | undefined>(undefined);
  const [fromActionsMenu, setFromActionsMenu] = useState(false);

  const handleClose = () => {
    setIsGlAccountRowOpen(false);
    setSelectedRow(undefined);
    setSidebarState(GlAccountsSidebarState.VIEW);
    handleRefetch();
  };

  const handleCellClick = (params: GridCellParams) => {
    if (params.field !== 'actions' && params.field !== GRID_CHECKBOX_SELECTION_FIELD) {
      setSidebarState(GlAccountsSidebarState.VIEW);
      if (selectedRow?.id === params.row.id && isGlAccountRowOpen) {
        setIsGlAccountRowOpen(false);
        setSelectedRow(undefined);
      } else {
        setSelectedRow(params.row);
        setIsGlAccountRowOpen(true);
      }
    }
  };

  const handleUpdateIsActiveSuccess = (account: GlAccount) => {
    handleClose();
    setSnackbarState({
      open: true,
      message: (
        <Trans
          i18nKey='admin.glAccountConfiguration.actionContent.successMessageNoChildren'
          values={{
            glAccountNumber: account.accountNumber,
            glAccountName: account.accountName,
            action: t('admin.glAccountConfiguration.actionContent.updated'),
          }}
          components={{ primary: <Typography sx={{ fontWeight: '500' }} display='inline' /> }}
        />
      ),
      color: 'success',
    });
  };

  const actionsOnClickFunctions = (
    params: GridRenderCellParams
  ): Partial<Record<DataGridActionsMenuItems, () => void>> => ({
    [DataGridActionsMenuItems.Edit]: () => {
      setSelectedRow(params.row);
      setSidebarState(GlAccountsSidebarState.EDIT);
      setIsGlAccountRowOpen(true);
    },
    [DataGridActionsMenuItems.Activate]: async () => {
      if (params.row.subAccounts.length === 0) {
        const result = await updateIsActiveGlAccounts({
          isActive: true,
          isActiveAffectedAccountIds: [params.row.accountId],
        });
        if (result === true) {
          handleUpdateIsActiveSuccess(params.row as GlAccount);
        }
      } else {
        setBulkActionAccountTree([params.row as GlAccount]);
        setFromActionsMenu(true);
        setIsDeactivate(false);
        setShowEditDialog(true);
      }
    },
    [DataGridActionsMenuItems.Deactivate]: () => {
      setFromActionsMenu(true);
      setBulkActionAccountTree([params.row as GlAccount]);
      setIsDeactivate(true);
      setShowEditDialog(true);
    },
    [DataGridActionsMenuItems.Delete]: () => {
      setFromActionsMenu(true);
      setBulkActionAccountTree([params.row as GlAccount]);
      setShowDeletionDialog(true);
    },
  });

  const actionsTooltipTitles: Partial<Record<DataGridActionsMenuItems, string>> = {
    [DataGridActionsMenuItems.Edit]: t('admin.glAccountConfiguration.generalLedgerAccounts.actionTooltip', {
      action: t('admin.glAccountConfiguration.actionContent.editTitle'),
    }),
    [DataGridActionsMenuItems.Delete]: t('admin.glAccountConfiguration.generalLedgerAccounts.actionTooltip', {
      action: t('admin.glAccountConfiguration.actionContent.deleteTitle'),
    }),
    [DataGridActionsMenuItems.Deactivate]: t('admin.glAccountConfiguration.generalLedgerAccounts.activationTooltip', {
      action: t('admin.glAccountConfiguration.generalLedgerAccounts.activated'),
    }),
    [DataGridActionsMenuItems.Activate]: t('admin.glAccountConfiguration.generalLedgerAccounts.activationTooltip', {
      action: t('admin.glAccountConfiguration.generalLedgerAccounts.deactivated'),
    }),
  };

  const columns: GridColumns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
      type: 'string',
    },
    {
      field: 'categoryName',
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.glCategory'),
      pinnable: false,
      minWidth: 200,
    },
    {
      field: 'accountType',
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.glType'),
      pinnable: false,
      minWidth: 100,
    },
    {
      field: 'accountTypeDetail',
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.glDetailType'),
      pinnable: false,
      minWidth: 200,
    },
    {
      field: 'description',
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.description'),
      pinnable: false,
      minWidth: 150,
    },
    {
      field: 'isActive',
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.active'),
      pinnable: false,
      renderCell: (params) => <CustomActiveCell isActive={params.row.isActive} />,
      headerAlign: 'center',
      minWidth: 100,
    },
    {
      field: 'isDisplayedInInvoicing',
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.showForInvoicing'),
      pinnable: false,
      minWidth: 175,
      renderCell: (params) => <CustomActiveCell isActive={params.row.isDisplayedInInvoicing} />,
      headerAlign: 'center',
    },
    {
      field: 'actions',
      sortable: false,
      type: 'string',
      filterable: false,
      headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.actions'),
      pinnable: false,
      disableReorder: true,
      disableExport: true,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      resizable: false,
      renderCell: (params) => (
        <CustomActionsMenuCell
          isActive={params.row.isActive}
          actions={[
            DataGridActionsMenuItems.Edit,
            DataGridActionsMenuItems.Activate,
            DataGridActionsMenuItems.Deactivate,
            DataGridActionsMenuItems.Delete,
          ]}
          onClickFunctions={actionsOnClickFunctions(params)}
          tooltipTitles={actionsTooltipTitles}
        />
      ),
    },
  ];

  interface GlAccountCellValue {
    accountNumber: number;
    accountName: string;
  }

  const glAccountSortComparator: GridComparatorFn = (v1, v2, param1, param2) => {
    const accountComparatorResult = gridStringOrNumberComparator(
      (v1 as GlAccountCellValue).accountNumber,
      (v2 as GlAccountCellValue).accountNumber,
      param1,
      param2
    );

    if (accountComparatorResult !== 0) {
      return accountComparatorResult;
    }

    return gridStringOrNumberComparator(
      (v1 as GlAccountCellValue).accountName,
      (v2 as GlAccountCellValue).accountName,
      param1,
      param2
    );
  };

  const getTextForRowGroupingColumn = (params: GridRenderCellParams) => {
    const { accountName, accountNumber } = params.row;
    const text = t('admin.glAccountConfiguration.generalLedgerAccounts.glAccountTitle', {
      glAccountNumber: accountNumber,
      glAccountName: accountName,
    });
    return {
      cellText: text,
    };
  };

  const getApplyQuickFilter =
    (value: string) =>
    (params: GridCellParams): boolean => {
      const fullAccount = `${params.value.accountNumber} - ${params.value.accountName}`;
      return fullAccount.includes(value);
    };

  const groupedColumnFilterOperator: GridFilterOperator[] = [
    {
      value: getGridStringOperators()[0].value,
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }

        return (params: GridCellParams): boolean => {
          const fullAccount = `${params.value.accountNumber} - ${params.value.accountName}`;
          return fullAccount.includes(filterItem.value);
        };
      },
      InputComponent: getGridStringOperators()[0].InputComponent,
    },
  ];

  const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
    headerName: t('admin.glAccountConfiguration.generalLedgerAccounts.glAccount'),
    valueGetter: (params: GridValueGetterParams) => ({
      accountNumber: params.row.accountNumber,
      accountName: params.row.accountName,
    }),
    valueFormatter: (params) => {
      const value = params.value as GlAccountCellValue;
      return `${value.accountNumber} - ${value.accountName}`;
    },
    hideDescendantCount: true,
    minWidth: 400,
    disableColumnMenu: false,
    filterable: true,
    sortable: true,
    disableExport: false,
    filterOperators: groupedColumnFilterOperator,
    hideable: false,
    sortComparator: glAccountSortComparator,
    sortingOrder: ['asc', 'desc'],
    renderCell: (params: GridRenderCellParams) => (
      <CustomTreeGroupCell
        {...{
          ...params,
          ...getTextForRowGroupingColumn(params),
        }}
      />
    ),
    getApplyQuickFilterFn: getApplyQuickFilter,
  };

  const createHierarchy = useCallback((account: GlAccountRows, hierarchy: string[]): GlAccountRows[] => {
    const subAccounts: GlAccountRows[] = [];
    account.subAccounts.forEach((subAccount) => {
      const rowAccount = {
        ...subAccount,
        hierarchy: [...hierarchy, subAccount.accountId],
        id: subAccount.accountId,
      };
      subAccounts.push(rowAccount, ...createHierarchy(rowAccount, [...hierarchy, subAccount.accountId]));
    });
    return subAccounts;
  }, []);

  const glAccountsDataToRows = useCallback((): GlAccountRows[] => {
    const accounts: GlAccountRows[] = [];

    if (!isNil(glAccounts) && !isError && !isLoading) {
      glAccounts.accounts.forEach((account) => {
        const rowAccount = { ...account, hierarchy: [account.accountId], id: account.accountId };
        accounts.push(rowAccount, ...createHierarchy(rowAccount, [account.accountId]));
      });
    }

    return accounts;
  }, [glAccounts, isError, isLoading, createHierarchy]);

  // bulk actions
  const getSubaccountsForAction = (removedIds: GridRowId[], rowData: GlAccount, selectionIds: GridRowId[]) => {
    const tempSubaccounts: GlAccount[] = [];
    rowData.subAccounts.forEach((subAccount) => {
      if (selectionIds.includes(subAccount.accountId)) {
        removedIds.push(subAccount.accountId);
        tempSubaccounts.push({
          ...subAccount,
          subAccounts: getSubaccountsForAction(removedIds, subAccount, selectionIds),
        });
      }
    });
    return tempSubaccounts;
  };

  const getTopParentAccount = (row: GlAccount | null): GlAccount | undefined => {
    if (row) {
      if (!row.parentGLAccount) {
        return row;
      }
      const parentRow = getTopParentAccount(apiRef.current.getRow(row.parentGLAccount.accountId));
      return parentRow;
    }
    return undefined;
  };

  const getAccountTree = (row: GlAccount, ids: GridRowId[], removedIds: GridRowId[]) => {
    const accounts: GlAccount[] = [];
    if (ids.includes(row.accountId)) {
      accounts.push({ ...row, subAccounts: getSubaccountsForAction(removedIds, row, ids) } as GlAccount);
      removedIds.push(row.accountId);
    } else {
      row.subAccounts.forEach((child) => accounts.push(...getAccountTree(child, ids, removedIds)));
    }
    return accounts;
  };

  const getAccountsForActions = (ids: GridRowId[]) => {
    const rowElements: GlAccountRows[] = ids
      .map((s) => apiRef.current.getRow(s))
      .sort((a, b) => a.accountNumber - b.accountNumber);
    const accountsForDialog: GlAccount[] = [];
    const removedIds: GridRowId[] = [];
    rowElements.forEach((row) => {
      if (!removedIds.includes(row.accountId)) {
        const rowChildren = apiRef.current.getRowGroupChildren({ groupId: row.accountId });
        if (rowChildren.length === 0 && !row.parentGLAccount) {
          accountsForDialog.push(row as GlAccount);
        } else {
          const topParentAccount = getTopParentAccount(row as GlAccount);
          if (topParentAccount) {
            accountsForDialog.push(...getAccountTree(topParentAccount, ids, removedIds));
          }
        }
      }
    });
    return accountsForDialog;
  };

  const bulkActionButtons: DynamicButtonInfo[] = [
    {
      dataTestId: 'gl-account-bulk-activate-button',
      text: t('buttonText.activate'),
      icon: <CheckCircleIcon />,
      functionality: async (selection: GridRowId[]) => {
        const accountTree = getAccountsForActions(selection);
        if (accountTree.length === 1 && accountTree[0].subAccounts.length === 0) {
          const result = await updateIsActiveGlAccounts({
            isActive: true,
            isActiveAffectedAccountIds: [accountTree[0].accountId],
          });
          if (result === true) {
            handleUpdateIsActiveSuccess(accountTree[0]);
          }
        } else {
          setBulkActionAccountTree(accountTree);
          setIsDeactivate(false);
          setShowEditDialog(true);
        }
      },
    },
    {
      dataTestId: 'gl-account-bulk-deactivate-button',
      text: t('buttonText.deactivate'),
      icon: <CancelIcon />,
      functionality: (selection: GridRowId[]) => {
        const accountTree = getAccountsForActions(selection);
        setBulkActionAccountTree(accountTree);
        setIsDeactivate(true);
        setShowEditDialog(true);
      },
    },
    {
      dataTestId: 'gl-account-bulk-delete-button',
      text: t('buttonText.delete'),
      icon: <DeleteIcon />,
      functionality: (selection: GridRowId[]) => {
        const accountTree = getAccountsForActions(selection);
        setBulkActionAccountTree(accountTree);
        setShowDeletionDialog(true);
      },
    },
  ];

  const onSelectionModelChange = (model: GridSelectionModel) => {
    const selectedIds = difference(model, selectionModel);
    const unselectedIds = difference(selectionModel, model);
    let newSelectionModel: GridRowId[] = [];
    if (selectedIds.length > 0) {
      let selectedRowsForModel: GridRowId[] = [];
      if (selectedIds.length === 1) {
        const rowChildren = apiRef.current.getRowGroupChildren({ groupId: selectedIds[0] });
        if (rowChildren.length > 0) {
          selectedRowsForModel = [selectedIds[0], ...rowChildren];
        } else {
          selectedRowsForModel = selectedIds;
        }
        newSelectionModel = [...selectionModel, ...selectedRowsForModel];
      } else {
        const allIds = apiRef.current.getAllRowIds();
        newSelectionModel = allIds;
      }
    } else if (unselectedIds.length > 0) {
      let unselectedRowsForModel: GridRowId[] = [];
      if (unselectedIds.length === 1) {
        const unselectedRow = apiRef.current.getRow(unselectedIds[0]);
        if (unselectedRow) {
          const { hierarchy } = unselectedRow;
          const rowChildren = apiRef.current.getRowGroupChildren({ groupId: unselectedIds[0] });
          if (hierarchy.length > 1 || rowChildren.length > 0) {
            hierarchy.pop();
            unselectedRowsForModel = [...unselectedRowsForModel, unselectedIds[0], ...hierarchy, ...rowChildren];
          } else {
            unselectedRowsForModel = unselectedIds;
          }
          newSelectionModel = selectionModel.filter((el) => !unselectedRowsForModel.includes(el));
        } else {
          newSelectionModel = selectionModel.filter((el) => !unselectedIds.includes(el));
        }
      } else {
        newSelectionModel = [];
      }
    } else {
      newSelectionModel = selectionModel;
    }
    const uniqueSelectionModel = newSelectionModel.filter((value, index, array) => array.indexOf(value) === index);
    setSelectionModel(uniqueSelectionModel);
    return uniqueSelectionModel;
  };

  return (
    <Box marginRight={isGlAccountRowOpen && !isMobile ? '350px' : '0'}>
      <Grid item xs={6}>
        {t('admin.glAccountConfiguration.generalLedgerAccounts.manageIntro')}
      </Grid>
      <FormContainer formContext={formContext}>
        <Grid container spacing={1.5} pt={2} mb={2}>
          {!filtersLoading
            ? [
                ...glAccountsFilterConfig.map((field: FieldValues) =>
                  createFormTextField(
                    field,
                    { t, tKey: 'admin.glAccountConfiguration.generalLedgerAccounts' },
                    register
                  )
                ),
                <Grid item key='gl-type-multiselect' sx={{ width: 214, maxWidth: 275, paddingTop: 2, flexGrow: 1 }}>
                  {glTypeFilter}
                </Grid>,
                <Grid
                  item
                  key='gl-detail-type-multiselect'
                  sx={{ width: 214, maxWidth: 275, paddingTop: 2, flexGrow: 1 }}
                >
                  {glDetailTypeFilter}
                </Grid>,
              ]
            : glAccountsFilterConfig.map((field: FieldValues) => (
                <Grid item key={field.name}>
                  <Skeleton variant='rectangular' width={214} height={48} />
                </Grid>
              ))}
        </Grid>
      </FormContainer>
      <BulkActionDataGrid
        apiRef={apiRef}
        buttons={bulkActionButtons}
        loading={isLoading}
        columns={columns}
        rows={glAccountsDataToRows()}
        itemsPerPage={10}
        getTreeDataPath={(row) => row.hierarchy}
        treeData
        disableColumnSelector={false}
        groupingColDef={groupingColDef}
        pinnedColumns={{ right: ['actions'] }}
        disableExport={false}
        data-testid='gl-accounts-datagrid'
        disableColumnMenu={false}
        sortingOrder={['desc', 'asc']}
        initialState={{
          sorting: {
            sortModel: [{ field: GRID_TREE_DATA_GROUPING_FIELD, sort: 'asc' }],
          },
        }}
        treeCustomSelectionFunction={onSelectionModelChange}
        customResetActions={() => {
          setSelectionModel([]);
          setBulkActionAccountTree(undefined);
        }}
        selectionModel={selectionModel}
        onCellClick={(params: GridCellParams) => {
          handleCellClick(params);
        }}
        checkboxSelection={isPermitted(user, [CboRole.GL_ACCOUNTS_WRITE])}
        disableSelectionOnClick
        getRowClassName={({ id }) => (selectedRow?.id === id ? 'focused-row' : '')}
        sx={{
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .focused-row': {
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.mode === 'light' ? 0.12 : 0.16),
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, theme.palette.mode === 'light' ? 0.14 : 0.24),
            },
          },
        }}
        columnVisibilityModel={{ actions: isPermitted(user, [CboRole.GL_ACCOUNTS_WRITE]) }}
      />
      <GeneralLedgerAccountsSidebar
        isOpen={isGlAccountRowOpen}
        onClose={handleClose}
        glAccount={selectedRow}
        sidebarState={sidebarState}
        setSidebarState={setSidebarState}
        handleRefetch={handleRefetch}
        bulkActionAccountTree={bulkActionAccountTree}
        setBulkActionAccountTree={setBulkActionAccountTree}
        showDeletionDialog={showDeletionDialog}
        setShowDeletionDialog={setShowDeletionDialog}
        showEditDialog={showEditDialog}
        setShowEditDialog={setShowEditDialog}
        isDeactivate={isDeactivate}
        setIsDeactivate={setIsDeactivate}
        fromActionsMenu={fromActionsMenu}
        setFromActionsMenu={setFromActionsMenu}
      />
    </Box>
  );
}

export default GeneralLedgerAccountsManageReport;
