import dayjs, { Dayjs } from 'dayjs';
import { TFunction } from 'react-i18next';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import duration from 'dayjs/plugin/duration';
import uniqueBy from 'lodash/uniqBy';
import { ActivationTypes } from '@cbo/shared-library/types/activation';
import { blue } from '@mui/material/colors';
import {
  ActualBreakResponseDto,
  ActualShiftResponseDto,
  PlannedShiftResponseDto,
} from '@cbo/shared-library/response/scheduling.response';
import { Scheduling as SchedulingNamespace } from '@cbo/shared-library/types';
import { AddEditShiftType, ScheduleChangeResponse } from '../Scheduling/types';
import {
  EmployeeConfigurationsResponse,
  JobCodeDefinition,
  JobStatus,
  LaborWarning,
} from '../services/employeeService';
import LaborUtilities from './laborUtilities';
import DateUtilities from './dateUtilities';
import { formatCurrency, formatTime } from '../../utils';

dayjs.extend(isBetweenPlugin);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);

export const daysData = [
  { key: 0, label: 'Su', day: 'SUNDAY' },
  { key: 1, label: 'M', day: 'MONDAY' },
  { key: 2, label: 'Tu', day: 'TUESDAY' },
  { key: 3, label: 'W', day: 'WEDNESDAY' },
  { key: 4, label: 'Th', day: 'THURSDAY' },
  { key: 5, label: 'F', day: 'FRIDAY' },
  { key: 6, label: 'Sa', day: 'SATURDAY' },
];

function isValidISOString(date: Date) {
  try {
    return !!date.toISOString();
  } catch (error) {
    return false;
  }
}

const formatHours = (hours: number | undefined): string => {
  const floatNumber = hours?.toFixed(2);
  return `${floatNumber} hr`;
};

const calculateDateRangeValues = (currentDate: Dayjs, startDay: Dayjs, endDay: Dayjs) => {
  const isDayWithinWeek = currentDate.isBetween(startDay, endDay, null, '[]');
  const isFirstDay = currentDate.isSame(startDay, 'day');
  const isLastDay = currentDate.isSame(endDay, 'day');

  return { isDayWithinWeek, isFirstDay, isLastDay };
};

const calculateBreakEndTime = (breakStart: string | null, breakPeriod: string | null): string => {
  if (!breakStart || !breakPeriod) return '';

  const breakStartDayJS = dayjs(breakStart, 'HH:mm');
  const breakEndDayJS = breakStartDayJS.add(Number(breakPeriod), 'minutes');

  return breakEndDayJS.format('HH:mm');
};

const startTimeOccursBeforeEndTime = (startTime: string | undefined, endTime: string | undefined): boolean => {
  const start = dayjs(startTime, 'HH:mm');
  let end;
  const endDateTime = dayjs(endTime, 'HH:mm');
  if (endDateTime.isBefore(start)) {
    // If endTime is before startTime, add one day to endTime
    end = endDateTime.add(1, 'day');
  } else end = endDateTime;
  return end.isAfter(start);
};

const isTimeDuringShift = (
  time: string | undefined,
  shiftStart: string | undefined,
  shiftEnd: string | undefined,
  considerBoundaries = false
): boolean => {
  // return true, so error isn't thrown in form
  if (!time || !shiftStart || !shiftEnd) return true;

  const timeDayJS = dayjs(time, 'HH:mm');
  const shiftStartDayJS = dayjs(shiftStart, 'HH:mm');
  const shiftEndDayJS = dayjs(shiftEnd, 'HH:mm');

  let result = timeDayJS.isBetween(shiftStartDayJS, shiftEndDayJS);
  if (considerBoundaries) {
    result = result || timeDayJS.isSame(shiftStartDayJS) || timeDayJS.isSame(shiftEndDayJS);
  }

  return result;
};

const isTimeOutsideShiftRanges = (shiftArray: AddEditShiftType[], time: string | undefined) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const shift of shiftArray) {
    const isAllDataAvailable = Boolean(shift.startTime && shift.endTime && time);
    const timesOverlapWithAnotherShift = isTimeDuringShift(time, shift.startTime, shift.endTime);

    if (isAllDataAvailable && timesOverlapWithAnotherShift) {
      // shift overlaps with another shift
      return false;
    }
  }
  return true;
};

const is15MinuteInterval = (time: string | undefined) => {
  const timeDayJs = dayjs(time, 'HH:mm');
  const minutes = timeDayJs.minute();

  // if divisible by 15 return true, else return false
  return minutes % 15 === 0;
};

const convertTime12to24 = (time12h: string) => dayjs(time12h, 'hh:mm A').format('HH:mm');

const formatYearOfService = (startDate: Date | null) => {
  if (startDate) {
    const serviceInYear = dayjs().diff(startDate, 'year');
    const serviceInMonth = dayjs().diff(startDate, 'month') % 12;

    let yearString = '';
    switch (serviceInYear) {
      case 0:
        yearString = '';
        break;
      case 1:
        yearString = '1 year';
        break;
      default:
        yearString = `${serviceInYear} years`;
        break;
    }

    let monthString = '';
    switch (serviceInMonth) {
      case 0:
        if (serviceInYear === 0) {
          monthString = '0 months';
        }
        break;
      case 1:
        monthString = '1 month';
        break;
      default:
        monthString = `${serviceInMonth} months`;
        break;
    }
    return dayjs(startDate).isAfter(dayjs()) ? '0 years' : `${yearString} ${monthString}`.trim();
  }
  return '-';
};

const filterActiveJobCodes = (employee: EmployeeConfigurationsResponse | undefined | null) =>
  employee?.jobCodes.filter((jobcode: JobCodeDefinition) => jobcode.activationStatus === ActivationTypes.ACTIVE);

const jobCodeFormatter = (jobcode: JobCodeDefinition[] | undefined) =>
  jobcode
    ?.map((code: JobCodeDefinition) => code.jobCodeName as string)
    .sort((a, b) => a.localeCompare(b))
    .map((jobCodeName: string | undefined, index: number) => {
      if (jobcode.length - 1 === index) return jobCodeName;
      return `${jobCodeName}, `;
    });

const payrateFormatter = (jobcodes: JobCodeDefinition[] | undefined) =>
  jobcodes
    ?.map((jobCode: JobCodeDefinition) => ({
      code: jobCode.jobCodeName as string,
      payrate: jobCode.payRate as string | number,
    }))
    .sort((a, b) => a.code.localeCompare(b.code))
    .map((jobCodePayrate: { code: string; payrate: string | number | undefined }, index: number) => {
      if (jobCodePayrate.payrate !== undefined) {
        if (jobcodes.length - 1 === index) {
          if (jobCodePayrate.payrate !== 'Inactive') {
            return `${formatCurrency(+jobCodePayrate.payrate)}`;
          }
        } else {
          if (jobCodePayrate.payrate !== 'Inactive') {
            return `${formatCurrency(+jobCodePayrate.payrate)}, `;
          }
          return `${jobCodePayrate.payrate}, `;
        }
        return jobCodePayrate.payrate;
      }
      return '-';
    });

const insertHoverElementIntoCalendarCell = (el: HTMLElement, text: string) => {
  // This is a temporary hack to insert divs into FullCalendar.
  // Follow https://github.com/fullcalendar/fullcalendar/issues/4816 and update when fix is implemented
  const cellHoverElementsContainer = document.createElement('div');
  cellHoverElementsContainer.className = 'cell-hover-elements-container';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 7; i++) {
    const addShiftIconDiv = document.createElement('div');
    addShiftIconDiv.innerHTML = text;
    cellHoverElementsContainer.appendChild(addShiftIconDiv);
  }

  el.querySelector('.fc-timeline-lane-frame')?.appendChild(cellHoverElementsContainer);
};

const dateOccursInFuture = (date: Date | undefined) => {
  const today = dayjs();
  const dayIsInFuture = today.isBefore(date, 'day');
  const dayIsToday = today.isSame(date, 'day');

  return dayIsInFuture || dayIsToday;
};

const calculateLeftForTimelineBgHarnessElement = (selectedDate: Date | undefined, weekStartDayOffset: number) => {
  const diff = dayjs(selectedDate).day() - weekStartDayOffset;
  const isDiffNegative = Math.sign(diff) === -1;
  const position = isDiffNegative ? 7 + diff : diff;
  return `calc(100% / 7 * ${position})`;
};

const insertTimelineBgHarnessElement = (selectedDate: Date | undefined, weekStartDayOffset: number) => {
  const timelineBgs = document.querySelectorAll('.fc-timeline-bg');
  const leftValue = calculateLeftForTimelineBgHarnessElement(selectedDate, weekStartDayOffset);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < timelineBgs.length; i++) {
    const timelineBg = timelineBgs[i];
    const timelineBgHarness = timelineBg.querySelector('.fc-timeline-bg-harness');
    // Fullcalendar creates timelineBgHarness when an event is selected
    if (timelineBgHarness) {
      const harnessEl = document.createElement('div');
      harnessEl.className = 'fc-timeline-bg-harness';
      harnessEl.style.cssText = `left:${leftValue};width:calc(100% / 7)`;

      const highlightEl = document.createElement('div');
      highlightEl.className = 'fc-highlight';
      highlightEl.style.cssText = `background:${blue[700]}14;`;

      harnessEl.appendChild(highlightEl);
      timelineBg?.appendChild(harnessEl);
    }
  }
};

const removeTimelineBgHarnessElement = () => {
  const timelineBgHarness = document.querySelector('.fc-timeline-bg-harness');
  timelineBgHarness?.remove();
};

const configureApplyToStartDay = (startDay: number) => {
  const sortedDaysData = [...daysData.slice(startDay), ...daysData.slice(0, startDay)];
  return sortedDaysData;
};

const filterEmployeeBySearch = (employeeArray: EmployeeConfigurationsResponse[], search: string) =>
  employeeArray.filter((employee) =>
    LaborUtilities.constructFullName(
      employee?.employeeInformation?.contactInformation.firstName,
      employee?.employeeInformation?.contactInformation.lastName
    )
      .toLocaleLowerCase()
      .replace(/\s+/g, '')
      .match(search)
  );

const escapeSpecialChar = (str: string) => str.replace(/[*()+\\[\]|]/g, '\\$&');

const filterEmployeesByJobCodesAndSearch = (
  employeeArray: EmployeeConfigurationsResponse[],
  jobCodeFilterArray: string[],
  searchString: string
) => {
  const search = escapeSpecialChar(searchString.toLowerCase().replace(/\s+/g, ''));
  const filteredEmployeeArrayByJobCodes = employeeArray.filter((employee: EmployeeConfigurationsResponse) =>
    // Determine if employee job codes match jobCodes in jobCodeFilterArray
    employee.jobCodes?.some(({ jobCodeName: employeeJobCode }) => {
      if (!employeeJobCode) return false;
      if (jobCodeFilterArray.length === 0) return employeeArray;
      return jobCodeFilterArray.includes(employeeJobCode);
    })
  );
  return filterEmployeeBySearch(filteredEmployeeArrayByJobCodes, search);
};

const filterActiveEmployeesFromJobCode = (
  jobCodeData: JobCodeDefinition[] | undefined,
  jobCode: string | undefined
) => {
  const employeesWithSelectedJobCode: (EmployeeConfigurationsResponse | undefined)[] | undefined = jobCodeData?.flatMap(
    (code: JobCodeDefinition) => {
      if (code.id === jobCode) return code.employees;
      return [];
    }
  );
  return employeesWithSelectedJobCode?.filter((employee) => employee?.activationStatus === 'ACTIVE');
};

const isShiftInChangelog = (shiftId: string, changelog: ScheduleChangeResponse[] | undefined): boolean =>
  changelog?.some((changedShift) => changedShift.shiftId === shiftId) ?? false;

const calculateLaborWarning = (jobStatus: JobStatus | null | undefined, totalHours: number): LaborWarning | null => {
  // TODO: removed/update once we have access to LRE
  switch (jobStatus) {
    case JobStatus.FULL_TIME:
      if (totalHours >= 30 && totalHours < 40) return LaborWarning.OVERTIME_WARNING;
      if (totalHours >= 40) return LaborWarning.OVERTIME_CRITICAL;
      break;
    case JobStatus.PART_TIME:
      if (totalHours >= 30) return LaborWarning.PART_TIME_CRITICAL;
      break;
    default:
      return null;
  }
  return null;
};

const formatJobType = (jobStatus: JobStatus | null, t: TFunction) => {
  switch (jobStatus) {
    case JobStatus.FULL_TIME:
      return t('labor.fullTime');
    case JobStatus.PART_TIME:
      return t('labor.partTime');
    case JobStatus.HOURLY:
      return t('labor.hourly');
    case JobStatus.SALARY_EXEMPT:
      return t('labor.salaryExempt');
    case JobStatus.SALARY_NON_EXEMPT:
      return t('labor.salaryNonExempt');
    default:
      return '-';
  }
};

const isTimeRangeOverlapping = (start1: Dayjs, end1: Dayjs, start2: Dayjs, end2: Dayjs) => {
  const startIsBeforeExistingEnd = start1.isBefore(end2);
  const endIsAfterExistingStart = start2.isBefore(end1);
  return startIsBeforeExistingEnd && endIsAfterExistingStart;
};

const findConflictingShifts = (
  shiftArray: PlannedShiftResponseDto[],
  start: string | undefined,
  end: string | undefined,
  businessDay: string,
  shiftIdToSkip?: string
) =>
  shiftArray.filter((existingShift) => {
    if (shiftIdToSkip && shiftIdToSkip === existingShift.id) return false;
    const isShiftOnAnotherDay = existingShift.businessDay !== dayjs(businessDay).format('YYYY-MM-DD');
    if (isShiftOnAnotherDay || !start || !end) return false;
    const startTime = dayjs(start, 'HH:mm');
    const endTime = dayjs(end, 'HH:mm');
    const existingStartTime = dayjs(
      convertTime12to24(formatTime(DateUtilities.removeTimezone(existingShift.punchInTime))),
      'HH:mm'
    );
    const existingEndTime = dayjs(
      convertTime12to24(formatTime(DateUtilities.removeTimezone(existingShift.punchOutTime))),
      'HH:mm'
    );

    return isTimeRangeOverlapping(startTime, endTime, existingStartTime, existingEndTime);
  });

const findConflictingShiftsWithMultipleShifts = (
  existingShifts: PlannedShiftResponseDto[],
  shiftsInForm: AddEditShiftType[],
  businessDay: string
) => {
  const result: PlannedShiftResponseDto[] = [];
  shiftsInForm.forEach((shift) => {
    const conflictingShifts = findConflictingShifts(existingShifts, shift.startTime, shift.endTime, businessDay);
    result.push(...conflictingShifts);
  });
  return uniqueBy(result, 'id');
};

const formatPunchEdit = (punchEditReason: SchedulingNamespace.PunchEditReasons, t: TFunction) => {
  switch (punchEditReason) {
    case SchedulingNamespace.PunchEditReasons.INCORRECT_BREAK_IN_OUT:
      return t('labor.incorrectBreakOutIn');
    case SchedulingNamespace.PunchEditReasons.FORGOT_TO_CLOCK_IN:
      return t('labor.forgotToClockInOut');
    case SchedulingNamespace.PunchEditReasons.INCORRECT_CLOCK_IN_OUT:
      return t('labor.incorrectClockInOut');
    case SchedulingNamespace.PunchEditReasons.INCORRECT_JOBCODE:
      return t('labor.incorrectJobCode');
    case SchedulingNamespace.PunchEditReasons.OTHER:
      return t('labor.other');
    case null:
    default:
      return '';
  }
};

const formatJobCode = (jobCodes: JobCodeDefinition[] | undefined, jobCodeId: string | undefined) => {
  const jobCode = jobCodes?.find((currentJobCode) => currentJobCode.id === jobCodeId);
  return jobCode?.jobCodeName;
};

const calculateBreaksTotalHours = (breaks: ActualBreakResponseDto[]): string => {
  const breakTotalMilliseconds = breaks
    .map((actualBreak): number =>
      dayjs(actualBreak.breakInformation?.breakInTime).diff(dayjs(actualBreak.breakInformation?.breakOutTime))
    )
    .reduce((acc, breakAmount) => acc + breakAmount, 0);
  return dayjs.duration({ milliseconds: breakTotalMilliseconds }).asHours().toFixed(2);
};

const formatEmployeeFromId = (
  employees: EmployeeConfigurationsResponse[] | undefined,
  employeeConfigId: string | undefined
) => {
  const employee = employees?.find((currentEmployee) => {
    if (!employeeConfigId) return false;
    return currentEmployee.id === employeeConfigId;
  });
  return employee?.employeeInformation?.contactInformation
    ? LaborUtilities.constructFullName(
        employee.employeeInformation.contactInformation.firstName,
        employee.employeeInformation.contactInformation.lastName
      )
    : '-';
};

const formatCompensationType = (compensationType: SchedulingNamespace.CompensationType | undefined, t: TFunction) => {
  switch (compensationType) {
    case SchedulingNamespace.CompensationType.PAID:
      return t('labor.paid');
    case SchedulingNamespace.CompensationType.UNPAID:
      return t('labor.unpaid');
    default:
      return undefined;
  }
};

const calculateRegularHours = (actualShift: ActualShiftResponseDto): string => {
  let regularSecondsWorked = 0;
  if (actualShift.actualShiftWageBreakdowns && actualShift.actualShiftWageBreakdowns.length > 0) {
    const paidSegments = actualShift.actualShiftWageBreakdowns[0].wageBreakdowns?.filter(
      (wageBreakdown) => wageBreakdown.rateAdjustment === 1
    );
    if (paidSegments) {
      paidSegments.forEach((segment) => {
        regularSecondsWorked += segment?.duration ?? 0;
      });
    }
  }

  return dayjs.duration({ seconds: regularSecondsWorked }).asHours().toFixed(2);
};

const calculateOvertimeHours = (actualShift: ActualShiftResponseDto): string => {
  const overtimeSecondsWorked =
    actualShift.actualShiftWageBreakdowns &&
    actualShift.actualShiftWageBreakdowns.length > 0 &&
    actualShift.actualShiftWageBreakdowns[0].wageBreakdowns &&
    actualShift.actualShiftWageBreakdowns[0].wageBreakdowns.length > 0
      ? actualShift.actualShiftWageBreakdowns[0].wageBreakdowns
          .map((wageBreakdown) =>
            // rateAdjustment > 1 is not a regular pay rate, so we need to add these up
            wageBreakdown.rateAdjustment > 1 ? wageBreakdown.duration : 0
          )
          .reduce((acc, overtimeDuration) => acc + overtimeDuration, 0)
      : 0;

  return dayjs.duration({ seconds: overtimeSecondsWorked }).asHours().toFixed(2);
};

const SchedulingUtilities = {
  formatHours,
  calculateDateRangeValues,
  calculateBreakEndTime,
  convertTime12to24,
  startTimeOccursBeforeEndTime,
  isTimeDuringShift,
  isTimeOutsideShiftRanges,
  findConflictingShifts,
  findConflictingShiftWithMultipleShifts: findConflictingShiftsWithMultipleShifts,
  is15MinuteInterval,
  formatYearOfService,
  jobCodeFormatter,
  payrateFormatter,
  insertHoverElementIntoCalendarCell,
  filterActiveJobCodes,
  dateOccursInFuture,
  calculateLeftForTimelineBgHarnessElement,
  insertTimelineBgHarnessElement,
  removeTimelineBgHarnessElement,
  configureApplyToStartDay,
  filterEmployeesByJobCodesAndSearch,
  filterActiveEmployeesFromJobCode,
  isShiftInChangelog,
  calculateLaborWarning,
  formatJobType,
  formatPunchEdit,
  formatJobCode,
  calculateBreaksTotalHours,
  formatEmployeeFromId,
  formatCompensationType,
  calculateRegularHours,
  calculateOvertimeHours,
  isValidISOString,
};

export default SchedulingUtilities;
