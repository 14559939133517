import { CalendarType } from '@cbo/shared-library';

export interface CalendarTypesConfig {
  [calendarType: string]: {
    id: string;
    label: string;
  };
}

export const calendarTypesConfig: CalendarTypesConfig = {
  '13-4': {
    id: CalendarType['13-4'],
    label: '13-4',
  },
  '12 Months': {
    id: CalendarType['12 months'],
    label: '12 Months',
  },
};
