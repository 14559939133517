import { Req, Res, SiteInfo } from '@cbo/shared-library';
import { UserPreferences } from '@cbo/shared-library/response/admin.response';
import { EnterpriseUnitData } from '@cbo/shared-library/response/site-management.response';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColumns,
  GridComparatorFn,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid-premium';
import { Dictionary } from 'lodash';
import { TFunction } from 'react-i18next';
import { formatDate, formatTime } from '../../utils';
import { OrgGroupDataRow, SiteTagConfig } from '../models/SiteGroups';

export interface RenderCellOptions {
  renderTagsColumn: (params: GridRenderCellParams<SiteTagConfig[]>) => '-' | JSX.Element;
}

export const formatAddress = (
  address: Req.SiteManagement.SiteAddressData | undefined,
  t: TFunction<'translation', undefined>
) => {
  if (!address) {
    return '-';
  }

  const { street, city, state, postalCode, country } = address;
  if (address.country === 'USA') {
    return t('admin.allSitesDatagrid.addressFormat', {
      street,
      city,
      state,
      postalCode,
    });
  }

  const addressParts = [street, city, state, postalCode, country].filter((value) => !!value);
  return addressParts.join(', ');
};

export const formatTimeStamp = (date: Date | string, preferences: Res.Admin.UserPreferences | null) => {
  if (!date) {
    return '-';
  }

  const formattedDate = formatDate(date, preferences);
  const formattedTime = formatTime(date, preferences);

  return `${formattedDate} ${formattedTime}`;
};

export const tagsComparator: GridComparatorFn<SiteTagConfig[]> = (
  v1: SiteTagConfig[],
  v2: SiteTagConfig[],
  param1,
  param2
) => {
  let v1String;
  let v2String;

  if (Array.isArray(v1)) {
    const v1LabelArr = v1.map((siteTagConfig) => siteTagConfig.label);
    v1String = v1LabelArr.join(', ');
  } else {
    v1String = v1;
  }

  if (Array.isArray(v2)) {
    const v2LabelArr = v2.map((siteTagConfig) => siteTagConfig.label);
    v2String = v2LabelArr.join(', ');
  } else {
    v2String = v2;
  }

  return gridStringOrNumberComparator(v1String, v2String, param1, param2);
};

export const getSiteColumnsDefinition = (
  preferences: UserPreferences | null,
  renderCellOptions: RenderCellOptions,
  t: TFunction<'translation', undefined>
): GridColumns => {
  const { renderTagsColumn } = renderCellOptions;

  const sitesColumns: GridColumns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      type: 'string',
      hideable: false,
    },
    {
      field: 'siteReferenceId',
      headerName: t('admin.allSitesDatagrid.siteReferenceIdColumnHeader'),
      minWidth: 150,
      flex: 0.1,
      hideable: false,
    },
    {
      field: 'siteName',
      headerName: t('admin.allSitesDatagrid.siteNameColumnHeader'),
      minWidth: 150,
      flex: 0.25,
      hideable: false,
    },
    {
      field: 'organizationName',
      headerName: t('admin.allSitesDatagrid.organizationNameColumnHeader'),
      minWidth: 150,
      flex: 0.25,
    },
    {
      field: 'streetAddress',
      headerName: t('admin.allSitesDatagrid.streetAddressColumnHeader'),
      minWidth: 150,
      flex: 0.25,
      valueGetter: (params) => formatAddress(params.value, t),
    },
    {
      field: 'city',
      headerName: t('admin.allSitesDatagrid.cityColumnHeader'),
      minWidth: 150,
      flex: 0.25,
    },
    {
      field: 'state',
      headerName: t('admin.allSitesDatagrid.stateColumnHeader'),
      minWidth: 150,
      flex: 0.25,
    },
    {
      field: 'postalCode',
      headerName: t('admin.allSitesDatagrid.postalCodeColumnHeader'),
      minWidth: 150,
      flex: 0.25,
    },
    {
      field: 'country',
      headerName: t('admin.allSitesDatagrid.countryColumnHeader'),
      minWidth: 150,
      flex: 0.25,
    },
    {
      field: 'timeZone',
      headerName: t('admin.allSitesDatagrid.timeZoneColumnHeader'),
      minWidth: 150,
      flex: 0.25,
    },
    {
      field: 'currency',
      headerName: t('admin.allSitesDatagrid.currencyColumnHeader'),
      minWidth: 150,
      flex: 0.25,
    },
    {
      field: 'description',
      headerName: t('admin.allSitesDatagrid.descriptionColumnHeader'),
      minWidth: 150,
      flex: 0.25,
    },
    {
      field: 'contactPerson',
      headerName: t('admin.allSitesDatagrid.contactPersonColumnHeader'),
      minWidth: 150,
      flex: 0.25,
    },
    {
      field: 'createdOn',
      headerName: t('admin.allSitesDatagrid.createdOnColumnHeader'),
      minWidth: 150,
      flex: 0.25,
      valueGetter: (params) => formatTimeStamp(params.value, preferences),
    },
    {
      field: 'lastModifiedOn',
      headerName: t('admin.allSitesDatagrid.lastModifiedOnColumnHeader'),
      minWidth: 150,
      flex: 0.25,
      valueGetter: (params) => formatTimeStamp(params.value, preferences),
    },
    {
      field: 'deactivatedOn',
      headerName: t('admin.allSitesDatagrid.deactivatedOnColumnHeader'),
      minWidth: 150,
      flex: 0.25,
    },
    {
      field: 'enterpriseUnitName',
      headerName: t('admin.allSitesDatagrid.enterpriseUnitNameColumnHeader'),
      minWidth: 150,
      flex: 0.25,
    },
    {
      field: 'id',
      headerName: t('admin.allSitesDatagrid.idColumnHeader'),
      minWidth: 150,
      flex: 0.25,
    },
    {
      field: 'parentEnterpriseUnitId',
      headerName: t('admin.allSitesDatagrid.parentEnterpriseUnitIdColumnHeader'),
      minWidth: 150,
      flex: 0.25,
    },
    {
      field: 'tags',
      headerName: t('admin.allSitesDatagrid.tagsColumnHeader'),
      minWidth: 500,
      flex: 0.6,
      renderCell: renderTagsColumn,
      sortComparator: tagsComparator,
    },
  ];

  return sitesColumns;
};

export const sitesDatagridInitialState = {
  columns: {
    columnVisibilityModel: {
      organizationName: false,
      streetAddress: false,
      city: false,
      state: false,
      postalCode: false,
      country: false,
      timeZone: false,
      currency: false,
      description: false,
      contactPerson: false,
      createdOn: false,
      lastModifiedOn: false,
      status: false,
      deactivatedOn: false,
      enterpriseUnitName: false,
      id: false,
      parentEnterpriseUnitId: false,
    },
  },
};

export const getChildren = (parentPath: string[], allGroupDataRow: OrgGroupDataRow[]) => {
  const parentPathStr = parentPath.join('-');
  return allGroupDataRow.filter((row) => row.hierarchy.slice(0, -1).join('-') === parentPathStr);
};

export const generateEUTreeDataPath = (
  euDictionary: Dictionary<EnterpriseUnitData>,
  row: EnterpriseUnitData
): string[] => {
  if (!row.parentId) return [row.enterpriseUnitId];

  const { parentId } = row;
  const parent = euDictionary[parentId];
  return [...generateEUTreeDataPath(euDictionary, parent), row.enterpriseUnitId];
};

export const mapEUDataToGridRows = (
  euData: EnterpriseUnitData[] | undefined,
  euDictionary: Dictionary<EnterpriseUnitData>
): OrgGroupDataRow[] =>
  euData?.map((eu: EnterpriseUnitData): OrgGroupDataRow => {
    const treePathHierachy: string[] = generateEUTreeDataPath(euDictionary, eu);
    const parentGroup = eu.parentId ? euDictionary[eu.parentId].name : undefined;

    return {
      hierarchy: treePathHierachy,
      name: eu.name,
      referenceId: eu.referenceId,
      parentGroup,
      id: eu.enterpriseUnitId,
      type: eu.enterpriseTypeName,
      description: eu.description,
      active: eu.active,
      childrenCount: 0,
    };
  }) ?? [];

export const addChildrenCountToOrgGroupRow = (orgGroupRows: OrgGroupDataRow[]): OrgGroupDataRow[] =>
  orgGroupRows.map((row) => ({
    ...row,
    childrenCount: getChildren(row.hierarchy, orgGroupRows).length,
  }));

export const getSiteButtonText = (
  selectedSiteName: string,
  multiSelectedSites: SiteInfo[],
  allSitesLength: number,
  isSiteFiltering: boolean,
  isHeader: boolean,
  sitesText: string,
  allSitesText: string
) => {
  let buttonText = '';
  if (isHeader) {
    buttonText = selectedSiteName;
    if (isSiteFiltering && multiSelectedSites.length > 0)
      buttonText = `${multiSelectedSites.length} ${sitesText.toLowerCase()}`;
    if (isSiteFiltering && multiSelectedSites.length === 1 && multiSelectedSites[0].name)
      buttonText = multiSelectedSites[0].name;
    else if (isSiteFiltering && multiSelectedSites.length === allSitesLength) buttonText = allSitesText;
  } else {
    buttonText = `${multiSelectedSites.length} ${sitesText.toLowerCase()}`;
    if (multiSelectedSites.length === 1 && multiSelectedSites[0].name) buttonText = multiSelectedSites[0].name;
    if (multiSelectedSites.length === 0) buttonText = selectedSiteName;
    else if (multiSelectedSites.length === allSitesLength) buttonText = allSitesText;
  }
  return buttonText;
};

export const compareSiteIds = (previousSiteIds?: string[], selectedSiteIds?: string[]) =>
  previousSiteIds?.sort((a, b) => a.localeCompare(b)).toString() ===
  selectedSiteIds?.sort((a, b) => a.localeCompare(b)).toString();
