/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro';
import { Control, Controller, ControllerProps, FieldError } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material';
import { useAccountPreferences } from '../../contexts/accountPreferencesContext';
import { formatDayJsDateInput } from '../../utils';

export declare type ParseableDate<TDate> = string | number | Date | null | undefined | TDate;

export type DatePickerElementProps<TInputDate, TDate = TInputDate> = Omit<
  DatePickerProps<TInputDate, TDate>,
  'value' | 'onChange' | 'renderInput' | 'onBlur'
> & {
  name: string;
  required?: boolean;
  isDate?: boolean;
  parseError?: (error: FieldError) => string;
  onChange?: (value: TDate, keyboardInputValue?: string) => void;
  validation?: ControllerProps['rules'];
  parseDate?: (value: TDate, keyboardInputValue?: string) => TDate;
  control?: Control<any>;
  inputProps?: TextFieldProps;
  helperText?: TextFieldProps['helperText'];
  inputFormat?: string;
  disablePlaceholder?: boolean;
  disableMaskedInput?: boolean;
  disableOpenPicker?: boolean;
  handleBlur?: any;
  disabled?: boolean;
  defaultValue?: Date;
};

export default function DatePickerElement({
  isDate,
  parseError,
  name,
  required,
  parseDate,
  validation = {},
  inputProps,
  control,
  inputFormat,
  disablePlaceholder,
  disableMaskedInput,
  disableOpenPicker,
  handleBlur,
  disabled,
  defaultValue,
  ...rest
}: DatePickerElementProps<any, any>): JSX.Element {
  const { preferences } = useAccountPreferences();
  const userPreferenceFormat = formatDayJsDateInput(preferences);

  const dateFormat = inputFormat ?? userPreferenceFormat;
  const upperCaseInputFormat = dateFormat ? dateFormat.toString().toUpperCase() : '';

  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      render={({ field: { onChange, value, onBlur }, fieldState: { error, invalid } }) => (
        <DatePicker
          disableOpenPicker={disableOpenPicker}
          {...rest}
          value={defaultValue || value || null}
          onChange={(inputValue, keyboardInputValue) => {
            if (handleBlur) {
              handleBlur();
            }

            onChange(inputValue, keyboardInputValue);
          }}
          inputFormat={dateFormat}
          disabled={disabled}
          disableMaskedInput={disableMaskedInput}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params?.inputProps,
                placeholder: disablePlaceholder ? '' : upperCaseInputFormat,
                'data-testid': `${name}-datepicker-text-field`,
              }}
              {...inputProps}
              required={!!required}
              error={invalid}
              onBlur={handleBlur || onBlur}
              helperText={
                // eslint-disable-next-line no-nested-ternary
                error
                  ? typeof parseError === 'function'
                    ? parseError(error)
                    : error.message
                  : inputProps?.helperText || rest.helperText
              }
            />
          )}
        />
      )}
    />
  );
}
