import { useFlags } from 'launchdarkly-react-client-sdk';

export enum FeatureFlag {
  invoiceOcr = 'cboInvoiceOcr',
  darkMode = 'cboDarkMode',
  vendorUpload = 'cboInvoiceVendorUpload',
  dashboardReports = 'cboDashboardReports',
  labor = 'cboLabor',
  inventory = 'cboInventory',
  laborRules = 'cboLaborRules',
  generalLedgerAccounts = 'cboGeneralLedgerAccounts',
  schedule = 'cboSchedule',
  schedulingManagerForecastDashboard = 'cboSchedulingManagerForecastDashboard',
  siteTags = 'cboSiteTags',
  asmCore = 'cboAsmCore',
  timezoneReports = 'cboTimezonedReports',
  houseAccounts = 'cboHouseAccounts',
  laborPayroll = 'cboLaborPayroll',
  siteGroups = 'cboSiteGroups',
  laborReports = 'cboLaborReports',
  scheduleButtons = 'scheduleButtons',
  customMainDashboard = 'cboCustomMainDashboard',
  configurableNetGrossSales = 'cboConfigurableNetAndGrossSales',
  invoiceHistoryReport = 'cboInvoiceHistoryReport',
}

export type FeatureFlagKey = (typeof FeatureFlag)[keyof typeof FeatureFlag];

export function useFeatureFlag(key: FeatureFlagKey): boolean {
  const flags = useFlags();
  return flags?.[key] ?? false;
}

export function useFeatureFlags(...keys: FeatureFlagKey[]) {
  const flags = useFlags() ?? {};
  return keys.map((key) => flags?.[key] ?? false);
}
