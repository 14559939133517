import { Res } from '@cbo/shared-library';
import { TRegion, Regions } from '../../config';
import { DateFormat, NumberFormat } from '../../models/config';

/**
 * Returns the number format, combining regional preferences with any user's saved preferences
 * @param preferences
 * @returns Returns an instance of NumberFormat that includes locale and format options
 */
export const getNumberFormat = (preferences?: Res.Admin.UserPreferences): NumberFormat => {
  const region: TRegion = (preferences?.region as TRegion) || 'en-US';
  const locale: string = Regions[region].locale || region;
  return { ...Regions[region].formatting.number, locale };
};

/**
 * Formats numbers based on regional preferences and the user's saved preferences
 * @param value
 * @param preferences
 * @param format
 * @returns Returns the specified number (value) as formatted string
 */
export const formatNumber = (
  value: number,
  preferences?: Res.Admin.UserPreferences,
  format?: DateFormat,
  toFixed?: number
): string => {
  const { options, locale } = format || getNumberFormat(preferences);

  const opts: Intl.NumberFormatOptions = options as Intl.NumberFormatOptions;
  return Intl.NumberFormat(locale, {
    ...opts,
    maximumFractionDigits: toFixed ?? opts.maximumFractionDigits,
    minimumFractionDigits: toFixed ?? opts.minimumFractionDigits,
  }).format(value);
};
