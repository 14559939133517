import DomainIcon from '@mui/icons-material/Domain';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { Organization } from '@ncr-voyix-commerce/react-common-components';
import { useOrgContext } from '../CommonComponentWrapper';
import DialogListItemActionButton from './DialogListItemActionButton';
import { useOrganizationDialogContext } from './OrganizationDialogProvider';

function OrganizationHierarchyCollapse({ organization }: { organization: Organization }) {
  const { organization: activeOrganization } = useOrgContext();

  const { handleOrganizationClick, searchValue, expandedOrg } = useOrganizationDialogContext();

  return (
    <ListItem disablePadding>
      <Collapse sx={{ width: '100%' }} in={expandedOrg.has(organization.id)}>
        <List sx={{ paddingBottom: '0px !important' }}>
          {organization.children
            .filter((childOrg) => childOrg.includes(searchValue))
            .map((childOrg) => (
              <DialogListItemActionButton
                selected={childOrg === activeOrganization?.organizationName}
                key={`${childOrg}`}
                sx={{
                  paddingLeft: 3,
                }}
                onListItemButtonClick={() => handleOrganizationClick(childOrg)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '0',
                    marginRight: '16px',
                  }}
                >
                  <DomainIcon />
                </ListItemIcon>
                <Typography variant='body1'>{childOrg}</Typography>
              </DialogListItemActionButton>
            ))}
        </List>
      </Collapse>
    </ListItem>
  );
}

export default OrganizationHierarchyCollapse;
