import CloseIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { StackProps } from '@mui/material';
import { useSidebar } from './CustomSidebarContext';

function SidebarHeader(props: StackProps) {
  const { children, onClick, ...rest } = props;
  const { isCollapsable, setCollapsed } = useSidebar();

  return (
    <Stack
      direction='row'
      spacing={3}
      alignItems='center'
      justifyContent='space-between'
      p={3}
      pr={2}
      {...rest}
      onClick={onClick}
    >
      <Box
        sx={{
          ...(onClick ? { '&:hover': { opacity: 0.8, cursor: 'pointer' } } : {}),
        }}
      >
        {children}
      </Box>
      {isCollapsable && (
        <IconButton size='small' onClick={() => setCollapsed((x) => !x)}>
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
}

export default SidebarHeader;
