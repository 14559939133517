import { createContext, useContext, useMemo, useState } from 'react';
import ContextProviderProps from '../models/ContextProviderProps';

interface IErrorRetryContext {
  retryCount: number;
  setRetry: (retryCount: number) => void;
}

export const ErrorRetryContext = createContext<IErrorRetryContext>({
  retryCount: 0,
  setRetry: (_retryCount) => null,
});

export const useErrorRetry = () => useContext(ErrorRetryContext);

export function ErrorRetryContextProvider({ children }: ContextProviderProps) {
  const [retryCount, setRetry] = useState<number>(0);
  const errorRetryValue = useMemo(() => ({ retryCount, setRetry }), [retryCount]);
  return <ErrorRetryContext.Provider value={errorRetryValue}>{children}</ErrorRetryContext.Provider>;
}

export default ErrorRetryContextProvider;
