import { createContext, useState, useEffect, useContext } from 'react';
import { CboRole, Res } from '@cbo/shared-library';
import ContextProviderProps from '../models/ContextProviderProps';
import { useGetCalendarFilters } from '../sales/requests/queries';
import { useSites } from './siteContext';
import { usePayrollCalendarFiltersQuery } from '../admin/requests/queries';
import isMessageResponse from '../utils/objectUtils/messageResponse';
import { useUsers } from './userContext';
import isPermitted from '../lib/permissions';

interface FilterContextType {
  fiscalCalendarFilters: Res.Calendar.FiscalCalendarFilters | undefined;
  payrollCalendarFilters: Res.Calendar.PayrollCalendarFilters | undefined;
}

const initialFilterContext: FilterContextType = {
  fiscalCalendarFilters: undefined,
  payrollCalendarFilters: undefined,
};

export const FilterContext = createContext<FilterContextType>(initialFilterContext);

export const useFilters = () => useContext(FilterContext);

function FilterContextProvider({ children }: ContextProviderProps) {
  const [filters, setFilters] = useState<FilterContextType>(initialFilterContext);
  const { selectedSite } = useSites();
  const user = useUsers();

  const { fiscalCalendarFiltersResponse } = useGetCalendarFilters(
    user.fullyAuthenticated === 'authenticated' && isPermitted(user, [CboRole.FISCAL_CALENDAR_VIEW])
  );
  const { isSuccess, data } = usePayrollCalendarFiltersQuery(
    selectedSite.enterpriseUnitId,
    isPermitted(user, [CboRole.PAYROLL_CALENDAR_VIEW])
  );

  useEffect(() => {
    if (fiscalCalendarFiltersResponse.isSuccess && fiscalCalendarFiltersResponse.data) {
      setFilters((prev) => ({ ...prev, fiscalCalendarFilters: fiscalCalendarFiltersResponse.data }));
    }
  }, [fiscalCalendarFiltersResponse.data, fiscalCalendarFiltersResponse.isSuccess]);

  useEffect(() => {
    if (data && isSuccess && !isMessageResponse(data) && data.siteId) {
      setFilters((prev) => ({ ...prev, payrollCalendarFilters: data }));
    }
  }, [data, isSuccess]);

  return <FilterContext.Provider value={filters}>{children}</FilterContext.Provider>;
}

export default FilterContextProvider;
