import { ListItem, ListItemButton, useTheme } from '@mui/material';
import { SwitcherDrawer } from 'src/components/common/Switcher/SwitcherDrawer';
import { AppConstants } from 'src/constants/app';
import { ThemeValues } from 'src/constants/theme-values';
import { useOrganizationContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { Organization } from 'src/types';

export const OrganizationSwitcherDrawer = ({
    menuOpen,
    onMenuOpen,
    onMenuClose,
    onOrgClick,
}: {
    menuOpen: boolean;
    onMenuOpen: () => void;
    onMenuClose: () => void;
    onOrgClick: (orgName: string) => void;
}) => {
    const { t } = useTranslationNs();
    const { userOrganizations, setIsOrgDialogOpen, organization } = useOrganizationContext();

    const theme = useTheme();

    const handleViewAll = () => {
        onMenuClose();
        setIsOrgDialogOpen(true);
    };

    const effectiveOrgs = userOrganizations
        .slice(0, AppConstants.OrgMenuMax)
        .map((org: Organization) => (
            <ListItem key={org.id} disablePadding>
                <ListItemButton
                    role='button'
                    sx={{
                        ...theme.typography.subtitle1,
                        padding: ThemeValues.DrawerListItemPadding,
                    }}
                    selected={org.organizationName === organization?.organizationName}
                    onClick={() => onOrgClick(org.organizationName)}
                >
                    {org.displayName}
                </ListItemButton>
            </ListItem>
        ));

    return (
        <SwitcherDrawer
            menuOpen={menuOpen}
            menuTitle={t('ORG_CONTEXT.MENU.TITLE')}
            menuCloseTitle={t('ORG_CONTEXT.ACCESSIBILITY.CLOSE')}
            ctaTitle={t('ORG_CONTEXT.MENU.MANAGE_ALL_ORGS')}
            onMenuClose={onMenuClose}
            onMenuOpen={onMenuOpen}
            onCtaClick={handleViewAll}
        >
            {effectiveOrgs}
        </SwitcherDrawer>
    );
};
