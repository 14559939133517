import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { SyntheticEvent } from 'react';
import { SxProps } from '@mui/system';

interface DialogCloseButtonProps {
  onClose: () => void;
  sx?: SxProps<Theme>;
}

export function DialogCloseButton(props: DialogCloseButtonProps) {
  const { onClose, sx } = props;
  const { t } = useTranslation();
  return (
    <IconButton aria-label={t('buttonText.close')} onClick={onClose} sx={sx} data-testid='dialog--close-icon-btn'>
      <CloseIcon />
    </IconButton>
  );
}

DialogCloseButton.defaultProps = {
  sx: {
    bottom: '3px',
  },
};

export interface ActionsDialogProps {
  open: boolean;
  onClose: () => void;
  dataTestId: string;
  dialogTitle: string;
  additionalDialogTitleContent?: React.ReactNode;
  dialogContentText?: string;
  additionalDialogContent?: React.ReactNode;
  closeButtonText: string;
  onActionClick?: () => void;
  actionButtonText?: string;
  actionButtonColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
  closeButtonColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
  actionButtonVariant?: 'contained' | 'outlined' | 'text' | undefined;
  closeButtonVariant?: 'contained' | 'outlined' | 'text' | undefined;
  checkboxText?: string;
  onCheckboxChange?: ((event: SyntheticEvent<Element, Event>, checked: boolean) => void) | undefined;
}

function ActionsDialog(props: ActionsDialogProps) {
  // const theme = useTheme();
  const {
    open,
    onClose,
    dataTestId,
    dialogTitle,
    dialogContentText,
    additionalDialogTitleContent,
    additionalDialogContent,
    closeButtonText,
    closeButtonColor,
    closeButtonVariant,
    onActionClick,
    actionButtonText,
    actionButtonColor,
    actionButtonVariant,
    checkboxText,
    onCheckboxChange,
  } = props;

  return (
    <Dialog
      data-testid={dataTestId}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      sx={{
        '& .MuiDialogTitle-root': {
          padding: '24px 24px 8px 24px',
        },
        '& .MuiDialogContent-root': {
          paddingBottom: '32px',
        },
        '& .MuiDialogActions-root': {
          padding: '0px 24px 24px 24px',
        },
        '& .MuiDialogActions-root>:not(:first-of-type)': {
          marginLeft: 1.5,
        },
      }}
      disableEnforceFocus
    >
      <DialogTitle data-testid='dialog--title'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {dialogTitle}
          <DialogCloseButton onClose={onClose} />
        </Box>
        {additionalDialogTitleContent}
      </DialogTitle>
      <DialogContent data-testid='dialog--content'>
        {dialogContentText && (
          <DialogContentText
            variant='body2'
            sx={{
              color: 'text.primary',
            }}
          >
            {dialogContentText}
          </DialogContentText>
        )}
        {additionalDialogContent}
      </DialogContent>
      <DialogActions sx={{ justifyContent: checkboxText ? 'space-between' : 'flex-end' }}>
        {checkboxText && (
          <FormControlLabel
            control={<Checkbox data-testid='actions-checkbox' size='small' />}
            label={
              <Box data-testid='actions-checkbox-text' sx={{ typography: 'body2' }}>
                {checkboxText}
              </Box>
            }
            onChange={onCheckboxChange}
          />
        )}
        <Box>
          <Button
            variant={closeButtonVariant}
            data-testid='dialog--close-btn'
            onClick={onClose}
            color={closeButtonColor}
          >
            {closeButtonText}
          </Button>
          {/* If this dialog has actions, then show the actions button. If not it will just show the close button */}
          {!!actionButtonText && !!onActionClick && (
            <Button
              data-testid='dialog--action-btn'
              color={actionButtonColor}
              variant={actionButtonVariant}
              onClick={onActionClick}
              sx={{ ml: 1 }}
            >
              {actionButtonText}
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

ActionsDialog.defaultProps = {
  onActionClick: undefined,
  actionButtonText: '',
  actionButtonColor: 'primary',
  closeButtonColor: 'primary',
  actionButtonVariant: 'contained',
  closeButtonVariant: 'contained',
  checkboxText: '',
  onCheckboxChange: undefined,
  dialogContentText: '',
  additionalDialogContent: undefined,
  additionalDialogTitleContent: undefined,
};

export default ActionsDialog;
