import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  DateRange,
  DateRangePicker as MUIDateRangePicker,
  StaticDateRangePicker as MUIStaticDateRangePicker,
  PickersLocaleText,
} from '@mui/x-date-pickers-pro';
import {
  DateRangePickerSlotsComponent,
  DateRangePickerSlotsComponentsProps,
} from '@mui/x-date-pickers-pro/DateRangePicker/DateRangePicker';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateRangePickerTextField from './DateRangePickerTextField';

interface Props {
  value: DateRange<Date | Dayjs>;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  onChange: (date: DateRange<any>) => void;
  localeText: Partial<PickersLocaleText<unknown>>;
  variant: 'filled' | 'standard' | 'outlined' | undefined;
  helperText?: string;
  displaySeparator?: boolean;
  hideIcon?: boolean;
  inputFormat?: string;
  placeholder?: string;
  useDefaultAnchor?: boolean;
  components?: Partial<DateRangePickerSlotsComponent> | undefined;
  componentsProps?: Partial<DateRangePickerSlotsComponentsProps> | undefined;
  useStaticDateRange?: boolean;
  useDayjsAdapter?: boolean;
  maxDate?: Date;
}

function DateRangePicker({
  value,
  onChange,
  localeText,
  variant,
  helperText,
  displaySeparator,
  hideIcon,
  inputFormat,
  placeholder,
  useDefaultAnchor,
  components,
  componentsProps,
  useStaticDateRange,
  useDayjsAdapter,
  maxDate,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [dateRangePopoverAnchorEl, setDateRangePopoverAnchorEl] = useState<null | HTMLElement>(null);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <MUIDateRangePicker
        // https://mui.com/x/api/date-pickers/date-range-picker/#slots
        // This doesn't work even though it should according to the documentation.
        // Using workaround by setting InputProps on the individual TextField.
        // components={{
        //   OpenPickerIcon: Event,
        // }}
        PopperProps={
          useDefaultAnchor
            ? undefined
            : {
                anchorEl: dateRangePopoverAnchorEl,
                placement: 'bottom-start',
              }
        }
        maxDate={maxDate}
        inputFormat={inputFormat}
        value={value}
        onChange={onChange}
        renderInput={(startProps, endProps) => (
          <>
            <DateRangePickerTextField
              name='start-date'
              startProps={startProps}
              setDateRangePopoverAnchorEl={setDateRangePopoverAnchorEl}
              hideIcon={hideIcon}
              variant={variant}
              helperText={helperText}
              placeholder={placeholder}
              useStaticDateRange={useStaticDateRange}
            />
            {displaySeparator ? (
              <Box sx={{ mx: 2 }} data-testid='date-range-separator'>
                {t('dateRangeShortcut.to')}
              </Box>
            ) : (
              <Box sx={{ mx: 1 }} />
            )}
            <DateRangePickerTextField
              name='end-date'
              startProps={endProps}
              setDateRangePopoverAnchorEl={setDateRangePopoverAnchorEl}
              hideIcon={hideIcon}
              variant={variant}
              helperText={helperText}
              placeholder={placeholder}
              useStaticDateRange={useStaticDateRange}
            />
          </>
        )}
        components={components}
        componentsProps={componentsProps}
        PaperProps={{
          sx: {
            boxShadow: 3,
          },
        }}
        DialogProps={{
          sx: {
            '& .PrivatePickersSlideTransition-root': {
              minHeight: '254px',
            },
          },
        }}
        desktopModeMediaQuery={theme.breakpoints.up('sm')}
        data-testid='date-range-picker'
        disableOpenPicker={useStaticDateRange}
      />
      {useStaticDateRange ? (
        <Box data-testid='static-date-range-picker'>
          <MUIStaticDateRangePicker
            displayStaticWrapperAs={isMobile ? 'mobile' : 'desktop'}
            inputFormat={inputFormat}
            value={value}
            onChange={onChange}
            renderInput={() => <div />}
          />
        </Box>
      ) : null}
    </>
  );
}

DateRangePicker.defaultProps = {
  helperText: undefined,
  displaySeparator: true,
  hideIcon: false,
  inputFormat: 'MM/DD/YYYY',
  placeholder: 'mm/dd/yyyy',
  useDefaultAnchor: false,
  components: undefined,
  componentsProps: undefined,
  useStaticDateRange: false,
  useDayjsAdapter: false,
  maxDate: undefined,
};

export default DateRangePicker;
