import { useRef, useEffect, ForwardedRef } from 'react';

export const useSyncRef = <T extends unknown>(ref: ForwardedRef<T>) => {
    const innerRef = useRef<T>();

    useEffect(() => {
        if (!ref) return;

        if (typeof ref === 'function') {
            ref(innerRef.current);
        } else {
            ref.current = innerRef.current;
        }
    });

    return innerRef;
};
