import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { GlAccountRows } from './GeneralLedgerAccountsManageReport';

export type GeneralLedgerAccountsInfoProps = {
  glAccount: GlAccountRows;
};

function GeneralLedgerAccountsInfo(props: GeneralLedgerAccountsInfoProps) {
  const { glAccount } = props;
  const { t } = useTranslation();

  const getGlField = (title: string, value: string | number | undefined, dataTestId: string) => (
    <Box data-testid={`gl-account-details-panel-${dataTestId}`} sx={{ marginBottom: '12px' }}>
      <Typography variant='caption' color='text.secondary'>
        {title}
      </Typography>
      <Typography variant='body1'>{value || '--'}</Typography>
    </Box>
  );

  return (
    <Box sx={{ padding: '24px' }}>
      <Typography variant='h5' sx={{ marginBottom: '15px' }} data-testid='gl-account-details-panel-header'>
        {glAccount.accountNumber} {glAccount.accountName}
      </Typography>
      <FormControlLabel
        control={<Switch color={glAccount.isActive ? 'primary' : 'secondary'} />}
        disabled
        checked={glAccount.isActive}
        label={
          glAccount.isActive
            ? t('admin.glAccountConfiguration.generalLedgerAccounts.active')
            : t('admin.glAccountConfiguration.generalLedgerAccounts.inactive')
        }
        sx={{ marginBottom: '10px' }}
      />
      <FormControlLabel
        control={<Switch color={glAccount.isDisplayedInInvoicing ? 'primary' : 'secondary'} />}
        disabled
        checked={glAccount.isDisplayedInInvoicing}
        label={
          glAccount.isDisplayedInInvoicing
            ? t('admin.glAccountConfiguration.generalLedgerAccounts.showForInvoicing')
            : t('admin.glAccountConfiguration.generalLedgerAccounts.hideFromInvoicing')
        }
        sx={{
          marginBottom: '10px',
        }}
      />
      {getGlField(t('labor.glCode'), glAccount.accountNumber, 'gl-code')}
      {getGlField(
        t('admin.glAccountConfiguration.generalLedgerAccounts.glAccountName'),
        glAccount.accountName,
        'gl-account-name'
      )}
      {getGlField(
        t('admin.glAccountConfiguration.generalLedgerAccounts.glCategory'),
        glAccount.categoryName,
        'gl-category'
      )}
      {getGlField(t('admin.glAccountConfiguration.generalLedgerAccounts.glType'), glAccount.accountType, 'gl-type')}
      {getGlField(
        t('admin.glAccountConfiguration.generalLedgerAccounts.glDetailType'),
        glAccount.accountTypeDetail,
        'gl-detail-type'
      )}
      {getGlField(
        t('admin.glAccountConfiguration.generalLedgerAccounts.parentGlAccount'),
        glAccount.parentGLAccount?.accountNumber,
        'gl-parent'
      )}
      {getGlField(
        t('admin.glAccountConfiguration.generalLedgerAccounts.description'),
        glAccount.description,
        'gl-description'
      )}
    </Box>
  );
}

export default GeneralLedgerAccountsInfo;
