import { TFunction } from 'i18next';

// eslint-disable-next-line import/prefer-default-export
export function formatAgeIndicator(currentDateInMinutes: number, createdAt: Date, t: TFunction) {
  const createdAtInMinutes = Math.floor(createdAt.getTime() / 1000 / 60);
  const differenceInMinutes = currentDateInMinutes - createdAtInMinutes;

  // TODO: Take into account user preferences when displaying age indicator. i.e: number format & language
  if (differenceInMinutes < 1) {
    return t('rightPanel.notifications.aMomentAgo');
    // eslint-disable-next-line no-else-return
  } else if (differenceInMinutes < 60) {
    const shouldDisplayPluralText = differenceInMinutes > 1;
    const minutesAgo = Math.floor(differenceInMinutes);

    return `${minutesAgo} ${
      shouldDisplayPluralText ? t('rightPanel.notifications.minsAgo') : t('rightPanel.notifications.minAgo')
    }`;
  } else if (differenceInMinutes < 1440) {
    const hoursAgo = Math.floor(differenceInMinutes / 60);
    const shouldDisplayPluralText = hoursAgo > 1;

    return `${hoursAgo} ${
      shouldDisplayPluralText ? t('rightPanel.notifications.hoursAgo') : t('rightPanel.notifications.hourAgo')
    }`;
  } else if (differenceInMinutes < 43200) {
    const daysAgo = Math.floor(differenceInMinutes / 60 / 24);
    const shouldDisplayPluralText = daysAgo > 1;

    return `${daysAgo} ${
      shouldDisplayPluralText ? t('rightPanel.notifications.daysAgo') : t('rightPanel.notifications.dayAgo')
    }`;
  } else if (differenceInMinutes < 518400) {
    const monthsAgo = Math.floor(differenceInMinutes / 60 / 24 / 30);
    const shouldDisplayPluralText = monthsAgo > 1;

    return `${monthsAgo} ${
      shouldDisplayPluralText ? t('rightPanel.notifications.monthsAgo') : t('rightPanel.notifications.monthAgo')
    }`;
  }

  return null;
}
