import { IsoCurrency, DateTimeStyle } from '../models/config';
import { TRegionSettings } from '../models/config/region';

/**
 * The list of regions supported by CBO
 */
export type TRegion = 'en-US' | 'es-MX' | 'fr-CA';
export enum AllRegions {
  EN_US = 'en-US',
  ES_MX = 'es-MX',
  FR_CA = 'fr-CA',
}

/**
 * Settings for supported regions
 */
export const Regions: { [region in TRegion]: TRegionSettings } = {
  'en-US': {
    formatting: {
      currency: {
        options: {
          currency: IsoCurrency.US_DOLLAR,
        },
      },
      time: {
        options: {
          timeStyle: DateTimeStyle.SHORT,
          hour12: true,
        },
      },
      number: {
        options: { minimumFractionDigits: 2 },
      },
      date: {
        dateFormatString: 'MM/DD/YYYY',
      },
    },
  },
  'es-MX': {
    formatting: {
      currency: {
        options: {
          currency: IsoCurrency.MEXICAN_PESO,
        },
      },
      time: {
        options: {
          timeStyle: DateTimeStyle.SHORT,
          hour12: false,
        },
      },
      number: {
        options: {
          minimumFractionDigits: 2,
        },
      },
      date: {
        dateFormatString: 'DD/MM/YYYY',
      },
    },
  },
  'fr-CA': {
    formatting: {
      currency: {
        options: {
          currency: IsoCurrency.CANADIAN_DOLLAR,
        },
      },
      time: {
        options: {
          timeStyle: DateTimeStyle.SHORT,
          hour12: false,
        },
      },
      number: {
        options: { minimumFractionDigits: 2 },
      },
      date: {
        dateFormatString: 'YYYY-MM-DD',
      },
    },
  },
};
