import { SxProps, useTheme } from '@mui/material';
import { Theme } from '@mui/system';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { LaborRule, SchedulingRuleTypes } from '../models/LaborRuleConfiguration';
import RuleTypography from '../RuleTypography/RuleTypography';
import RuleTypographyForm from '../RuleTypographyForm/RuleTypographyForm';
import ConditionalTemplate from '../ConditionalTemplate/ConditionalTemplate';
import { getTemplateString } from '../utils/utils';

export interface LaborRuleTemplateProps {
  id: number;
  rule: LaborRule;
  focusMode?: boolean;
  editable?: boolean;
  action?: JSX.Element;
  sx?: SxProps<Theme>;
  locality?: string;
}

function Template(props: LaborRuleTemplateProps) {
  const { id, rule, editable, locality } = props;
  const [currentRule, setCurrentRule] = useState(rule);
  const { t } = useTranslation();
  let component;

  /**
   * This ensures changing the dropdown values updates the template string immediately
   * Useful for rules like MissedBreakRule and RightToRestRule
   * where changing the dropdown values requires getting a new template string
   */
  const handleSelectChange = (updatedRule: LaborRule) => {
    setCurrentRule(updatedRule);
  };

  switch (currentRule.type) {
    case SchedulingRuleTypes.AdjustedRateRemainingTimePredictabilityPayRule:
    case SchedulingRuleTypes.ExtraHoursPredictabilityPayRule:
    case SchedulingRuleTypes.FixedFeePredictabilityPayRule:
    case SchedulingRuleTypes.PredictabilityPayRule:
    case SchedulingRuleTypes.SplitShiftRule:
      component = <ConditionalTemplate {...props} />;
      break;
    default:
      component = editable ? (
        <RuleTypographyForm
          data-testid={`rule-typography-form-${id}`}
          formRuleIndex={id}
          templateString={getTemplateString(currentRule, t, false, locality)}
          onSelectChange={handleSelectChange}
        />
      ) : (
        <RuleTypography
          data-testid={`rule-typography-${id}`}
          rule={rule}
          templateString={getTemplateString(rule, t, false, locality)}
          isDrawer={false}
        />
      );
  }
  return component;
}

function LaborRuleTemplate(props: LaborRuleTemplateProps) {
  const { id, action, sx, focusMode } = props;
  const theme = useTheme();
  const ruleSize = action ? 11 : 12;

  return (
    <Grid
      container
      data-testid={`labor-rule-template-card-${id}`}
      sx={{
        background: theme.palette.background.default,
        padding: 1.8,
        borderRadius: 1,
        alignItems: 'center',
        ...(focusMode ? { borderStyle: 'solid', borderColor: '#C4C4C4', borderWidth: 0.7 } : {}),
        ...sx,
      }}
    >
      <Grid item xs={ruleSize}>
        <Template {...props} />
      </Grid>
      {action && (
        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {action}
        </Grid>
      )}
    </Grid>
  );
}

LaborRuleTemplate.defaultProps = {
  sx: {
    m: 2,
    mt: 0,
  },
  focusMode: false,
  editable: false,
  action: null,
  locality: undefined,
};

export default LaborRuleTemplate;
