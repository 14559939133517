import { Res } from '@cbo/shared-library';
import dayjs, { Dayjs } from 'dayjs';
import { TRegion, Regions } from '../../config';
import { DateFormat, TimeFormat } from '../../models/config';

/**
 * Returns the time format, combining regional preferences with the user's saved preferences
 * @param preferences
 * @returns Returns an instance of TimeFormat that includes locale and format options
 */
export const getTimeFormat = (preferences?: Res.Admin.UserPreferences | null, timeZone?: string): TimeFormat => {
  const region: TRegion = (preferences?.region as TRegion) || 'en-US';
  const locale: string = Regions[region].locale || region;
  return { ...Regions[region].formatting.time, locale };
};

/**
 * Formats times based on regional preferences and the user's saved preferences
 * @param value
 * @param preferences
 * @param format
 * @returns Returns the specified time (value) as formatted string
 */
export const formatTime = (
  value: string | number | Date | undefined,
  preferences?: Res.Admin.UserPreferences | null,
  timeZone?: string,
  format?: DateFormat
): string => {
  if (!value) return '';
  const { options, locale } = format || getTimeFormat(preferences);
  const opts: Intl.DateTimeFormatOptions = options as Intl.DateTimeFormatOptions;
  const time = new Date(value).getTime();
  return Intl.DateTimeFormat(locale, { ...opts, timeZone }).format(time);
};

/**
 * Formats 24 hour string time to dayjs object
 * @param time
 * @returns Returns a dayjs set to today with the hours and minutes set
 */
export const format24HourTimeToDayJs = (time: string): Dayjs | null => {
  const hoursAndMinutes = time.match(/.{1,2}/g);

  if (!hoursAndMinutes) {
    return null;
  }

  const dayJsObject = dayjs();
  const objectWithTime = dayJsObject.set('hour', Number(hoursAndMinutes[0])).set('minute', Number(hoursAndMinutes[1]));

  return objectWithTime;
};

/**
 * Formats dateTime object to 24 hour string
 * @param dateTime
 * @returns Returns a 24 hour string in the format HHmm
 */
export const formatTimeTo24HourString = (dateTime: Date): string => {
  const newDateTime = dayjs(dateTime);
  return newDateTime.format('HHmm');
};
