import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isAtBottomOfPage, setIsAtBottomOfPage] = useState(false);

  /* istanbul ignore next */
  const handleScroll = () => {
    const element = document.documentElement;
    const bottom = Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 1;
    setScrollPosition(window.scrollY);

    return bottom ? setIsAtBottomOfPage(true) : setIsAtBottomOfPage(false);
  };
  const debouncedHandleScroll = debounce(handleScroll, 75);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);

    if (document.body.clientHeight < window.innerHeight) {
      setIsAtBottomOfPage(true);
    }

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);

  return { isAtBottomOfPage, scrollPosition };
}

export default useScrollPosition;
