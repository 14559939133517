import { Bsl } from '@cbo/shared-library/types';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useCallback } from 'react';
import { useFirebaseApi } from '../contexts/firebaseApiContext';

// Call firebase function to exchange tokens and then sign into firebase with token
export default function useFirebaseTokenExchange() {
  const { exchangeOktaToken } = useFirebaseApi();
  return useCallback(
    async (accessToken: string, organization: Bsl.Provisioning.AuthorizedOrganization) => {
      if (!exchangeOktaToken) {
        throw new Error('API not yet initialized');
      }
      const firebaseToken = await exchangeOktaToken({
        accessToken,
        claims: null,
        org: organization,
      });
      return signInWithCustomToken(getAuth(), firebaseToken.data);
    },
    [exchangeOktaToken]
  );
}
