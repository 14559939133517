import { SiteInfo, SitesById } from '@cbo/shared-library';

const sorter = (s1: SiteInfo, s2: SiteInfo) => {
  const c1 = s1.name ?? s1.referenceId ?? s1.enterpriseUnitId;
  const c2 = s2.name ?? s2.referenceId ?? s2.enterpriseUnitId;

  return String.prototype.localeCompare.call(c1, c2);
};
export default function siteSort(sites: SiteInfo[] | SitesById) {
  return Object.values(sites).sort(sorter);
}
