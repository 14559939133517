import { SwitcherDialog } from 'src/components/common/Switcher/SwitcherDialog';
import { useOrganizationContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import {
    OrganizationDialogProvider,
    useOrganizationDialogContext,
} from '../OrganizationProviders/OrganizationDialogProvider';
import { OrganizationAllOrgsList } from './OrganizationAllOrgsList';

const OrganizationSwitcherDialogInner = () => {
    const { t } = useTranslationNs();

    const { setSearchValue, searchValue, handleExpandOrgClick, resetExpandedOrgs, expandedOrg } =
        useOrganizationDialogContext();
    const { isOrgDialogOpen, setIsOrgDialogOpen, userOrganizations } = useOrganizationContext();

    const handleSearchValueChange = (value: string) => {
        setSearchValue(value);
        if (value) {
            userOrganizations.forEach((org) => {
                if (expandedOrg.has(org.id)) {
                    return;
                }

                if (org.children.some((child) => child.includes(searchValue))) {
                    handleExpandOrgClick(org.id);
                }
            });
        } else {
            resetExpandedOrgs();
        }
    };

    const handleDialogClose = () => {
        setSearchValue('');
        setIsOrgDialogOpen(false);
        resetExpandedOrgs();
    };

    return (
        <SwitcherDialog
            isOpen={isOrgDialogOpen}
            dialogTitle={t('ORG_CONTEXT.MENU.TITLE')}
            dialogCloseTitle={t('ORG_CONTEXT.ACCESSIBILITY.CLOSE')}
            onClose={handleDialogClose}
            onSearch={handleSearchValueChange}
        >
            <OrganizationAllOrgsList />
        </SwitcherDialog>
    );
};

export const OrganizationSwitcherDialog = () => {
    const { setIsOrgDialogOpen, updateOrganization } = useOrganizationContext();

    return (
        <OrganizationDialogProvider
            setIsOrgDialogOpen={setIsOrgDialogOpen}
            updateOrganization={updateOrganization}
        >
            <OrganizationSwitcherDialogInner />
        </OrganizationDialogProvider>
    );
};
