import { ReactNode } from 'react';
import { useRccContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { SettingGroup } from '../../../common/SettingGroup';
import { InternalOrganizationPreferenceSwitcher } from './InternalOrganizationPreferenceSwitcher';
import { NonInternalOrganizationPreferenceSwitcher } from './NonInternalOrganizationPreferenceSwitcher';

export const DefaultOrganizationPreferences = () => {
    const { isInternalUser } = useRccContext();
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS.PREFERENCES.DEFAULTS.ORGANIZATION',
    });

    let switcher: ReactNode;

    if (isInternalUser === true) {
        switcher = <InternalOrganizationPreferenceSwitcher />;
    } else {
        switcher = <NonInternalOrganizationPreferenceSwitcher />;
    }

    return (
        <SettingGroup title={t('TITLE')} description={t('DESCRIPTION')}>
            {switcher}
        </SettingGroup>
    );
};
