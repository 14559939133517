export type RccLanguage = 'en';

export type RccEnvironment = 'dev' | 'stg' | 'prd' | 'apac' | 'emea' | 'napac';

export type RccContextConfig = {
    language: RccLanguage;
    environment: RccEnvironment;
    enableAutoLogout?: boolean;
    _TEMPORARY_userSettingsMutationEndpoint?: string;
};

export type AppEnv = {
    Services: ServiceTypeEndpoints;
    AccessToken: string;
    NepOrganization: string | undefined;
    IdmUrl: string
};

export type ServiceType = 'Provisioning' | 'Applications';

export type ServiceTypeEndpoints = Record<ServiceType, string>;

export enum RccEventKeys {
    SIGNOUT = 'SIGNOUT',
}

export enum QueryParamKeys {
    organization = 'organizationId'
}