/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';
import { AccountPreferencesContext } from '../../contexts/accountPreferencesContext';
import CurrencyTextField from '../../components/Currency/CurrencyTextField';
import { MinimumWageRule } from '../models/LaborRuleConfiguration';

interface TippedMinWageTypographyFormProps {
  templateString: string;
  rule: MinimumWageRule;
}

export default function TippedMinWageTypographyForm(props: TippedMinWageTypographyFormProps) {
  const { t } = useTranslation();
  const { templateString, rule } = props;
  const { preferences } = useContext(AccountPreferencesContext);
  const prefix = 'laborRules.rules.MinimumWageRule.Tipped';
  const formContext = useFormContext();
  const [minWage, setMinWage] = useState<number | undefined>(rule.amount);
  const { setValue } = formContext;

  const handleValueChanged = (v: NumberFormatValues) => {
    setMinWage(v.floatValue ?? 0);
    if (v.floatValue === undefined) {
      setValue('rules.0.rule.amount', '');
    }
  };

  useEffect(() => {
    const newValue = rule.stdMinWageAmount! - (minWage ?? 0);
    setValue('rules.0.rule.maxTipCredit', newValue);
  }, [minWage, setValue, rule.stdMinWageAmount]);

  const operatorStyle = { mx: 2, display: 'flex', height: '100%', alignItems: 'center' };
  return (
    <Grid container sx={{ my: 2 }}>
      <Grid item xs={12} sx={{ display: 'flex', mb: 2, alignItems: 'flex-start' }}>
        <Box sx={{ width: 160 }}>
          <CurrencyTextField
            formContext={formContext}
            gridProps={{ flexGrow: 1 }}
            label={t(`${prefix}.standardMinWage`)}
            name='standardMinWage'
            dataTestId='standard-min-wage-input'
            preferences={preferences}
            inputValue={rule.stdMinWageAmount!}
            helperText={t('formMessage.readOnly')}
            readOnly
          />
        </Box>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Typography sx={operatorStyle}> - </Typography>
        <Box sx={{ width: 220 }}>
          <CurrencyTextField
            formContext={formContext}
            gridProps={{ flexGrow: 1 }}
            label={t(`${prefix}.minWageForEmployee`)}
            required
            name='rules.0.rule.amount'
            dataTestId='min-wage-for-employee-input'
            preferences={preferences}
            onValueChange={handleValueChanged}
          />
        </Box>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Typography sx={operatorStyle}>=</Typography>
        <Box sx={{ width: 130 }}>
          <CurrencyTextField
            formContext={formContext}
            gridProps={{ flexGrow: 1 }}
            label={t(`${prefix}.maxTipCredit`)}
            name='rules.0.rule.maxTipCredit'
            dataTestId='max-tip-credit-input'
            preferences={preferences}
            helperText={t('formMessage.readOnly')}
            readOnly
            allowNegative
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {templateString}
      </Grid>
    </Grid>
  );
}

TippedMinWageTypographyForm.defaultProps = {};
