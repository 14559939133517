import { ReactNode, useCallback, useMemo } from 'react';
import { AppConstants } from 'src/constants/app';
import { UserSettingKeys } from 'src/constants/user-settings-keys';
import { useRccContext } from 'src/contexts';
import { IUserSettingsContext, UserSettingsContext } from 'src/contexts/UserSettingsContext';
import { useGetUserSettingsByName } from 'src/fetchers/user-settings.fetchers';
import { UserConfigurationSetting } from 'src/types/user-settings.type';

export const UserSettingsProvider = ({ children }: { children: ReactNode }) => {
    const { isAuthenticated, _TEMPORARY_userSettingsMutationEndpoint } = useRccContext();
    const {
        userSettings,
        isUserSettingsLoading,
        isUserSettingsValidating,
        isUserSettingsMutating,
        getUserSettingsError,
        userSettingsMutationError,
        updateUserSettings,
    } = useGetUserSettingsByName(
        AppConstants.GlobalConfigSetName,
        isAuthenticated,
        _TEMPORARY_userSettingsMutationEndpoint,
    );

    const getUserSettingByKey = useCallback(
        (key: UserSettingKeys) => {
            return userSettings?.configurationSettings.find(
                (ucs: UserConfigurationSetting) => ucs.key === key,
            )?.value;
        },
        [userSettings],
    );

    const value: IUserSettingsContext = useMemo(
        () => ({
            userSettings,
            isUserSettingsLoading,
            isUserSettingsValidating,
            isUserSettingsMutating,
            getUserSettingsError,
            userSettingsMutationError,
            updateUserSettings,
            getUserSettingByKey,
        }),
        [
            userSettings,
            isUserSettingsLoading,
            isUserSettingsValidating,
            isUserSettingsMutating,
            getUserSettingsError,
            userSettingsMutationError,
            updateUserSettings,
            getUserSettingByKey,
        ],
    );

    return <UserSettingsContext.Provider value={value}>{children}</UserSettingsContext.Provider>;
};
