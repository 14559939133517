import { Skeleton } from '@mui/material';
import { ThemeValues } from 'src/constants/theme-values';

export const SwitcherSkeleton = ({ width = '140px' }: { width?: string }) => {
    return (
        <Skeleton
            variant='rectangular'
            height='40px'
            width={width}
            sx={{ borderRadius: ThemeValues.BorderRadius }}
        />
    );
};
