import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { FieldArrayWithId, UseFieldArrayUpdate } from 'react-hook-form';
import { useState } from 'react';
import { CustomLaborRuleConfigForm, Exemption, RuleFormValues } from '../models/LaborRuleConfiguration';

export interface ExemptionsProps {
  selectedExemptions: Exemption[];
  update?: UseFieldArrayUpdate<RuleFormValues, 'rules'>;
  fields?: FieldArrayWithId<RuleFormValues, 'rules', 'id'>[];
  editable?: boolean;
}

export const handleCheck = (
  checked: boolean,
  exemption: Exemption,
  selectedExemptions: Exemption[],
  setChecked: (exemptions: Exemption[]) => void,
  fields: FieldArrayWithId<RuleFormValues, 'rules', 'id'>[],
  update: UseFieldArrayUpdate<RuleFormValues, 'rules'>
) => {
  if (checked) {
    selectedExemptions.push(exemption);
  } else {
    selectedExemptions.splice(selectedExemptions.indexOf(exemption), 1);
  }
  setChecked(selectedExemptions);
  fields.forEach((field: FieldArrayWithId<RuleFormValues, 'rules', 'id'>, i: number) =>
    update(i, { ...field, rule: { ...field.rule, exemptions: selectedExemptions } } as CustomLaborRuleConfigForm)
  );
};

export function Exemptions(props: ExemptionsProps) {
  const { selectedExemptions, update, fields, editable } = props;
  const { t } = useTranslation();
  const [checkedExemptions, setChecked] = useState(selectedExemptions);

  return (
    <Grid container>
      <Grid item sx={{ mb: '.75rem', mt: '1.5rem' }}>
        {t('laborRules.exemptions.header')}
      </Grid>
      <Grid
        container
        sx={{ borderStyle: 'solid', borderColor: '#C4C4C4', borderWidth: 0.7, borderRadius: '.5rem', padding: '1rem' }}
      >
        {Object.values(Exemption).map((exemption) => (
          <Grid key={`${exemption}`} item sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              onChange={(e, checked) =>
                fields && update && handleCheck(checked, exemption, selectedExemptions, setChecked, fields, update)
              }
              data-testid={`${exemption}`}
              checked={checkedExemptions.indexOf(exemption) !== -1}
              disabled={!editable}
            />
            <Typography color={checkedExemptions.indexOf(exemption) !== -1 ? 'text.primary' : 'text.secondary'}>
              {t(`laborRules.exemptions.${exemption}`)}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

Exemptions.defaultProps = {
  editable: false,
  update: undefined,
  fields: undefined,
};
