import { Res } from '@cbo/shared-library';

const defaultPreferences: Res.Admin.UserPreferences = {
  language: 'en-US',
  showCurrencySymbol: true,
  region: 'en-US',
  dontShowAgain: [],
};

export default defaultPreferences;
