import { UseMutationResult } from '@tanstack/react-query';
import routes from '../../../constants/routes';
import { ConfirmationFormValues } from '../../modals/LaborRuleBaseConfirmationModal/LaborRuleBaseConfirmationModal';
import {
  CreateChangeSetRequest,
  CustomLaborRuleConfigForm,
  LaborRuleConfigData,
  MinorRuleTypes,
  RuleChangeReason,
  RuleFormValues,
  SchedulingRuleTypes,
} from '../../models/LaborRuleConfiguration';
import { mutateData, splitSchoolDayDefChangesetRequest } from '../../utils/utils';
import { ISnackbarStatus } from '../../../contexts/SnackbarContext';

export const getFormDataWithDefaultRules = (
  data: RuleFormValues,
  defaultRulesData: LaborRuleConfigData[],
  effectiveDate: string,
  changeReason: RuleChangeReason
) => {
  const defaultRules = defaultRulesData.map((config) => config.rule);
  return mutateData(
    {
      ...data,
      rules: defaultRules.map((rule) => ({
        id: 1,
        rule,
      })) as CustomLaborRuleConfigForm[],
    },
    effectiveDate,
    changeReason,
    defaultRulesData.length > 0 ? defaultRulesData[0].changesetId : null
  );
};

export type HandleSuccessProps = {
  effectiveDate: string;
  ruleName: string;
  jurisdictionName: string;
  push: (path: string) => void;
  setOpenModal: (value: boolean) => void;
  refetchConfigs: () => Promise<void>;
  setSnackbarState: (snackbarState: ISnackbarStatus) => void;
};

export type SaveAndDeleteHandlerProps = {
  data: RuleFormValues;
  formData: ConfirmationFormValues;
  changesetsToDelete: number[];
  handleSuccessProps: HandleSuccessProps;
  saveNewRuleConfigurationMutation: UseMutationResult<
    void | {
      body?: string | undefined;
    },
    unknown,
    CreateChangeSetRequest,
    unknown
  >;
  deleteRuleConfigurationsMutation: UseMutationResult<{ body?: string } | void | unknown[], unknown, number[], unknown>;
};

export const handleSuccess = async (props: HandleSuccessProps) => {
  const { setOpenModal, push, refetchConfigs, setSnackbarState, ruleName, jurisdictionName, effectiveDate } = props;
  setOpenModal(false);
  push(routes.LABOR_RULES);
  await refetchConfigs();
  setSnackbarState({
    open: true,
    color: 'success',
    message: `${ruleName} saved. Update is scheduled to go into effect for selected sites in ${jurisdictionName} on ${effectiveDate}`,
  });
};

const saveAndDelete = async (
  formReqBody: CreateChangeSetRequest,
  changesetsToDelete: number[],
  handleSuccessProps: HandleSuccessProps,
  saveNewRuleConfigurationMutation: UseMutationResult<
    void | {
      body?: string | undefined;
    },
    unknown,
    CreateChangeSetRequest,
    unknown
  >,
  deleteRuleConfigurationMutation: UseMutationResult<{ body?: string } | void | unknown[], unknown, number[], unknown>
) => {
  await saveNewRuleConfigurationMutation.mutateAsync(formReqBody);
  if (changesetsToDelete.length > 0) {
    await deleteRuleConfigurationMutation.mutateAsync(changesetsToDelete);
  }
  await handleSuccess(handleSuccessProps);
};

export const handleRestoreDefaults = async (
  defaultRulesData: LaborRuleConfigData[],
  props: SaveAndDeleteHandlerProps
) => {
  const {
    data,
    formData,
    changesetsToDelete,
    handleSuccessProps,
    saveNewRuleConfigurationMutation,
    deleteRuleConfigurationsMutation,
  } = props;
  const formReqBody = getFormDataWithDefaultRules(
    data,
    defaultRulesData,
    formData.effectiveDate,
    formData.changeReason || RuleChangeReason.COMPANY_POLICY
  );
  await saveAndDelete(
    formReqBody,
    changesetsToDelete,
    handleSuccessProps,
    saveNewRuleConfigurationMutation,
    deleteRuleConfigurationsMutation
  );
};

export const handleSaveAndDelete = async (props: SaveAndDeleteHandlerProps) => {
  const {
    data,
    formData,
    changesetsToDelete,
    handleSuccessProps,
    saveNewRuleConfigurationMutation,
    deleteRuleConfigurationsMutation,
  } = props;
  const formReqBody = mutateData(
    data,
    formData.effectiveDate,
    formData.changeReason || RuleChangeReason.COMPANY_POLICY,
    null
  );
  if (formReqBody.ruleType === SchedulingRuleTypes.PredictabilityPayRule) {
    formReqBody.ruleType = 'PredictabilityPay';
  }
  if (formReqBody.ruleType === MinorRuleTypes.MinorSchoolDayDefinitionRule) {
    Promise.all(
      splitSchoolDayDefChangesetRequest(formReqBody).map((changeSet) =>
        saveAndDelete(
          changeSet,
          changesetsToDelete,
          handleSuccessProps,
          saveNewRuleConfigurationMutation,
          deleteRuleConfigurationsMutation
        )
      )
    );
  } else {
    await saveAndDelete(
      formReqBody,
      changesetsToDelete,
      handleSuccessProps,
      saveNewRuleConfigurationMutation,
      deleteRuleConfigurationsMutation
    );
  }
};

export const handleDelete = async (
  changesetsToDelete: number[],
  handleSuccessProps: HandleSuccessProps,
  deleteRuleConfigurationMutation: UseMutationResult<{ body?: string } | void | unknown[], unknown, number[], unknown>
) => {
  if (changesetsToDelete.length > 0) {
    await deleteRuleConfigurationMutation.mutateAsync(changesetsToDelete);
  }
  await handleSuccess(handleSuccessProps);
};
