import { Req } from '@cbo/shared-library';
import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';

const recordLogoutEvent = async (userInfo: Req.Firebase.RecordLogout) => {
  const functions = getFunctions();
  const recordLogoutEventFirebaseFunction = httpsCallable(functions, 'recordLogoutEvent2');
  const res: HttpsCallableResult = await recordLogoutEventFirebaseFunction({ body: userInfo });
  return res?.data;
};

export default recordLogoutEvent;
