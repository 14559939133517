import { useState } from 'react';

function useScrollWithShadow() {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const [boxShadow, setBoxShadow] = useState<string>('none');

  function resetScrollStates() {
    setScrollHeight(0);
    setClientHeight(0);
    setScrollTop(0);
  }

  const onScrollHandler = (event: React.UIEvent<HTMLElement>) => {
    if (event.target) {
      setScrollTop((event.target as HTMLElement).scrollTop);
      setScrollHeight((event.target as HTMLElement).scrollHeight);
      setClientHeight((event.target as HTMLElement).clientHeight);
    }
  };

  function setShadow(newState?: string) {
    const isTop = scrollTop === 0;
    const isBottom = clientHeight === scrollHeight - scrollTop;
    const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;
    const bottom = 'inset 0 -8px 5px -5px rgb(200 200 200 / 1)';
    const top = 'inset 0 8px 5px -5px rgb(200 200 200 / 1)';

    if (newState) {
      setBoxShadow(newState);
    } else if (isTop) {
      setBoxShadow(bottom);
    } else if (isBetween) {
      setBoxShadow(`${top}, ${bottom}`);
    } else if (isBottom) {
      setBoxShadow(top);
    }
  }

  return { boxShadow, setShadow, resetScrollStates, onScrollHandler };
}

export default useScrollWithShadow;
