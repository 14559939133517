import AppConfiguration from './shared/models/AppConfiguration';

const defaultConfig: AppConfiguration = {
  appRoot: '',
  apiRoot: '',
  oktaConfig: null,
  projectId: '',
  apiKey: '',
  authDomain: '',
  appId: '',
  messagingSenderId: '',
  storageBucket: '',
  scanBucket: '',
  vapidKey: '',
  fullCalendarLicenseKey: '',
  launchDarklyClientId: '',
  ncridUrl: '',
};

class GlobalConfig {
  config: AppConfiguration = defaultConfig;

  notDefinedYet = true;

  public get(): AppConfiguration | null {
    if (this.notDefinedYet && process.env.NODE_ENV !== 'test') {
      throw new Error('Global config has not been defined yet.');
    } else {
      return this.config;
    }
  }

  public set(value: AppConfiguration): void {
    if (this.notDefinedYet) {
      this.config = value;
      this.notDefinedYet = false;
    } else {
      throw new Error('Global config has already been defined');
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export const globalConfig = new GlobalConfig();
