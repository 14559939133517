import { createContext, useContext, useMemo, useState } from 'react';
import { MessagePayload } from 'firebase/messaging';
import { BroadcastChannel } from 'broadcast-channel';
import ContextProviderProps from '../models/ContextProviderProps';

let defaultNotification: null | MessagePayload = null;
let defaultBroadcastChannel: null | BroadcastChannel = null;

export type TNotificationContext = {
  newNotification: null | MessagePayload;
  setNewNotification: (notification: MessagePayload) => void;
  broadcastChannel: null | BroadcastChannel;
  setBroadcastChannel: (broadcastChannel: BroadcastChannel) => void;
};

export const NotificationContext = createContext<TNotificationContext>({
  newNotification: null,
  setNewNotification: (notification) => {
    defaultNotification = notification;
  },
  broadcastChannel: null,
  setBroadcastChannel: (bc: BroadcastChannel) => {
    defaultBroadcastChannel = bc;
  },
});

export const useNotifications = () => useContext(NotificationContext);

export function NotificationContextProvider({ children }: ContextProviderProps) {
  const [newNotification, setNewNotification] = useState<MessagePayload | null>(defaultNotification);
  const [broadcastChannel, setBroadcastChannel] = useState<BroadcastChannel | null>(defaultBroadcastChannel);

  if (defaultBroadcastChannel == null) {
    defaultBroadcastChannel = new BroadcastChannel('notifications');
    setBroadcastChannel(defaultBroadcastChannel);
  }

  const providerValue = useMemo(
    () => ({ newNotification, broadcastChannel, setNewNotification, setBroadcastChannel }),
    [newNotification, broadcastChannel]
  );

  return <NotificationContext.Provider value={providerValue}>{children}</NotificationContext.Provider>;
}

export default NotificationContextProvider;
