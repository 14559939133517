import { Req } from '@cbo/shared-library';
import { HttpsError } from '../contexts/firebaseApiContext';
import useCallBsl from '../utils/hooks/useCallBsl';

export const useCallBslAndHandleErrors = () => {
  const callBsl = useCallBsl();
  return async <T, E>(
    request: Req.Firebase.BslProxyRequest,
    handleError: (error: unknown) => E,
    errorMessageMapper?: Record<string, string>
  ): Promise<T | void> =>
    callBsl<T>(request).catch((err) => {
      const firebaseStatus = (err as HttpsError).code;
      if (errorMessageMapper) {
        const errorKey = firebaseStatus.substring(firebaseStatus.indexOf('/') + 1);
        if (errorKey in errorMessageMapper) {
          // Call handleError instead of returning it because the promise was resolving with undefined, thus
          // triggering the onSuccess callback in mutations even when the request failed
          handleError(`${errorMessageMapper[errorKey]}`);
        }
      }
      // TODO: Update to remove caught and then thrown error for error boundary
      throw err;
    });
};

export default useCallBslAndHandleErrors;
