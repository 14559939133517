import i18next from 'i18next';
import { CboRole } from '@cbo/shared-library';
import isPermitted from '../../../lib/permissions';
import { useUsers } from '../../../contexts/userContext';
import routes from '../../../constants/routes';
import { NavItem } from '.';
import { FeatureFlag, useFeatureFlags } from '../../../utils/hooks/useFeatureFlag';

export default function useInventoryRoutes(): NavItem[] {
  const user = useUsers();
  const [asmCore, invoiceHistoryReportEnabled] = useFeatureFlags(FeatureFlag.asmCore, FeatureFlag.invoiceHistoryReport);
  const inventoryRoutes: NavItem[] = [];

  if (isPermitted(user, [CboRole.VENDOR_VIEW])) {
    inventoryRoutes.push({
      title: i18next.t('navItems.vendors'),
      href: routes.VENDORS,
      testid: 'vendors',
    });
  }

  if (isPermitted(user, [CboRole.RAW_ITEM_VIEW])) {
    inventoryRoutes.push({
      title: i18next.t('navItems.rawItems'),
      href: routes.RAW_ITEMS,
      testid: 'raw-items',
    });
  }

  if (asmCore && isPermitted(user, [CboRole.UNITS_OF_MEASURE_VIEW])) {
    inventoryRoutes.push({
      title: i18next.t('navItems.unitsOfMeasure'),
      href: routes.UNITS_OF_MEASURE,
      testid: 'units-of-measure',
    });
  }

  if (isPermitted(user, [CboRole.INVOICE_VIEW])) {
    inventoryRoutes.push({
      title: i18next.t('navItems.invoices'),
      href: routes.INVOICES,
      testid: 'invoice-items',
    });
  }

  if (invoiceHistoryReportEnabled && isPermitted(user, [CboRole.INVOICE_VIEW])) {
    inventoryRoutes.push({
      title: i18next.t('navItems.invoiceHistory'),
      href: routes.INVOICE_HISTORY_REPORT,
      testid: 'invoice-history-report',
    });
  }

  return inventoryRoutes;
}
