import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LaborRuleBaseConfirmationModal, {
  ConfirmationFormValues,
} from '../LaborRuleBaseConfirmationModal/LaborRuleBaseConfirmationModal';
import {
  LaborRule,
  LaborRuleConfigData,
  MinimumWageRule,
  SchedulingRuleTypes,
  WagesRuleTypes,
} from '../../models/LaborRuleConfiguration';
import LaborRuleTemplate from '../../LaborRuleTemplate/LaborRuleTemplate';

interface RestoreDefaultModalProps {
  ruleType: string;
  defaultRules: LaborRuleConfigData[];
  handleRestore: (confirmData: ConfirmationFormValues) => Promise<void>;
  handleClose: () => void;
  loading?: boolean;
}

export default function RestoreDefaultModal(props: RestoreDefaultModalProps) {
  const { handleRestore, handleClose, ruleType, defaultRules, loading } = props;
  const { t } = useTranslation();
  const ruleName = t(`laborRules.rules.${ruleType}.name`);
  const getRules = defaultRules;
  let stdMinWage: number;
  getRules?.slice().forEach((item) => {
    const { ruleName: laborRule, rule } = item;
    if (laborRule === 'Minimum Wage') {
      stdMinWage = (rule as MinimumWageRule).amount ?? 0;
    }
    if (laborRule === 'Minimum Wage Tipped') {
      (rule as MinimumWageRule).stdMinWageAmount = stdMinWage;
    }
  });
  const dialogMaxWidth =
    ruleType === WagesRuleTypes.MinimumWageRule || ruleType === SchedulingRuleTypes.SplitShiftRule ? 'md' : 'sm';

  const defaultRulesDiv = () => (
    <Grid container item direction='column' mb={1}>
      <Grid item>
        <Typography>{t('laborRules.restoreDefaultModal.jurisdictionDefaultLabel')} </Typography>
      </Grid>
      {getRules?.length > 0 ? (
        getRules.map((config: LaborRuleConfigData, index: number) => (
          <Grid item key={`grid-item-lre-template-${config.id}`}>
            <LaborRuleTemplate
              // eslint-disable-next-line react/no-array-index-key
              key={`lre-template-${index}-${config.rule.type}-${config.id}`}
              id={index}
              rule={config.rule}
              focusMode
              sx={{ my: 1 }}
            />
          </Grid>
        ))
      ) : (
        <LaborRuleTemplate
          key='noGuidance'
          id={0}
          data-testid='labor-rule-template-noGuidance'
          sx={{
            mt: 1,
          }}
          rule={{} as LaborRule}
        />
      )}
    </Grid>
  );

  return (
    <LaborRuleBaseConfirmationModal
      dataTestId='restore-default-rules-confirmation-modal'
      title={t('laborRules.restoreDefaultModal.title', { ruleName })}
      ctaText={t('laborRules.restoreDefaultModal.ctaButtonText')}
      subHeaderText={t('laborRules.restoreDefaultModal.subHeaderText')}
      handleClose={handleClose}
      ctaAction={handleRestore}
      ctaRed
      body={defaultRulesDiv()}
      dateReadOnly
      dialogMaxWidth={dialogMaxWidth}
      loading={loading}
    />
  );
}

RestoreDefaultModal.defaultProps = {
  loading: false,
};
