import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { PersonaRole, Req, Res } from '@cbo/shared-library';

export const personToRoleNames: Record<PersonaRole, string> = {
  [PersonaRole.ADMIN]: 'CBO_ADMIN',
  [PersonaRole.ASSISTANT_MANAGER]: 'CBO_ASSISTANT_MANAGER',
  [PersonaRole.EMPLOYEE]: 'CBO_EMPLOYEE',
  [PersonaRole.SUPPORT]: 'CBO_SUPPORT',
  [PersonaRole.MANAGER]: 'CBO_RESTAURANT_MANAGER',
  [PersonaRole.ACCOUNTANT]: 'CBO_ACCOUNTANT',
};

const everyoneRoleNames = Object.values(PersonaRole).map((role) => personToRoleNames[role]);
const isViewerEveryone = (link: Res.Firebase.Link): boolean =>
  everyoneRoleNames.every((roleName) => !!link.readRoles?.find((role) => role.id === roleName));
const everyoneRole: Res.Firebase.DocumentRole = {
  id: 'Everyone',
  name: 'Everyone',
  description: 'Everyone',
};

export const getAllLinks = async (filterByUserRoles?: boolean): Promise<Res.Firebase.Link[]> => {
  const functions = getFunctions();
  const getOrganizationLinks = httpsCallable(functions, 'getOrganizationLinks2');
  const res: HttpsCallableResult = await getOrganizationLinks({ body: { filterByUserRoles } });
  const results = (res.data as Res.Firebase.Link[]).map((link) =>
    isViewerEveryone(link)
      ? {
          ...link,
          readRoles: [everyoneRole],
        }
      : link
  );
  return results;
};

export const addNewLink = async (link: Req.Firebase.LinkData) => {
  const functions = getFunctions();
  const addLink = httpsCallable(functions, 'addLink2');
  const res: HttpsCallableResult = await addLink({ body: link });
  return res.data as Res.Firebase.Link;
};

export const deleteCompanyLink = async (linkId: string): Promise<string> => {
  const functions = getFunctions();
  const deleteLink = httpsCallable(functions, 'deleteLink2');
  const res: HttpsCallableResult = await deleteLink({ body: { id: linkId } });
  return res.data as string;
};

export const editLink = async (link: Req.Firebase.Link) => {
  const functions = getFunctions();
  const updateLink = httpsCallable(functions, 'updateLink2');
  const res: HttpsCallableResult = await updateLink({ body: link });
  return res.data as Res.Firebase.Link;
};
