import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Ty from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { CreateGlAccountRequest, GlAccount } from '../requests/requests';
import SalesUtilities from '../utilities';
import ActionsDialog from '../../components/ActionsDialog/ActionsDialog';
import GeneralLedgerAccountsDialogContent from './GeneralLedgerAccountsDialogContent';
import { useUpdateIsActiveGlAccountsMutation } from '../requests/mutations';

export interface GeneralLedgerAccountsEditDialogProps {
  open: boolean;
  deactivate: boolean;
  onClose: () => void;
  glAccounts: GlAccount[];
  handleUpdate: (requestData: CreateGlAccountRequest) => Promise<void>;
  data: CreateGlAccountRequest | undefined;
  onSuccess: () => void;
  bulkAction?: boolean;
  setBulkActionAccountTree: Dispatch<SetStateAction<GlAccount[] | undefined>>;
  fromActionsMenu: boolean;
  setFromActionsMenu: Dispatch<SetStateAction<boolean>>;
}

function GeneralLedgerAccountsEditDialog(props: GeneralLedgerAccountsEditDialogProps) {
  const {
    open,
    deactivate,
    onClose,
    data,
    handleUpdate,
    glAccounts,
    bulkAction,
    setBulkActionAccountTree,
    onSuccess,
    fromActionsMenu,
    setFromActionsMenu,
  } = props;
  const { t } = useTranslation();
  const { setSnackbarState } = useSnackbar();
  const history = useHistory();
  const { mutateAsync: updateIsActiveGlAccounts } = useUpdateIsActiveGlAccountsMutation();

  const deactivateWithoutChildren = deactivate && !glAccounts[0].subAccounts.length;
  const deactivateWithChildren = deactivate && glAccounts[0].subAccounts && glAccounts[0].subAccounts.length > 0;

  const additionalDialogTitleContent = () => {
    let content: React.ReactNode = null;
    if (glAccounts.length === 1) {
      content = (
        <Ty sx={{ marginTop: '24px', marginBottom: '8px' }} data-testid='gl-accounts-edit-modal-title-content'>
          {deactivateWithoutChildren &&
            t('admin.glAccountConfiguration.actionContent.additionalDialogTitleSingleAccount', {
              action: t('admin.glAccountConfiguration.actionContent.deactivate'),
            })}
          {deactivateWithChildren && (
            <Trans
              i18nKey='admin.glAccountConfiguration.actionContent.additionalDialogTitleSingleAndSub'
              values={{
                numberOfGlAccounts: SalesUtilities.getNumberOfAccountsInTree(glAccounts[0]),
                action: t('admin.glAccountConfiguration.actionContent.deactivate'),
                actionStart: t('admin.glAccountConfiguration.actionContent.deactivating'),
              }}
            />
          )}
          {!deactivate && (
            <Trans
              i18nKey='admin.glAccountConfiguration.actionContent.additionalDialogTitleSingleAndSub'
              values={{
                numberOfGlAccounts: SalesUtilities.getNumberOfAccountsInTree(glAccounts[0]),
                action: t('admin.glAccountConfiguration.actionContent.activate'),
                actionStart: t('admin.glAccountConfiguration.actionContent.activating'),
              }}
            />
          )}
        </Ty>
      );
    }
    if (glAccounts.length > 1) {
      content = (
        <Ty sx={{ marginTop: '24px', marginBottom: '8px' }} data-testid='gl-accounts-edit-modal-title-content'>
          <Trans
            i18nKey={
              SalesUtilities.checkIfSubAccountsExist(glAccounts)
                ? 'admin.glAccountConfiguration.actionContent.additionalDialogTitleMultipleAndSub'
                : 'admin.glAccountConfiguration.actionContent.additionalDialogTitleMultipleAccounts'
            }
            values={{
              numberOfGlAccounts: SalesUtilities.getNumberOfGlAccounts(glAccounts),
              action: deactivate
                ? t('admin.glAccountConfiguration.actionContent.deactivate')
                : t('admin.glAccountConfiguration.actionContent.activate'),
              actionStart: deactivate
                ? t('admin.glAccountConfiguration.actionContent.deactivating')
                : t('admin.glAccountConfiguration.actionContent.activating'),
            }}
          />
        </Ty>
      );
    }
    return content;
  };

  const handleActionClick = async () => {
    if (bulkAction) {
      const accountIds: string[] = [];
      glAccounts.forEach((account) => {
        const subAccountIds = SalesUtilities.getAllGlAccountIds(account as GlAccount);
        accountIds.push(...subAccountIds);
      });
      const result = await updateIsActiveGlAccounts({
        isActive: !deactivate,
        isActiveAffectedAccountIds: accountIds,
      });
      if (result === true) {
        onSuccess();
        setSnackbarState({
          open: true,
          message:
            glAccounts.length === 1 ? (
              <Trans
                i18nKey={
                  !glAccounts[0].subAccounts.length && !fromActionsMenu
                    ? 'admin.glAccountConfiguration.actionContent.successMessageNoChildren'
                    : 'admin.glAccountConfiguration.actionContent.successMessageWithChildren'
                }
                values={{
                  glAccountNumber: glAccounts[0].accountNumber,
                  glAccountName: glAccounts[0].accountName,
                  action: t('admin.glAccountConfiguration.actionContent.updated'),
                }}
                components={{ primary: <Ty sx={{ fontWeight: '500' }} display='inline' /> }}
              />
            ) : (
              t('admin.glAccountConfiguration.actionContent.bulkSuccess', {
                action: t('admin.glAccountConfiguration.actionContent.updated'),
              })
            ),
          color: 'success',
          actionText: t('buttonText.view'),
          actionCallback: !fromActionsMenu ? () => history.push('/activity-log') : undefined,
        });
        setBulkActionAccountTree(undefined);
        setFromActionsMenu(false);
      }
    } else if (data) {
      handleUpdate(data);
    }
  };

  const dialogTitle = () => {
    let title = '';
    if (glAccounts.length === 1) {
      title = t('admin.glAccountConfiguration.actionContent.actionTitleWithGlAccount', {
        glAccountNumber: glAccounts[0].accountNumber,
        glAccountName: glAccounts[0].accountName,
        action: deactivate
          ? t('admin.glAccountConfiguration.actionContent.deactivateTitle')
          : t('admin.glAccountConfiguration.actionContent.activateTitle'),
      });
    } else if (glAccounts.length > 1) {
      title = t('admin.glAccountConfiguration.actionContent.actionTitleBulkActions', {
        action: deactivate
          ? t('admin.glAccountConfiguration.actionContent.deactivateTitle')
          : t('admin.glAccountConfiguration.actionContent.activateTitle'),
      });
    }
    return title;
  };

  return (
    <ActionsDialog
      open={open}
      onClose={onClose}
      dialogTitle={dialogTitle()}
      additionalDialogTitleContent={additionalDialogTitleContent()}
      additionalDialogContent={
        <GeneralLedgerAccountsDialogContent
          glAccounts={glAccounts}
          endMessage={deactivate ? t('admin.glAccountConfiguration.actionContent.existingDataInfo') : undefined}
        />
      }
      closeButtonText={t('buttonText.cancel')}
      closeButtonVariant='text'
      actionButtonText={deactivate ? t('buttonText.deactivate') : t('buttonText.activate')}
      actionButtonColor={deactivate ? 'error' : 'primary'}
      actionButtonVariant='contained'
      onActionClick={handleActionClick}
      dataTestId='gl-accounts-edit-modal'
    />
  );
}

GeneralLedgerAccountsEditDialog.defaultProps = {
  bulkAction: false,
};

export default GeneralLedgerAccountsEditDialog;
