import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { basicLogger } from 'launchdarkly-js-client-sdk';
import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LicenseInfo } from '@mui/x-license-pro';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { globalConfig } from './configuration';
import { getOktaConfig } from './oktaConfig';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE_KEY as string);

// Call initial firebase function using relative url / rewrite rules to get config
// This call must be successful for the react app to load
fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_HOST}/getFrontendConfig`, {
  headers: { 'Content-Type': 'application/json' },
  method: 'post',
  body: JSON.stringify({ data: {} }),
})
  .then((res) => res.json())
  .then(async (res) => {
    const oktaConfig = getOktaConfig(res.result.oktaClientId, res.result.oktaBaseDomain, res.result.oktaCustomDomain);
    globalConfig.set({ ...res.result, oktaConfig });
    const launchDarklyClientId = globalConfig.get()?.launchDarklyClientId || '';
    const ldLogLevel = process.env.NODE_ENV === 'development' ? 'info' : 'none';
    const LDProvider = await asyncWithLDProvider({
      clientSideID: launchDarklyClientId,
      options: {
        logger: basicLogger({ level: ldLogLevel }),
      },
    });
    return (
      <BrowserRouter>
        <React.StrictMode>
          <LDProvider>
            <App config={globalConfig.config} />
          </LDProvider>
        </React.StrictMode>
      </BrowserRouter>
    );
  })
  // eslint-disable-next-line i18next/no-literal-string
  .catch((e: string) => <p style={{ color: 'red', textAlign: 'center' }}>Error while fetching global config</p>)
  .then((reactElement: ReactElement) => {
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(reactElement);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
