import { CboRole } from '@cbo/shared-library';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import staleTimeSettings from '../../config/reactQueryConfig';
import { useSites } from '../../contexts/siteContext';
import { useUsers } from '../../contexts/userContext';
import isPermitted from '../../lib/permissions';
import useSalesRequests, {
  ProductMixEmployeeSalesDataRequest,
  ProductMixMenuGridDataRequest,
  ProductMixTop5ItemsRequest,
  ProductMixTotalSalesRequest,
  SalesSummaryDataRequest,
} from '../../sales/requests/requests';
import { yesterday } from '../reportingUtils/filterUtils';

/*
 * Hook to prefetch some report data from specific pages.
 * Utilizes react-query's 'prefetchQuery' to make a request
 * with default parameters and have it cache the results.
 */
async function usePrefetchReportData() {
  const { selectedSite } = useSites();
  const user = useUsers();
  const queryClient = useQueryClient();
  const [prefetchedOrgs, setPrefetchedOrgs] = useState<string[]>([]);

  const {
    getSalesSummaryCardData,
    getSalesSummaryGridData,
    getSalesSummaryPaymentsGridData,
    getProductMixMenuGridData,
    getProductMixTotalSales,
    getProductMixTop5Items,
    getProductMixTop5Sellers,
    getProductMixEmployeeSalesGrid,
  } = useSalesRequests();

  useEffect(() => {
    if (selectedSite.enterpriseUnitId.length > 0 && user.org?.bslId && !prefetchedOrgs.includes(user.org.bslId)) {
      const sameDateLastYear = dayjs().subtract(1, 'd').subtract(1, 'y').format('YYYY-MM-DD');
      const cacheTime = staleTimeSettings.INFINITE; // Keep historical data in cache indefinitely

      // Request objects
      // Sales Summary
      const salesSummaryRequest: SalesSummaryDataRequest = {
        startDate: yesterday,
        endDate: yesterday,
        siteIds: [selectedSite.enterpriseUnitId],
      };

      // Product Mix
      const productMixGridRequest: ProductMixMenuGridDataRequest = {
        startDate: yesterday,
        endDate: yesterday,
        categories: [],
        items: [],
        siteIds: [selectedSite.enterpriseUnitId],
      };
      const productMixTotalSalesRequest: ProductMixTotalSalesRequest = {
        startDate: yesterday,
        endDate: yesterday,
        categories: [],
        items: [],
        siteIds: [selectedSite.enterpriseUnitId],
        previousStartDate: sameDateLastYear,
        previousEndDate: sameDateLastYear,
        selectionType: 'Day',
      };
      const productMixTop5ItemsRequest: ProductMixTop5ItemsRequest = {
        startDate: yesterday,
        endDate: yesterday,
        categories: [],
        items: [],
        siteIds: [selectedSite.enterpriseUnitId],
      };
      const productMixEmployeeSalesDataRequest: ProductMixEmployeeSalesDataRequest = {
        startDate: yesterday,
        endDate: yesterday,
        siteIds: [selectedSite.enterpriseUnitId],
        categories: [],
        items: [],
        employees: [],
      };

      // Prefetch promises
      // Sales Summary
      let salesSummaryRequests: Promise<void>[] = [];
      if (isPermitted(user, [CboRole.SALES_SUMMARY_VIEW], true)) {
        const prefetchSalesSummaryCards = queryClient.prefetchQuery(
          [
            'sales-summary',
            'sales-cards',
            salesSummaryRequest.startDate,
            salesSummaryRequest.endDate,
            salesSummaryRequest.siteIds,
          ],
          () => getSalesSummaryCardData(salesSummaryRequest),
          {
            staleTime: cacheTime,
            cacheTime,
          }
        );
        const prefetchSalesSummarySalesGrid = queryClient.prefetchQuery(
          [
            'sales-summary',
            'sales-grid',
            salesSummaryRequest.startDate,
            salesSummaryRequest.endDate,
            salesSummaryRequest.siteIds,
          ],
          () => getSalesSummaryGridData(salesSummaryRequest),
          {
            staleTime: cacheTime,
            cacheTime,
          }
        );
        const prefetchSalesSummaryPaymentsGrid = queryClient.prefetchQuery(
          [
            'sales-summary',
            'payments-grid',
            salesSummaryRequest.startDate,
            salesSummaryRequest.endDate,
            salesSummaryRequest.siteIds,
          ],
          () => getSalesSummaryPaymentsGridData(salesSummaryRequest),
          {
            staleTime: cacheTime,
            cacheTime,
          }
        );
        salesSummaryRequests = [
          prefetchSalesSummaryCards,
          prefetchSalesSummarySalesGrid,
          prefetchSalesSummaryPaymentsGrid,
        ];
      }

      // Product Mix
      let pmixRequests: Promise<void>[] = [];
      if (isPermitted(user, [CboRole.PRODUCT_MIX_REPORT_VIEW], true)) {
        const prefetchPmixItems = queryClient.prefetchQuery(
          [
            'productmix',
            'top-items',
            productMixTop5ItemsRequest.startDate,
            productMixTop5ItemsRequest.endDate,
            productMixTop5ItemsRequest.siteIds,
            productMixTop5ItemsRequest.categories,
            productMixTop5ItemsRequest.items,
          ],
          () => getProductMixTop5Items(productMixTop5ItemsRequest),
          {
            staleTime: cacheTime,
            cacheTime,
          }
        );
        const prefetchPmixSales = queryClient.prefetchQuery(
          [
            'productmix',
            'total-sales',
            productMixTotalSalesRequest.startDate,
            productMixTotalSalesRequest.endDate,
            productMixTotalSalesRequest.previousStartDate,
            productMixTotalSalesRequest.previousEndDate,
            productMixTotalSalesRequest.selectionType,
            productMixTotalSalesRequest.siteIds,
            productMixTotalSalesRequest.categories,
            productMixTotalSalesRequest.items,
          ],
          () => getProductMixTotalSales(productMixTotalSalesRequest),
          {
            staleTime: cacheTime,
            cacheTime,
          }
        );
        const prefetchPmixMenuItemsGrid = queryClient.prefetchQuery(
          [
            'productmix',
            'grid-data',
            productMixGridRequest.startDate,
            productMixGridRequest.endDate,
            productMixGridRequest.siteIds,
            productMixGridRequest.categories,
            productMixGridRequest.items,
          ],
          () => getProductMixMenuGridData(productMixGridRequest),
          {
            staleTime: cacheTime,
            cacheTime,
          }
        );

        const prefetchPmixSellers = queryClient.prefetchQuery(
          [
            'productmix',
            'top-sellers',
            productMixEmployeeSalesDataRequest.startDate,
            productMixEmployeeSalesDataRequest.endDate,
            productMixEmployeeSalesDataRequest.siteIds,
            productMixEmployeeSalesDataRequest.categories,
            productMixEmployeeSalesDataRequest.items,
            null,
          ],
          () => getProductMixTop5Sellers(productMixEmployeeSalesDataRequest),
          {
            staleTime: cacheTime,
            cacheTime,
          }
        );
        const prefetchPmixEmployeeSalesGrid = queryClient.prefetchQuery(
          [
            'productmix',
            'employee-sales-grid-data',
            productMixEmployeeSalesDataRequest.startDate,
            productMixEmployeeSalesDataRequest.endDate,
            productMixEmployeeSalesDataRequest.siteIds,
            productMixEmployeeSalesDataRequest.categories,
            productMixEmployeeSalesDataRequest.items,
            productMixEmployeeSalesDataRequest.employees,
          ],
          () => getProductMixEmployeeSalesGrid(productMixEmployeeSalesDataRequest),
          {
            staleTime: cacheTime,
            cacheTime,
          }
        );
        pmixRequests = [
          prefetchPmixItems,
          prefetchPmixSales,
          prefetchPmixMenuItemsGrid,
          prefetchPmixSellers,
          prefetchPmixEmployeeSalesGrid,
        ];
      }

      Promise.all([...salesSummaryRequests, ...pmixRequests]);
      setPrefetchedOrgs([...prefetchedOrgs, user.org.bslId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite.enterpriseUnitId, user.org, user.bslAuth?.roles]);
}

export default usePrefetchReportData;
