import DomainIcon from '@mui/icons-material/Domain';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanIcon from '@mui/icons-material/Lan';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrgContext } from '../CommonComponentWrapper';
import DialogListItemActionButton from './DialogListItemActionButton';
import { useOrganizationDialogContext } from './OrganizationDialogProvider';
import OrganizationHierarchyCollapse from './OrganizationHierarchyCollapse';

function OrganizationAllOrgsList() {
  const { t } = useTranslation();
  const { userOrganizations, organization } = useOrgContext();
  const { handleOrganizationClick, searchValue, expandedOrg, handleExpandOrgClick } = useOrganizationDialogContext();

  const filteredOrgs = userOrganizations.filter(
    (org) => org.organizationName.includes(searchValue) || org.children.some((child) => child.includes(searchValue))
  );

  if (filteredOrgs.length === 0) {
    return (
      <Box sx={{ px: '24px' }}>
        <Typography variant='body1'>{t('mockOrgSwitcher.dialog.noResults')}</Typography>
      </Box>
    );
  }

  return (
    <List>
      {filteredOrgs.map((org) => {
        const orgName = org.organizationName;
        const isExpanded = expandedOrg.has(org.id);
        const expandTitle = isExpanded ? 'collapseOrg' : 'expandOrg';

        return (
          <Fragment key={`${orgName}`}>
            <DialogListItemActionButton
              selected={orgName === organization?.organizationName}
              onListItemButtonClick={() => handleOrganizationClick(orgName)}
              secondaryAction={
                Boolean(org.children.length) && (
                  <IconButton
                    title={t(`mockOrgSwitcher.accessibility.${expandTitle}`)}
                    size='small'
                    onClick={() => handleExpandOrgClick(org.id)}
                  >
                    {isExpanded ? <ExpandLessIcon fontSize='inherit' /> : <ExpandMoreIcon fontSize='inherit' />}
                  </IconButton>
                )
              }
              sx={{
                px: 0,
                py: 0,
                '.MuiListItemSecondaryAction-root': {
                  right: '8px',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: '0',
                  marginRight: '16px',
                }}
              >
                {org.children.length ? <LanIcon /> : <DomainIcon />}
              </ListItemIcon>
              <Typography variant='body1'>{orgName}</Typography>
            </DialogListItemActionButton>

            {Boolean(org.children) && <OrganizationHierarchyCollapse organization={org} />}
          </Fragment>
        );
      })}
    </List>
  );
}

export default OrganizationAllOrgsList;
