/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */

import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Control, Controller, ControllerProps } from 'react-hook-form';
import { useTheme } from '@mui/material';

export type FormMultiSelectProps = {
  name: string;
  options: any[];
  loading?: boolean; // TODO
  control?: Control<any>;
  validation?: ControllerProps['rules'];
  required?: boolean;
  label?: string;
  dataTestId?: string;
  placeholder?: string;
  disabled?: boolean;
};

function FormMultiSelect({
  name,
  options,
  loading,
  control,
  required = false,
  validation = {},
  label = '',
  dataTestId = `muiSelect-${name}`,
  placeholder,
  disabled = false,
}: FormMultiSelectProps): JSX.Element {
  const theme = useTheme();
  if (required && !validation.required) {
    // eslint-disable-next-line no-param-reassign
    validation.required = 'This field is required';
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field: { onChange, value, ref, ...fieldRest }, fieldState: { error } }) => (
        <FormControl variant='standard' style={{ width: '100%' }} disabled={disabled}>
          <InputLabel variant='standard' id={`${label}-select-label`}>
            {label}
          </InputLabel>
          <Select
            labelId={`${label}-select`}
            displayEmpty
            style={{ width: '100%' }}
            multiple
            value={value}
            required={required}
            onChange={onChange}
            data-testid={dataTestId}
            ref={ref}
            name={`${name}-select`}
            renderValue={(selected) => {
              if (selected?.length === 0 && placeholder) {
                return <Typography color={theme.palette.secondary.light}>{placeholder}</Typography>;
              }
              return selected?.join(', ');
            }}
            MenuProps={{ sx: { maxHeight: 320 } }}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option} data-testid={`${name}-${option}`}>
                <Checkbox checked={value?.includes(option)} />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}

export default FormMultiSelect;
