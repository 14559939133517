import { Req } from '@cbo/shared-library';
import { ActivationTypes, ActivationTypes as EmploymentStatus } from '@cbo/shared-library/types/activation';
import { useErrorHandler } from 'react-error-boundary';
import { useSites } from '../../contexts/siteContext';
import useCallBslAndHandleErrors from '../../lib/firebaseApiHandleErrors';
import { Paginated } from '../../models/Paginated';
import {
  AddPayRateRequestBody,
  ContactInformation,
  EmergencyContactInformation,
  Employee,
  EmployeeCertification,
  EmployeeConfigurationsResponse,
  EmployeeJobCodeAssignment,
  JobCodeDefinition,
  PatchPayRateRequestBody,
  PayRate,
  PersonalInformation,
} from '../services/employeeService';
import {
  ApproachingACAHourlyThresholdGridDataRequest,
  ApproachingACAHourlyThresholdGridDataResponse,
  ContactInfoUpdateRequestSchema,
  EmployeeBreakReportCommonRequestSchema,
  EmployeeBreakReportGridDataResponse,
  EmployeeBreakReportKpiCardsDataResponse,
  EmployeeRequestSchema,
  EmployeeSalesPerformanceCommonRequest,
  EmployeeSalesPerformanceGridDataResponse,
  EmployeeSalesPerformanceKpiCardsResponse,
  EmployeeSelfRequestSchema,
  GenericPayrollExportGridDataRequest,
  GenericPayrollExportGridDataResponse,
  IntervalSalesAndLaborAverageSalesResponse,
  IntervalSalesAndLaborGridDataResponse,
  IntervalSalesAndLaborKPIResponse,
  IntervalSalesAndLaborReportCommonRequestSchema,
  JobcodeHistoryGridRequest,
  JobcodeHistoryGridResponse,
  LaborGroupSchema,
  OvertimeThresholdCommonRequest,
  OvertimeThresholdGridDataResponse,
  OvertimeThresholdKpiCardsResponse,
  AcaDashboardKPICardsRequest,
  AcaDashboardKPICardsResponse,
  UpdateEmergencyContactRequestSchema,
  UpdateEmployeeCertificationSchema,
  UpdateEmployeeConfigurationSchema,
  UpdateEmployeeJobCodeAssignmentSchema,
  UpdateEmployeePermissionsSchema,
  UpdateEmployeeRequestSchema,
  UpdateJobcodeSchema,
  UpdatePersonalInfoSchema,
  UpdateResponse,
  ApproachingACAThresholdAverageHoursWorkedGridResponse,
  EmployeeIdByPosEmployeeIdResponse,
  EmployeePayrollDto,
  EmployeePayrollGridResponse,
  EmployeePayrollDetailDataGridResponse,
  EmployeePayrollKPICardData,
  EmployeeTipReportGridDataResponse,
} from '../types';
import { BaseReportDataRequest } from '../../sales/requests/requests';

export type LaborRequests = {
  deactivateEmployeeJobcode: (jobCodeId: string, employeeConfigId: string) => Promise<string | void>;
  getEmployeeConfigurations: () => Promise<EmployeeConfigurationsResponse[] | void>;
  getEmployeesByEmpStatus: (activationStatus: EmploymentStatus) => Promise<EmployeeConfigurationsResponse[] | void>;
  getEmployee: (employeeId: string) => Promise<Employee | void>;
  getSelfEmployee: () => Promise<Employee | void>;
  createSelfEmployee: (employee: EmployeeSelfRequestSchema) => Promise<Employee | void>;
  updateEmployee: (
    employeeId: string,
    employee: EmployeeRequestSchema,
    errorMessageMapper?: Record<string, string>
  ) => Promise<UpdateResponse | void>;
  updateSelfEmployee: (updateEmployeeDto: UpdateEmployeeRequestSchema) => Promise<UpdateResponse | void>;
  getLaborGroups: () => Promise<Paginated<LaborGroupSchema> | void>;
  getLaborGroupById: (laborGroupId: string) => Promise<LaborGroupSchema | void>;
  updateLaborGroupToJobCodeMapping: (laborGroupId: string, jobCodeDefinitionId: string) => Promise<string | void>;
  deleteLaborGroupToJobCodeMapping: (laborGroupId: string, jobCodeDefinitionId: string) => Promise<string | void>;
  getJobCodes: () => Promise<JobCodeDefinition[] | void>;
  getJobCodesByStatus: (activationStatus: ActivationTypes) => Promise<JobCodeDefinition[] | void>;
  getJobcodeById: (jobcodeId: string) => Promise<JobCodeDefinition | void>;
  updateJobcode: (jobcodeId: string, updateJobcodeDto: UpdateJobcodeSchema) => Promise<UpdateResponse | void>;
  getEmployeeJobcodeAssignments: (employeeConfigId: string) => Promise<EmployeeJobCodeAssignment[] | void>;
  updateEmployeeJobcodeAssignment: (
    employeeJobCodeAssignmentId: string,
    employeeJobCodeAssignment: UpdateEmployeeJobCodeAssignmentSchema
  ) => Promise<UpdateResponse | void>;
  getJobcodeHistoryGridData: (body: JobcodeHistoryGridRequest) => Promise<JobcodeHistoryGridResponse | void>;
  updateEmployeeConfiguration: (
    employeeId: string,
    updateEmployeeConfigurationDto: UpdateEmployeeConfigurationSchema
  ) => Promise<UpdateResponse | void>;
  updatePermissions: (
    employeeId: string,
    updateEmployeeConfigurationDto: UpdateEmployeePermissionsSchema
  ) => Promise<UpdateResponse | void>;
  getEmployeeConfiguration: (employeeConfigurationId: string) => Promise<EmployeeConfigurationsResponse | void>;
  createSelfEmploymentConfiguration: (enterpriseUnitId: string) => Promise<Employee | void>;
  getEmployeeConfigurationByEmployeeId: (
    employeeId: string,
    loadEmployeeInfo: boolean
  ) => Promise<EmployeeConfigurationsResponse | void>;
  getEmployeeIdByPosEmployeeId: (posEmployeeId: string) => Promise<EmployeeIdByPosEmployeeIdResponse | void>;
  getContactInfoByEmployeeId: (employeeId: string) => Promise<ContactInformation | void>;
  getBatchPayRatesByEmployeeJobcodes: (
    employeeConfigurationId: string,
    jobcodeIds: string[]
  ) => Promise<PayRate[][] | void>;
  getBatchPayRatesAssignedToJobcodeEmployees: (
    jobcodes: JobCodeDefinition[]
  ) => Promise<{ [jobcodeId: string]: { [employeeId: string]: PayRate[] } } | void>;
  createPayRateForEmployeeConfigJobCode: (
    jobCodeId: string,
    employeeConfigurationId: string,
    payRateData: AddPayRateRequestBody
  ) => Promise<string | void>;
  patchPayRate: (payRateId: string, body: PatchPayRateRequestBody) => Promise<string | void>;
  getSelfContactInformation: () => Promise<ContactInformation | void>;
  updateSelfContactInformation: (contactInfo: ContactInformation) => Promise<UpdateResponse | void>;
  getSelfPersonalInformation: () => Promise<PersonalInformation | void>;
  updateSelfPersonalInformation: (personalInfo: UpdatePersonalInfoSchema) => Promise<UpdateResponse | void>;
  getSelfEmergencyContact: () => Promise<EmergencyContactInformation | void>;
  updateSelfEmergencyContact: (emergencyContact: UpdateEmergencyContactRequestSchema) => Promise<UpdateResponse | void>;
  getSelfCertifications: () => Promise<EmployeeCertification[] | void>;
  updateSelfCertification: (certification: UpdateEmployeeCertificationSchema) => Promise<UpdateResponse | void>;
  updateContactInformation: (
    employeeId: string,
    contactInformation: ContactInfoUpdateRequestSchema
  ) => Promise<UpdateResponse | void>;
  updatePersonalInformation: (
    employeeId: string,
    personalInformation: UpdatePersonalInfoSchema
  ) => Promise<UpdateResponse | void>;
  updateEmergencyContacts: (
    employeeId: string,
    emergencyContacts: UpdateEmergencyContactRequestSchema
  ) => Promise<UpdateResponse | void>;
  updateCertifications: (
    employeeId: string,
    certificationInformation: UpdateEmployeeCertificationSchema
  ) => Promise<UpdateResponse | void>;
  getGenericPayrollExportGridData: (
    body: GenericPayrollExportGridDataRequest
  ) => Promise<GenericPayrollExportGridDataResponse[] | void>;
  getOvertimeThresholdReportGridData: (
    body: OvertimeThresholdCommonRequest
  ) => Promise<OvertimeThresholdGridDataResponse | void>;
  getOvertimeThresholdReportKpiCardsData: (
    body: OvertimeThresholdCommonRequest
  ) => Promise<OvertimeThresholdKpiCardsResponse | void>;
  getEmployeeSalesPerformanceReportGridData: (
    body: EmployeeSalesPerformanceCommonRequest
  ) => Promise<EmployeeSalesPerformanceGridDataResponse | void>;
  getEmployeeSalesPerformanceReportKpiCardsData: (
    body: EmployeeSalesPerformanceCommonRequest
  ) => Promise<EmployeeSalesPerformanceKpiCardsResponse | void>;
  getAcaThresholdKpiCardsData: (body: AcaDashboardKPICardsRequest) => Promise<AcaDashboardKPICardsResponse | void>;
  getEmployeeTipReportGridData: (
    body: EmployeeBreakReportCommonRequestSchema
  ) => Promise<EmployeeTipReportGridDataResponse | void>;
  getEmployeeBreakReportGridData: (
    body: EmployeeBreakReportCommonRequestSchema
  ) => Promise<EmployeeBreakReportGridDataResponse | void>;
  getEmployeeBreakReportKpiCardsData: (
    body: EmployeeBreakReportCommonRequestSchema
  ) => Promise<EmployeeBreakReportKpiCardsDataResponse | void>;
  getIntervalSalesAndLaborGridData: (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ) => Promise<IntervalSalesAndLaborGridDataResponse | void>;
  getIntervalSalesAndLaborKPICards: (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ) => Promise<IntervalSalesAndLaborKPIResponse | void>;
  getIntervalSalesAndLaborAverageSales: (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ) => Promise<IntervalSalesAndLaborAverageSalesResponse | void>;
  getApproachingACAHourlyThresholdGridData: (
    body: ApproachingACAHourlyThresholdGridDataRequest
  ) => Promise<ApproachingACAHourlyThresholdGridDataResponse | void>;
  getAcaAverageHoursGridData: (
    body: BaseReportDataRequest
  ) => Promise<ApproachingACAThresholdAverageHoursWorkedGridResponse | void>;
  getEmployeePayrollGridData: (body: EmployeePayrollDto) => Promise<EmployeePayrollGridResponse | void>;
  getEmployeePayrollKPICardsData: (body: EmployeePayrollDto) => Promise<EmployeePayrollKPICardData | void>;
  getEmployeePayrollDetailGridData: (body: EmployeePayrollDto) => Promise<EmployeePayrollDetailDataGridResponse | void>;
};

const useLaborRequests = (): LaborRequests => {
  const handleError = useErrorHandler();
  const callBslAndHandleErrors = useCallBslAndHandleErrors();
  const { selectedSite } = useSites();

  const proxies = {
    info: 'employees',
    config: 'employee-configuration',
    sales: 'sales',
  };

  const Verb = Req.Firebase.HttpVerb;

  /**
   * EMPLOYEE
   */

  const getEmployee = async (employeeId: string): Promise<Employee | void> =>
    callBslAndHandleErrors(
      {
        proxy: 'employees',
        verb: Verb.GET,
        pathSegments: ['employees', employeeId],
      },
      handleError
    );

  const createSelfEmployee = async (employeeInfo: EmployeeSelfRequestSchema): Promise<Employee | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.info,
        pathSegments: ['employees', 'self'],
        payload: employeeInfo,
      },
      handleError
    );

  const createSelfEmploymentConfiguration = async (enterpriseUnitId: string): Promise<Employee | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.info,
        pathSegments: ['employees', 'euid', 'self'],
        enterpriseUnit: enterpriseUnitId,
      },
      handleError
    );

  const getSelfEmployee = async (): Promise<Employee | void> =>
    callBslAndHandleErrors(
      {
        proxy: 'employees',
        verb: Verb.GET,
        pathSegments: ['employees', 'self'],
      },
      handleError
    );

  const updateEmployee = async (
    employeeId: string,
    employee: EmployeeRequestSchema,
    errorMessageMapper?: Record<string, string>
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.info,
        pathSegments: ['employees'],
        payload: employee,
        queryParams: [{ kind: 'single', key: 'id', value: employeeId }],
      },
      handleError,
      errorMessageMapper
    );

  const updateContactInformation = async (
    employeeId: string,
    contactInformation: ContactInfoUpdateRequestSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.info,
        pathSegments: ['contact-information', `${employeeId}`],
        payload: contactInformation,
      },
      handleError
    );

  const updatePersonalInformation = async (
    employeeId: string,
    personalInformation: UpdatePersonalInfoSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.info,
        pathSegments: ['personal-information', `${employeeId}`],
        payload: personalInformation,
      },
      handleError
    );

  const updateEmergencyContacts = async (
    employeeId: string,
    emergencyContacts: UpdateEmergencyContactRequestSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.info,
        pathSegments: ['emergency-contacts', `${employeeId}`],
        payload: emergencyContacts,
      },
      handleError
    );

  const updateSelfEmployee = async (updateEmployeeDto: UpdateEmployeeRequestSchema): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.PATCH, proxy: proxies.info, pathSegments: ['employees', 'self'], payload: updateEmployeeDto },
      handleError
    );

  /**
   * EMPLOYEE CONFIG
   */

  const getEmployeeConfigurations = async (): Promise<EmployeeConfigurationsResponse[] | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.config,
        pathSegments: ['employee-configurations', 'not-paginated'],
        verb: Verb.GET,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getEmployeesByEmpStatus = async (
    activationStatus: EmploymentStatus
  ): Promise<EmployeeConfigurationsResponse[] | void> => {
    if (selectedSite.enterpriseUnitId) {
      return callBslAndHandleErrors(
        {
          verb: Verb.GET,
          proxy: proxies.config,
          pathSegments: ['employee-configurations', 'not-paginated'],
          queryParams: [
            { kind: 'single', key: 'filterByColumns[]', value: 'activationStatus' },
            { kind: 'single', key: 'filterByKeyword', value: activationStatus },
          ],
          enterpriseUnit: selectedSite.enterpriseUnitId,
        },
        handleError
      );
    }
    return [];
  };

  const updateEmployeeConfiguration = async (
    employeeConfigurationId: string,
    updateEmployeeConfigurationDto: UpdateEmployeeConfigurationSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.config,
        pathSegments: ['employee-configurations', employeeConfigurationId],
        payload: updateEmployeeConfigurationDto,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const updatePermissions = async (
    employeeConfigurationId: string,
    updateEmployeeConfigurationDto: UpdateEmployeePermissionsSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.config,
        pathSegments: ['employee-configurations', employeeConfigurationId],
        payload: updateEmployeeConfigurationDto,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getEmployeeConfiguration = async (
    employeeConfigurationId: string
  ): Promise<EmployeeConfigurationsResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['employee-configurations', employeeConfigurationId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getEmployeeConfigurationByEmployeeId = async (
    employeeId: string,
    loadEmployeeInfo = false
  ): Promise<EmployeeConfigurationsResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['employee-configurations', 'employeeId', employeeId],
        queryParams: [{ kind: 'single', key: 'loadEmployeeInfo', value: loadEmployeeInfo.toString() }],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getEmployeeIdByPosEmployeeId = async (
    posEmployeeId: string
  ): Promise<EmployeeIdByPosEmployeeIdResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['employee-configurations', 'id-by-pos-emp-id', posEmployeeId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getContactInfoByEmployeeId = async (employeeId: string): Promise<ContactInformation | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.info,
        pathSegments: ['contact-information', employeeId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getBatchPayRatesByEmployeeJobcodes = async (
    employeeConfigurationId: string,
    jobcodeIds: string[]
  ): Promise<PayRate[][] | void> => {
    const payRates = await Promise.all(
      jobcodeIds.flatMap(
        async (jobcodeId) =>
          callBslAndHandleErrors(
            {
              verb: Verb.GET,
              proxy: proxies.config,
              pathSegments: [
                'employee-configurations',
                employeeConfigurationId,
                'job-code-definitions',
                jobcodeId,
                'pay-rates',
              ],
              enterpriseUnit: selectedSite.enterpriseUnitId,
            },
            handleError
          ) as unknown as PayRate[]
      )
    );
    return payRates;
  };

  const getBatchPayRatesAssignedToJobcodeEmployees = async (
    jobcodes: JobCodeDefinition[]
  ): Promise<{ [jobcodeId: string]: { [employeeId: string]: PayRate[] } } | void> => {
    const result = await Promise.all(
      jobcodes.flatMap(async (jobcode) => {
        if (jobcode.employees) {
          const employeePayRates = await Promise.all(
            jobcode.employees.flatMap(async (employee) => {
              const payRates = (await callBslAndHandleErrors(
                {
                  verb: Verb.GET,
                  proxy: proxies.config,
                  pathSegments: [
                    'employee-configurations',
                    employee.id,
                    'job-code-definitions',
                    jobcode.id,
                    'pay-rates',
                  ],
                  enterpriseUnit: selectedSite.enterpriseUnitId,
                },
                handleError
              )) as unknown as PayRate[];

              return { [employee.id]: payRates };
            })
          );

          return { [jobcode.id]: employeePayRates.reduce((acc, curr) => ({ ...acc, ...curr }), {}) };
        }
        return {};
      })
    );
    return result.reduce((acc, curr) => ({ ...acc, ...curr }), {});
  };

  const createPayRateForEmployeeConfigJobCode = async (
    jobCodeId: string,
    employeeConfigurationId: string,
    payRateData: AddPayRateRequestBody
  ): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.config,
        pathSegments: [
          'job-code-definitions',
          jobCodeId,
          'employee-configurations',
          employeeConfigurationId,
          'pay-rate',
        ],
        payload: payRateData,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  /**
   * PAY RATES
   */
  const patchPayRate = async (payRateId: string, body: PatchPayRateRequestBody): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.config,
        pathSegments: ['pay-rates', payRateId],
        payload: body,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  // Deactivate a job code for a specific employee.
  const deactivateEmployeeJobcode = async (jobCodeId: string, employeeConfigId: string): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.DELETE,
        proxy: proxies.config,
        pathSegments: ['job-code-definitions', jobCodeId, 'employee-configurations', employeeConfigId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getLaborGroups = (): Promise<Paginated<LaborGroupSchema> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['labor-groups'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getLaborGroupById = (laborGroupId: string): Promise<LaborGroupSchema | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['labor-groups', laborGroupId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const updateLaborGroupToJobCodeMapping = (
    laborGroupId: string,
    jobCodeDefinitionId: string
  ): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.config,
        pathSegments: ['labor-groups', laborGroupId, 'job-code-definitions', jobCodeDefinitionId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const deleteLaborGroupToJobCodeMapping = (
    laborGroupId: string,
    jobCodeDefinitionId: string
  ): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.DELETE,
        proxy: proxies.config,
        pathSegments: ['labor-groups', laborGroupId, 'job-code-definitions', jobCodeDefinitionId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  /**
   * JOB CODES
   */

  const getJobCodes = async (): Promise<JobCodeDefinition[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['job-code-definitions', 'not-paginated'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getJobCodesByStatus = async (activationStatus: ActivationTypes): Promise<JobCodeDefinition[] | void> => {
    if (selectedSite.enterpriseUnitId) {
      return callBslAndHandleErrors(
        {
          verb: Verb.GET,
          proxy: proxies.config,
          pathSegments: ['job-code-definitions', 'not-paginated'],
          queryParams: [
            { kind: 'single', key: 'filterByColumns[]', value: 'activationStatus' },
            { kind: 'single', key: 'filterByKeyword', value: activationStatus.toString() },
          ],
          enterpriseUnit: selectedSite.enterpriseUnitId,
        },
        handleError
      );
    }
    return [];
  };
  const getJobcodeById = (jobcodeId: string): Promise<JobCodeDefinition | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['job-code-definitions', jobcodeId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const updateJobcode = (jobcodeId: string, updateJobcodeDto: UpdateJobcodeSchema): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.config,
        pathSegments: ['job-code-definitions', jobcodeId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
        payload: updateJobcodeDto,
      },
      handleError
    );

  const getEmployeeJobcodeAssignments = (employeeConfigId: string): Promise<EmployeeJobCodeAssignment[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['employee-job-code-assignment'],
        queryParams: [
          { kind: 'single', key: 'filterByColumns[]', value: 'employeeId' },
          { kind: 'single', key: 'filterByKeyword', value: employeeConfigId },
        ],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const updateEmployeeJobcodeAssignment = (
    employeeJobCodeAssignmentId: string,
    employeeJobCodeAssignment: UpdateEmployeeJobCodeAssignmentSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.config,
        pathSegments: ['employee-job-code-assignment', employeeJobCodeAssignmentId],
        payload: employeeJobCodeAssignment,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getJobcodeHistoryGridData = (body: JobcodeHistoryGridRequest): Promise<JobcodeHistoryGridResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['jobcode-history', 'grid-data'],
        payload: body,
      },
      handleError
    );

  /**
   * CONTACT INFORMATION
   */

  const getSelfContactInformation = async (): Promise<ContactInformation | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.info,
        pathSegments: ['contact-information', 'self'],
      },
      handleError
    );

  const updateSelfContactInformation = async (contactInfo: ContactInformation): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.PATCH, proxy: proxies.info, pathSegments: ['contact-information', 'self'], payload: contactInfo },
      handleError
    );

  /**
   * PERSONAL INFORMATION
   */

  const getSelfPersonalInformation = async (): Promise<PersonalInformation | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.info,
        pathSegments: ['personal-information', 'self'],
      },
      handleError
    );

  const updateSelfPersonalInformation = async (
    personalInfo: UpdatePersonalInfoSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.PATCH, proxy: proxies.info, pathSegments: ['personal-information', 'self'], payload: personalInfo },
      handleError
    );

  /**
   * EMERGENCY CONTACT
   */

  const getSelfEmergencyContact = async (): Promise<EmergencyContactInformation | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.info,
        pathSegments: ['emergency-contacts', 'self'],
      },
      handleError
    );

  const updateSelfEmergencyContact = async (
    emergencyContact: UpdateEmergencyContactRequestSchema | null
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.info,
        pathSegments: ['emergency-contacts', 'self'],
        payload: emergencyContact,
      },
      handleError
    );

  /**
   * CERTIFICATIONS
   */

  const getSelfCertifications = async (): Promise<EmployeeCertification[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.info,
        pathSegments: ['certifications', 'self'],
      },
      handleError
    );

  const updateCertifications = async (
    employeeId: string,
    updateCertificationsDTO: UpdateEmployeeCertificationSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.info,
        pathSegments: ['certifications', `${employeeId}`],
        payload: updateCertificationsDTO,
      },
      handleError
    );

  const updateSelfCertification = async (
    certification: UpdateEmployeeCertificationSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.PATCH, proxy: proxies.info, pathSegments: ['certifications', 'self'], payload: certification },
      handleError
    );

  /**
   * EMPLOYEE SALES PERFORMANCE
   */

  const getEmployeeSalesPerformanceReportGridData = async (
    body: EmployeeSalesPerformanceCommonRequest
  ): Promise<EmployeeSalesPerformanceGridDataResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['employee-sales', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getEmployeeSalesPerformanceReportKpiCardsData = async (
    body: EmployeeSalesPerformanceCommonRequest
  ): Promise<EmployeeSalesPerformanceKpiCardsResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['employee-sales', 'kpi-cards'],
        payload: body,
      },
      handleError
    );

  /**
   * APPROACHING OT THRESHOLD
   */

  const getOvertimeThresholdReportGridData = async (
    body: OvertimeThresholdCommonRequest
  ): Promise<OvertimeThresholdGridDataResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['approaching-overtime-threshold', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getOvertimeThresholdReportKpiCardsData = async (
    body: OvertimeThresholdCommonRequest
  ): Promise<OvertimeThresholdKpiCardsResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['approaching-overtime-threshold', 'kpi-cards'],
        payload: body,
      },
      handleError
    );

  /**
   * GENERIC PAYROLL EXPORT
   */
  const getGenericPayrollExportGridData = async (
    body: GenericPayrollExportGridDataRequest
  ): Promise<GenericPayrollExportGridDataResponse[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['generic-payroll-export'],
        payload: body,
      },
      handleError
    );

  /**
   * EMPLOYEE TIP REPORT
   */

  const getEmployeeTipReportGridData = async (
    body: EmployeeBreakReportCommonRequestSchema
  ): Promise<EmployeeTipReportGridDataResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['employee-tip', 'grid-data'], payload: body },
      handleError
    );

  /**
   * EMPLOYEE BREAK REPORT
   */
  const getEmployeeBreakReportGridData = async (
    body: EmployeeBreakReportCommonRequestSchema
  ): Promise<EmployeeBreakReportGridDataResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['employee-break', 'grid-data'], payload: body },
      handleError
    );

  const getEmployeeBreakReportKpiCardsData = async (
    body: EmployeeBreakReportCommonRequestSchema
  ): Promise<EmployeeBreakReportKpiCardsDataResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['employee-break', 'kpi-cards'], payload: body },
      handleError
    );

  /**
   * INTERVAL SALES AND LABOR REPORT
   */

  const getIntervalSalesAndLaborGridData = async (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ): Promise<IntervalSalesAndLaborGridDataResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['interval-sales-labor', 'grid-data'], payload: body },
      handleError
    );

  const getIntervalSalesAndLaborKPICards = async (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ): Promise<IntervalSalesAndLaborKPIResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['interval-sales-labor', 'kpi-cards'], payload: body },
      handleError
    );

  const getIntervalSalesAndLaborAverageSales = async (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ): Promise<IntervalSalesAndLaborAverageSalesResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['interval-sales-labor', 'average-sales'], payload: body },
      handleError
    );

  /**
   * APPROACHING ACA THRESHOLD
   */

  const getAcaThresholdKpiCardsData = async (
    body: AcaDashboardKPICardsRequest
  ): Promise<AcaDashboardKPICardsResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['approaching-aca-threshold', 'dashboard', 'kpi-cards'],
        payload: body,
      },
      handleError
    );

  const getApproachingACAHourlyThresholdGridData = async (
    body: ApproachingACAHourlyThresholdGridDataRequest
  ): Promise<ApproachingACAHourlyThresholdGridDataResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['approaching-aca-threshold', 'hourly-threshold', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getAcaAverageHoursGridData = async (
    body: BaseReportDataRequest
  ): Promise<ApproachingACAThresholdAverageHoursWorkedGridResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['approaching-aca-threshold', 'employee-average', 'grid-data'],
        payload: body,
      },
      handleError
    );

  /**
   * Employee Payroll Report
   */

  const getEmployeePayrollDetailGridData = async (
    body: EmployeePayrollDto
  ): Promise<EmployeePayrollDetailDataGridResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['employee-payroll', 'detail', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getEmployeePayrollKPICardsData = async (body: EmployeePayrollDto): Promise<EmployeePayrollKPICardData | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['employee-payroll', 'kpi-cards'],
        payload: body,
      },
      handleError
    );

  const getEmployeePayrollGridData = async (body: EmployeePayrollDto): Promise<EmployeePayrollGridResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['employee-payroll', 'summary', 'grid-data'],
        payload: body,
      },
      handleError
    );

  return {
    deactivateEmployeeJobcode,
    getEmployeeConfigurations,
    getEmployeesByEmpStatus,
    getEmployee,
    createSelfEmployee,
    getSelfEmployee,
    updateEmployee,
    updateSelfEmployee,
    getLaborGroups,
    getLaborGroupById,
    updateLaborGroupToJobCodeMapping,
    deleteLaborGroupToJobCodeMapping,
    getJobCodes,
    getJobCodesByStatus,
    getJobcodeById,
    updateJobcode,
    getEmployeeJobcodeAssignments,
    updateEmployeeJobcodeAssignment,
    getJobcodeHistoryGridData,
    updateEmployeeConfiguration,
    updatePermissions,
    createSelfEmploymentConfiguration,
    getEmployeeConfiguration,
    getEmployeeConfigurationByEmployeeId,
    getEmployeeIdByPosEmployeeId,
    getContactInfoByEmployeeId,
    createPayRateForEmployeeConfigJobCode,
    patchPayRate,
    getBatchPayRatesByEmployeeJobcodes,
    getBatchPayRatesAssignedToJobcodeEmployees,
    getSelfContactInformation,
    updateSelfContactInformation,
    getSelfPersonalInformation,
    updateSelfPersonalInformation,
    getSelfEmergencyContact,
    updateSelfEmergencyContact,
    getSelfCertifications,
    updateSelfCertification,
    updateContactInformation,
    updateEmergencyContacts,
    updatePersonalInformation,
    updateCertifications,
    getGenericPayrollExportGridData,
    getOvertimeThresholdReportGridData,
    getOvertimeThresholdReportKpiCardsData,
    getEmployeeTipReportGridData,
    getEmployeeBreakReportGridData,
    getAcaThresholdKpiCardsData,
    getEmployeeBreakReportKpiCardsData,
    getIntervalSalesAndLaborGridData,
    getIntervalSalesAndLaborKPICards,
    getIntervalSalesAndLaborAverageSales,
    getEmployeeSalesPerformanceReportGridData,
    getEmployeeSalesPerformanceReportKpiCardsData,
    getApproachingACAHourlyThresholdGridData,
    getAcaAverageHoursGridData,
    getEmployeePayrollDetailGridData,
    getEmployeePayrollKPICardsData,
    getEmployeePayrollGridData,
  };
};

export default useLaborRequests;
