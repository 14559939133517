import { Controller, FieldValues } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';

export default function FormCheckbox(dataTestId: string, formInfo: FieldValues, redOnError = false): JSX.Element {
  const theme = useTheme();
  const { name, validation, required, parseError, label, control, helperText, gridProps, ...rest } = formInfo;

  return (
    <Grid item marginTop={1} key={name} {...gridProps}>
      <Controller
        name={name}
        rules={validation}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormControl required={required} error={!!error}>
            <FormGroup row>
              <FormControlLabel
                /**
                 * setting { display: 'table' } and { display: 'table-cell' } on Checkbox
                 * to align checkboxes on top with multiline labels
                 */
                style={{ display: 'table' }}
                label={label || ''}
                control={
                  <div style={{ display: 'table-cell' }}>
                    <Checkbox
                      {...rest}
                      checked={!!value}
                      value={value}
                      aria-label={`${name} checkbox`}
                      sx={{
                        color: redOnError && !!error ? theme.palette.error.main : theme.palette.grey[500],
                      }}
                      data-testid={dataTestId}
                      onChange={(ev) => {
                        onChange(!value);
                        if (typeof rest.onChange === 'function') {
                          rest.onChange(ev, !value);
                        }
                      }}
                    />
                  </div>
                }
              />
            </FormGroup>
          </FormControl>
        )}
      />
    </Grid>
  );
}
