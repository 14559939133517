import { TFunction, useTranslation } from 'react-i18next';
import Edit from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { DataGridPremiumProps, GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import DataGrid, { BaseDataGridProps } from '../../components/DataGrid/DataGrid';
import { LaborRuleConfigData, LaborRuleGroup, statesWithGrouping } from '../models/LaborRuleConfiguration';
import { enrichSiteRows } from '../utils/utils';

export interface JurisdictionSitesRow {
  [x: string]: unknown;
  id: string;
  hierarchy: string[];
}

interface JurisdictionSitesDataGridProps extends Partial<BaseDataGridProps> {
  sites: JurisdictionSitesRow[];
  getRowHeight?: () => number;
  jurisdictionPath: string;
  ruleGroup: LaborRuleGroup;
  allRules?: LaborRuleConfigData[];
  editModeVariant?: boolean;
  onEditRule?: (stateOverrides?: Record<string, unknown>) => void;
}

const createColumn = (field: string, headerName: string) => ({
  field,
  headerName,
  minWidth: 120,
  flex: 1,
});

const defaultColumns = ['siteName', 'referenceId', 'configuration', 'effectiveDate', 'effectiveUntil', 'changeReason'];
const editModeColumns = ['state', 'siteName', 'referenceId', 'configuration'];

// TODO figure out if this is still needed anywhere
export const getColumnsWithRuleData = (ruleConfigs: LaborRuleConfigData[], t: TFunction<'translation', undefined>) => {
  const columnsFromRule = ruleConfigs.length
    ? Object.keys(ruleConfigs[0].rule)
        .filter((key) => !['type', 'group'].includes(key))
        .map((key) => createColumn(key, t(`laborRules.columnHeaders.${key}`)))
    : [];
  const leftColumns = defaultColumns
    .slice(0, 3)
    .map((name) => createColumn(name, t(`laborRules.columnHeaders.${name}`)));
  const rightColumns = defaultColumns
    .slice(3, 6)
    .map((name) => createColumn(name, t(`laborRules.columnHeaders.${name}`)));
  return [...leftColumns, columnsFromRule[0], ...rightColumns];
};

export const getColumns = (
  t: TFunction<'translation', undefined>,
  editModeVariant: boolean,
  jurisdictionPath: string,
  onEditRule?: (stateOverrides?: Record<string, unknown>) => void
) => {
  const columnNames = editModeVariant ? editModeColumns : defaultColumns;
  const columns = columnNames.map((name) => createColumn(name, t(`laborRules.columnHeaders.${name}`)));
  return editModeVariant
    ? columns
    : [
        ...columns,
        {
          ...createColumn('action', t(`laborRules.columnHeaders.action`)),
          renderCell: (params: GridRenderCellParams) => {
            const groupName = params.id.toString().split('/').at(-1);
            const jurisdictionName = jurisdictionPath.split('.').at(-1)?.replaceAll('_', ' ');
            const localityPath =
              groupName === jurisdictionName ? '' : `${jurisdictionPath}.${groupName?.replaceAll(' ', '_')}`;
            return (
              params.row.action ||
              (groupName !== jurisdictionName && (
                <Tooltip title={t('laborRules.tooltips.editLocality')}>
                  <Button onClick={() => onEditRule && onEditRule({ localityPath })}>
                    <Edit sx={{ m: 'auto' }} />
                  </Button>
                </Tooltip>
              ))
            );
          },
        },
      ];
};

export const getEnableGroupingSettings = (jurisdictionPath: string, ruleGroup: LaborRuleGroup) => {
  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) => row.hierarchy;
  const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
    headerName: 'Locality',
  };
  const CARuleGroupWithGrouping = [LaborRuleGroup.SCHEDULING, LaborRuleGroup.WAGES_AND_TIPS];
  return jurisdictionPath === 'United_States_of_America.California'
    ? (CARuleGroupWithGrouping.includes(ruleGroup) && { treeData: true, getTreeDataPath, groupingColDef }) || {}
    : (statesWithGrouping.includes(jurisdictionPath) &&
        ruleGroup === LaborRuleGroup.SCHEDULING && { treeData: true, getTreeDataPath, groupingColDef }) ||
        {};
};

function JurisdictionSitesDataGrid(props: JurisdictionSitesDataGridProps) {
  const { sites, getRowHeight, jurisdictionPath, ruleGroup, editModeVariant, onEditRule, allRules } = props;
  const { t } = useTranslation();
  const columns = getColumns(t, !!editModeVariant, jurisdictionPath, onEditRule);
  const rows = enrichSiteRows(sites, allRules, jurisdictionPath, t, onEditRule);
  const enableGrouping = editModeVariant ? {} : getEnableGroupingSettings(jurisdictionPath, ruleGroup);
  return (
    <DataGrid
      {...props}
      {...enableGrouping}
      columns={columns as GridColumns}
      rows={rows}
      disableExport
      disableColumnSelector={false}
      itemsPerPage={5}
      rowsPerPageOptions={[5, 10, 20]}
      sx={{ mt: '1rem' }}
      dataTestId='jurisdiction-sites-table'
      getRowHeight={getRowHeight}
    />
  );
}

JurisdictionSitesDataGrid.defaultProps = {
  getRowHeight: undefined,
  editModeVariant: false,
  onEditRule: undefined,
  allRules: undefined,
};

export default JurisdictionSitesDataGrid;
