import Box from '@mui/material/Box';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';

interface CustomActiveCellProps {
  isActive: boolean;
}

function CustomActiveCell({ isActive }: CustomActiveCellProps) {
  return (
    <Box width='100%' display='flex' justifyContent='center' alignItems='center'>
      {isActive ? (
        <Check color='success' data-testid='activeCell-active' />
      ) : (
        <Close color='error' data-testid='activeCell-inactive' />
      )}
    </Box>
  );
}

export default CustomActiveCell;
