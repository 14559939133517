// TODO: Delete this file for CBO-4139
import { Req } from '@cbo/shared-library';
import { useMemo } from 'react';
import { bslError } from '../contexts/firebaseApiContext';
import { Employee } from './services/employeeService';
import { EmployeeRequestSchema } from './types';
import useCallBslAndHandleErrors from '../lib/firebaseApiHandleErrors';

const proxies = {
  info: 'employees',
  config: 'employee-configuration',
};

const Verb = Req.Firebase.HttpVerb;
export default () => {
  const callBslAndHandleErrors = useCallBslAndHandleErrors();

  return useMemo(
    () => ({
      getEmployee: async (employeeId: string, handleError: (error: unknown) => void): Promise<Employee | void> =>
        callBslAndHandleErrors(
          {
            proxy: 'employees',
            verb: Verb.GET,
            pathSegments: ['employees', employeeId],
          },
          handleError
        ),

      updateEmployee: async (
        employeeId: string,
        employee: EmployeeRequestSchema,
        handleError: (error: unknown) => void,
        enterpriseUnitId: string,
        errorMessageMapper?: Record<string, string>
      ): Promise<unknown | typeof bslError> =>
        // TODO Figure this out
        // const updatedEmployee = { ...employee, registrationStatus: status };
        callBslAndHandleErrors(
          {
            verb: Verb.PATCH,
            proxy: proxies.info,
            pathSegments: ['employees'],
            queryParams: [{ kind: 'single', key: 'id', value: employeeId }],
            payload: employee,
            enterpriseUnit: enterpriseUnitId,
          },
          handleError,
          errorMessageMapper
        ),
    }),
    [callBslAndHandleErrors]
  );
};
