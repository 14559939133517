import { MutableRefObject, ReactNode, useRef, useState } from 'react';
import { Switcher } from 'src/components/common/Switcher/Switcher';
import {
    SwitcherProvider,
    getDefaultConfig,
} from 'src/components/common/Switcher/SwitcherProvider';
import { SwitcherOptions, useOrganizationContext } from 'src/contexts';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { OrganizationSwitcherDialog } from '../OrganizationSwitcherDialog/OrganizationSwitcherDialog';
import { OrganizationSwitcherDrawer } from './OrganizationSwitcherDrawer';
import { OrganizationSwitcherMenu } from './OrganizationSwitcherMenu';
import HierarchyIcon from 'src/components/common/Icons/Hierarchy';

export const NonInternalOrganizationSwitcher = ({
    config = getDefaultConfig(),
}: {
    config?: SwitcherOptions;
}) => {
    const { t } = useTranslationNs();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const { isLoading, organization, userOrganizations, updateOrganization } =
        useOrganizationContext();
    const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const isMobile = useMobileView();

    const handleOpenOrgMenu = () => {
        setMenuOpen(true);
    };

    const handleCloseOrgMenu = () => {
        setMenuOpen(false);
    };

    const handleOrgClick = (orgName: string) => {
        handleCloseOrgMenu();
        updateOrganization(orgName);
    };

    let switcherLabel: ReactNode;
    if (isLoading) switcherLabel = t('COMMON.LOADING');
    else if (!organization) {
        switcherLabel = t('ORG_CONTEXT.SWITCHER.EMPTY');
    } else switcherLabel = organization?.displayName || organization?.organizationName;

    return (
        <SwitcherProvider config={config || {}}>
            <Switcher
                tooltipTitle={
                    organization
                        ? organization.organizationName
                        : t('ORG_CONTEXT.ACCESSIBILITY.SWITCHER')
                }
                disabled={userOrganizations.length <= 1}
                enableSwitcher={userOrganizations.length !== 1}
                isLoading={isLoading}
                onClick={handleOpenOrgMenu}
                startIcon={<HierarchyIcon />}
                ref={buttonRef}
            >
                {switcherLabel}
            </Switcher>
            {isMobile ? (
                <OrganizationSwitcherDrawer
                    menuOpen={menuOpen}
                    onMenuOpen={handleOpenOrgMenu}
                    onMenuClose={handleCloseOrgMenu}
                    onOrgClick={handleOrgClick}
                />
            ) : (
                <OrganizationSwitcherMenu
                    anchorRef={buttonRef}
                    menuOpen={menuOpen}
                    onMenuClose={handleCloseOrgMenu}
                    onOrgClick={handleOrgClick}
                />
            )}
            <OrganizationSwitcherDialog />
        </SwitcherProvider>
    );
};
