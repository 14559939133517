import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import { MultiselectOption } from '@cbo/shared-library';

/** GLOBAL TYPES */
export type GlobalFilterBarLabelInput = { label: string; tab?: string };

/**
 * @param startDate The start date of the date range. Formatted like 'YYYY-MM-DD'
 * @param endDate The end date of the date range. Formatted like 'YYYY-MM-DD'
 */
export type GlobalFilterBarDateInput = {
  startDate: string | null;
  endDate: string | null;
};

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

/**
 * @param buttonText The text that appears on the button
 * @param titleText The title text that appears above the component
 * @param controllerName The name of the default form value the component uses
 * @param buttonSize Optional, the size of the button. Defaults to small
 * @param onlyFilterContainer Optional, whether the date filter should be displayed as a standalone component. Defaults to true
 */
export type BaseGlobalFilterProps = {
  buttonText?: string;
  titleText?: string;
  controllerName: string;
  buttonSize?: ButtonSize;
  onlyFilterContainer?: boolean;
};

/**
 * @param singular The text that appears on the button when only one option is selected
 * @param plural The text that appears on the button when multiple options are selected
 */
export type LabelGlobalFilterProps = BaseGlobalFilterProps & {
  singular: string;
  plural: string;
};

/** DATE FILTER TYPES */

/**
 * @param label The visible label that appears on the chip
 * @param dates The date range that the chip represents
 */
export type DateFilterChip = {
  label: string;
  dates: [Dayjs, Dayjs];
};

export enum DateComparisonRanges {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  BUSINESS_DATE = 'businessDate',
  SAME_DATE_LAST_YEAR = 'sameDatePreviousYear',
  SAME_DAY_LAST_YEAR = 'sameDayPreviousYear',
  PREVIOUS_PERIOD = 'previousPeriod',
  SALES_FORECAST = 'salesForecast',
  SCHEDULED_HOURS = 'scheduledHours',
}

/** TOGGLE SWITCH TYPES */

/**
 * @param label The visible label that appears to the left of the switch
 * @param tab The tab the label appears under
 * @param subtext Optional text that appears underneath the label
 * @param defaultActivated Optional, whether the switch should appear activated by default
 * @param childComponent Optional, will display the child component underneath when switched on
 * @param childComponent.component The actual ReactNode component
 * @param childComponent.controllerName The name of the form value the component uses
 */
export type ToggleSwitchOption = {
  label: string;
  tab?: string;
  subtext?: string;
  defaultActivated?: boolean;
  childComponent?: {
    component: ReactNode;
    controllerName: string;
  };
};

export type ToggleSwitchFormValue = {
  label: string;
  tab?: string;
  defaultActivated?: boolean;
};

/** SINGLE SELECT TYPES */

/**
 * @param label The label text the user sees and can select
 * @param tab The tab the label will be under if tabs are enabled
 * @param subtext Optional smaller text that appears below the label
 */
export type SingleSelectGroup = {
  label: string;
  tab?: string;
  subtext?: string;
};

/** FILTER IMPLEMENTATION TYPES */
export interface BaseFilter {
  sites: MultiselectOption[];
  dateRange: GlobalFilterBarDateInput;
}

export interface SiteAndDateComparisonFilter extends BaseFilter {
  comparedDateRange: {
    startDate: string | null;
    endDate: string | null;
    label: string | null;
  };
}
