export interface NotificationMethods {
  email: boolean;
  sms: boolean;
  inApp: boolean;
}

export interface NotificationSettingsRowData {
  [key: string]: unknown;
  id: string;
  groupName: string;
  numEvents: number;
  notificationMethods: NotificationMethods;
  siteNames: string;
}

export enum NotificationPriority {
  None = 'None',
  Normal = 'Normal',
  Priority = 'Priority',
}
