import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

export function Link({
    to,
    children
}: PropsWithChildren<{
    to: string,
}>) {
    let internalOnPress = useLinkPressHandler({to});

    function handlePress(event: any) {
        if (!event.defaultPrevented) {
            internalOnPress(event);
        }
    }

    return <a onClick={handlePress}>{children}</a>
}

export function useLinkPressHandler({ to }: { to: string }): (event: any) => void {
    return function handlePress() {
        let isExternalUrl = isValidHttpUrl(to) && !to.startsWith(window.location.origin);

        if (isExternalUrl) {
            const targetUrl = new URL(to);
            window.location.replace(targetUrl);
        } else {
            const sameOriginUrl = new URL(window.location.toString())
            sameOriginUrl.pathname = to;
            window.location.replace(sameOriginUrl);
        }
    };
}

function isValidHttpUrl(str: string) {
    let url: URL;

    try {
        url = new URL(str);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}