import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Ty from '@mui/material/Typography';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Res } from '@cbo/shared-library';
import NotificationCard from '../NotificationCard/NotificationCard';

interface NotificationRightPanelProps {
  notifications: Res.Notifications.Notification[];
  notificationsToRender: number;
  isMobile: boolean;
  handleRightPanelClose: () => void;
  handleNotificationsOpen: () => void;
  setNotificationsToRender: (notificationsToRender: number) => void;
  setNumberOfPages: (numberOfPages: number) => void;
  setLastPage: (lastPage: number) => void;
}

function NotificationRightPanel({
  notifications,
  notificationsToRender,
  isMobile,
  handleRightPanelClose,
  handleNotificationsOpen,
  setNotificationsToRender,
  setNumberOfPages,
  setLastPage,
}: NotificationRightPanelProps) {
  const { t } = useTranslation();

  const headerAndFooterHeightInPx = 200;
  const listItemHeight = isMobile ? 131 : 128;

  useEffect(() => {
    const clientHeight = window.innerHeight;
    const availableHeight = clientHeight - headerAndFooterHeightInPx;
    const numberOfNotifications = Math.floor(availableHeight / listItemHeight); // Number of items per page
    const numberOfPages = Math.ceil(notifications.length / numberOfNotifications);

    setNotificationsToRender(numberOfNotifications);
    setNumberOfPages(numberOfPages);
    setLastPage(numberOfPages);
  }, [setNotificationsToRender, setNumberOfPages, setLastPage, notifications, listItemHeight]);

  return (
    <Box
      data-testid='notifications-right-panel'
      width='100%'
      height='100%'
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 2.625,
          paddingX: 2,
        }}
      >
        <Box>
          <Ty
            data-testid='notifications-header'
            variant='h6'
            sx={{
              position: 'relative',
              top: '2px',
            }}
          >
            {t('rightPanel.notifications.header')}
          </Ty>
        </Box>

        <IconButton onClick={handleRightPanelClose}>
          <CloseIcon data-testid='close-sidebar-btn' />
        </IconButton>
      </Box>

      {/* New indicator */}
      <Box
        sx={{
          marginTop: 1,
          marginBottom: 0.375,
          paddingX: 2,
        }}
      >
        <Ty data-testid='new-notifications-indicator' variant='caption' color='text.secondary'>
          {t('rightPanel.notifications.newIndicator', {
            indicatorNumber: notifications.filter((item) => !item.read).length,
          })}
        </Ty>
      </Box>

      <Divider />

      {/* Notification list */}
      <Box>
        <List data-testid='notifications-list' disablePadding>
          {notifications.slice(0, notificationsToRender).map((notification, index) => (
            <Box key={`notification-card-${notification.id}`}>
              <ListItem data-testid={`notification-card-${index}`} disablePadding>
                <NotificationCard notification={notification} minHeightInPx={`${listItemHeight}px`} />
              </ListItem>

              <Divider />
            </Box>
          ))}
        </List>
      </Box>

      {/* See All Notifications Button */}
      <Box
        sx={{
          paddingTop: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box>
          <Button
            data-testid='see-all-notifications-btn'
            size='large'
            onClick={handleNotificationsOpen}
            disabled={notifications.length < 1}
          >
            {t('rightPanel.notifications.seeAllNotifications')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default NotificationRightPanel;
