import {
    Button,
    Stack,
    TextField,
    Theme,
    Typography,
    debounce,
    SxProps, InputLabel,
} from '@mui/material';
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    forwardRef,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useGetOrganizationByName } from 'src/fetchers/organization.fetchers';
import { useSyncRef } from 'src/hooks/useSyncRef';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { Organization } from 'src/types';
import { ThemeValues } from 'src/constants/theme-values';
import { useTheme } from '@mui/material/styles';

export type OrganizationCheckerInputProps = {
    activeTerm: string;
    setActiveTerm: Dispatch<SetStateAction<string>>;
    onCtaClick: (organization: Organization) => void;
    searchTerm: string;
    setSearchTerm: Dispatch<SetStateAction<string>>;
    isDisabled?: boolean;
    listSxProps?: SxProps<Theme>;
};

export const OrganizationCheckerInput = forwardRef<HTMLInputElement, OrganizationCheckerInputProps>(
    (
        {
            activeTerm,
            setActiveTerm,
            onCtaClick,
            searchTerm,
            setSearchTerm,
            isDisabled = false,
            listSxProps = {},
        }: OrganizationCheckerInputProps,
        ref,
    ) => {
        const [isDirty, setIsDirty] = useState<boolean>(false);
        const [isCtaDisabled, setIsCtaDisabled] = useState<boolean>(true);
        const inputRef = useSyncRef<HTMLInputElement>(ref);
        const { t } = useTranslationNs();
        const { organizationData, isLoadingOrg, errorOrg } = useGetOrganizationByName(searchTerm, {
            keepPreviousData: false,
        });
        const debouncedChangeHandler = useMemo(
            () =>
                debounce(async (event: ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    setSearchTerm(value);
                }, 750),
            [setSearchTerm],
        );
        const theme = useTheme();

        const ctaClickHandler = () => {
            if (!isCtaDisabled && !isLoadingOrg) {
                setActiveTerm(searchTerm);
                onCtaClick(organizationData);
            }
        };

        useEffect(() => {
            setIsDirty(searchTerm !== activeTerm);
        }, [setIsDirty, searchTerm, activeTerm]);

        useEffect(() => {
            setIsCtaDisabled(!organizationData || !isDirty || isDisabled);
        }, [organizationData, isDirty, isDisabled]);

        useEffect(() => {
            if (!errorOrg) return;
            inputRef.current?.focus();
        }, [errorOrg]);

        return (
            <Stack spacing={'24px'} sx={listSxProps}>
                <Stack rowGap={'8px'}>
                    <InputLabel error={errorOrg}>{t('ORG_CONTEXT.DIALOG.ID')}</InputLabel>
                    <TextField
                        inputRef={inputRef}
                        disabled={isLoadingOrg || isDisabled}
                        fullWidth={true}
                        autoComplete='off'
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setIsCtaDisabled(true);
                            debouncedChangeHandler(event);
                        }}
                        error={errorOrg}
                        InputProps={{
                            sx: {borderRadius: ThemeValues.BorderRadius }
                        }}
                    />
                    {errorOrg && (
                        <Typography fontSize={'14px'} color='error'>
                            {t('ORG_CONTEXT.DIALOG.NO_MATCH')}
                        </Typography>
                    )}
                </Stack>
                <Button
                    disabled={isCtaDisabled || isDisabled}
                    onClick={ctaClickHandler}
                    sx={{ 
                        textTransform: 'unset',   
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: ThemeValues.BorderRadius,
                    }}
                    fullWidth={true}
                    variant='contained'
                    size='large'
                >
                    {t('ORG_CONTEXT.DIALOG.FEDERATED_CTA')}
                </Button>
            </Stack>
        );
    },
);
