import Close from '@mui/icons-material/Close';
import { Theme, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import { Organization } from '@ncr-voyix-commerce/react-common-components';
import { useTranslation } from 'react-i18next';
import { useOrgContext } from '../CommonComponentWrapper';

function OrganizationSwitcherDrawer({
  menuOpen,
  onMenuOpen,
  onMenuClose,
}: {
  menuOpen: boolean;
  onMenuOpen: () => void;
  onMenuClose: () => void;
}) {
  const { t } = useTranslation();
  const { userOrganizations, updateOrganization, setIsOrgDialogOpen, organization } = useOrgContext();

  const theme = useTheme();

  const handleOrgClick = (orgName: string) => {
    onMenuClose();
    updateOrganization(orgName);
  };

  const handleViewAll = () => {
    onMenuClose();
    setIsOrgDialogOpen(true);
  };

  const effectiveOrgs = userOrganizations.slice(0, 5).map((org: Organization) => (
    <ListItem key={org.id} disablePadding>
      <ListItemButton
        role='button'
        sx={{
          ...theme.typography.subtitle1,
          padding: '12px 16px',
          textAlign: 'center',
        }}
        selected={org.organizationName === organization?.organizationName}
        onClick={() => handleOrgClick(org.organizationName)}
      >
        {org.displayName}
      </ListItemButton>
    </ListItem>
  ));

  return (
    <SwipeableDrawer
      disableDiscovery
      disableSwipeToOpen
      anchor='bottom'
      open={menuOpen}
      onClose={onMenuClose}
      onOpen={onMenuOpen}
      sx={{
        '.MuiPaper-root.MuiDrawer-paper': {
          borderTopRightRadius: '8px',
          borderTopLeftRadius: '8px',
        },
      }}
    >
      <Box height='auto' width='100%'>
        <Box
          sx={(sxtheme: Theme) => ({
            margin: '10px auto',
            width: '36px',
            height: '5px',
            backgroundColor: sxtheme.palette.action.active,
            borderRadius: '33px',
          })}
        />
        <Box
          sx={{
            padding: '0px 8px',
          }}
        >
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography
              sx={{
                padding: '12px 8px',
                fontSize: '1.125rem',
                height: '48px',
              }}
              variant='h6'
            >
              {t('mockOrgSwitcher.menu.title')}
            </Typography>
            <IconButton title={t('mockOrgSwitcher.accessibility.close')} onClick={onMenuClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <List>{effectiveOrgs}</List>
      <Box height='auto' width='100%'>
        <Button
          size='large'
          onClick={() => handleViewAll()}
          sx={{
            borderRadius: '8px',
            textTransform: 'none',
            width: '100%',
          }}
          variant='outlined'
        >
          {t('mockOrgSwitcher.menu.manageAllOrgs')}
        </Button>
      </Box>
    </SwipeableDrawer>
  );
}

export default OrganizationSwitcherDrawer;
