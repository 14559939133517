import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { useTheme } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import React from 'react';
import {
  darkBackgroundColor,
  defaultButtonStyling,
  selectedButtonStyling,
  arrowStyling,
} from '../GlobalFilterBarStyles';
import { ButtonSize } from '../types';
import ButtonResetIcon from '../ButtonResetIcon';

interface DateFilterButtonProps {
  anchorEl: HTMLButtonElement | null;
  buttonSize: ButtonSize;
  buttonText: string;
  handleReset: () => void;
  resetEnabled: boolean;
  predefined: boolean | undefined;
  handleButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  dates: DateRange<dayjs.Dayjs>;
}

function DateFilterButton(props: DateFilterButtonProps) {
  const { anchorEl, buttonSize, buttonText, handleReset, resetEnabled, predefined, handleButtonClick, dates } = props;

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleButtonSx = () => {
    if (isDarkMode) {
      if (resetEnabled || predefined) {
        return {
          backgroundColor: darkBackgroundColor,
          color: theme.palette.text.primary,
          '& .MuiButton-startIcon': { marginRight: '0px' },
        };
      }
      return {
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.text.primary,
        '& .MuiButton-startIcon': { marginRight: '0px' },
      };
    }

    if (resetEnabled || predefined) {
      return selectedButtonStyling(anchorEl, theme);
    }

    return defaultButtonStyling(anchorEl, theme);
  };

  return resetEnabled ? (
    <Button
      startIcon={
        predefined && (
          <DateRangeIcon
            sx={{ color: theme.palette.primary.main, fontSize: 'medium', marginLeft: '6px', marginRight: '2px' }}
          />
        )
      }
      role='button'
      aria-haspopup='true'
      data-testid='date-filter-button'
      sx={handleButtonSx}
      onClick={handleButtonClick}
      size={buttonSize}
    >
      <Typography
        sx={{
          marginRight: `${predefined ? '4px' : '0px'}`,
          marginLeft: `${'4px'}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 500,
          lineHeight: '22px',
        }}
        fontSize='inherit'
      >
        {dates[0]?.isValid() && dates[1]?.isValid()
          ? `${
              dates[0].isSame(dates[1], 'day')
                ? dates[0].format('L')
                : `${dates[0].format('L')} - ${dates[1].format('L')}`
            }`
          : buttonText}
      </Typography>
      {resetEnabled && <ButtonResetIcon handleReset={handleReset} />}
    </Button>
  ) : (
    <Button
      role='button'
      aria-haspopup='true'
      data-testid='date-filter-button'
      sx={handleButtonSx}
      onClick={handleButtonClick}
      size={buttonSize}
      startIcon={
        predefined && (
          <DateRangeIcon
            sx={{ color: theme.palette.primary.main, fontSize: 'medium', marginLeft: '6px', marginRight: '2px' }}
          />
        )
      }
    >
      <Typography
        sx={{ marginRight: '4px', marginLeft: `${predefined ? '4px' : '8px'}`, fontWeight: 500, lineHeight: '22px' }}
        fontSize='inherit'
      >
        {predefined && dates[0]?.isValid() && dates[1]?.isValid()
          ? `${
              dates[0].isSame(dates[1], 'day')
                ? dates[0].format('L')
                : `${dates[0].format('L')} - ${dates[1].format('L')}`
            }`
          : buttonText}
      </Typography>
      {anchorEl ? (
        <ArrowDropUpOutlinedIcon sx={arrowStyling(theme, Boolean(predefined))} fontSize='inherit' />
      ) : (
        <ArrowDropDownIcon sx={arrowStyling(theme, Boolean(predefined))} fontSize='inherit' />
      )}
    </Button>
  );
}

export default DateFilterButton;
