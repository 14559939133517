import Cancel from '@mui/icons-material/Cancel';
import Search from '@mui/icons-material/Search';
import { Theme, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function OrganizationSearchBar({ onChange }: { onChange: (value: string) => void }) {
  const theme = useTheme<Theme>();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    onChange(value);
  };

  const handleClear = () => {
    if (inputRef.current) inputRef.current.value = '';
    setInputValue('');
    onChange('');
  };

  return (
    <Box width='100%' position='relative'>
      <IconButton
        onClick={handleClear}
        sx={{
          position: 'absolute',
          right: '14px',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 2,
          display: inputValue ? 'inline-flex' : 'none',
        }}
        aria-label='cancel'
        size='small'
      >
        <Tooltip title={t('mockOrgSwitcher.tooltip.clear')}>
          <Cancel />
        </Tooltip>
      </IconButton>

      <TextField
        ref={inputRef}
        InputLabelProps={{
          shrink: false,
          focused: false,
        }}
        value={inputValue}
        InputProps={{
          startAdornment: (
            <InputAdornment
              sx={{
                marginRight: '6px',
                '.MuiSvgIcon-root': {
                  height: '24px',
                  width: '24px',
                },
              }}
              position='start'
            >
              <Search />
            </InputAdornment>
          ),
        }}
        onChange={handleOnChange}
        autoComplete='off'
        size='small'
        label={inputValue ? '' : t('mockOrgSwitcher.inputLabel')}
        autoFocus
        sx={{
          width: '100%',
          '.MuiInputBase-root': {
            borderRadius: '8px',
            overflow: 'hidden',
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : 'transparent',
          },
          '.MuiFormLabel-root': {
            paddingLeft: '30px',
          },
        }}
      />
    </Box>
  );
}

export default OrganizationSearchBar;
