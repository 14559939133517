import { doc, DocumentData, DocumentReference, DocumentSnapshot, FirestoreError, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import initFirestore from '../firestoreUtils/initFirestore';

/**
 * Subscribe to document changes
 * @param path the document path.  If undefined or empty string then the subscription is canceled
 * @param onError optional error handler
 * @return The document snapshot or null if the doc has not yet been pulled
 */
export default function useDocumentSubscription<T extends DocumentData>(
  path?: string,
  onError?: (error: FirestoreError) => void
) {
  const [snapshot, setSnapshot] = useState<DocumentSnapshot<T> | null>(null);

  const firestore = initFirestore();

  useEffect(() => {
    if (!path) {
      setSnapshot(null);
      return () => undefined;
    }
    const document = doc(firestore, path) as DocumentReference<T>;
    return onSnapshot(document, setSnapshot, onError);
  }, [path, firestore, onError]);

  return snapshot;
}
