import Box from '@mui/material/Box';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Theme } from '@mui/system/createTheme';
import { useTheme } from '@mui/system';
import { useFormContext } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material/Select/Select';
import {
  LaborRulePropertyTypeMappings,
  RuleTypographyInputType,
  LaborRuleSelectValueKeys,
  selectValueMap,
  MinimumWageRule,
  ageRange,
  MinorProhibitedHoursRule,
  LaborRule,
  RightToRestRule,
} from '../models/LaborRuleConfiguration';
import {
  getNumbers,
  getRuleTokens,
  isMinorDefinedSchoolDaysRule,
  isRightToRestRule,
  isTippedMinWageRule,
} from '../utils/utils';
import createFormTextField, { StartAdornmentType } from '../../components/FormTextFields/FormTextFields';
import TippedMinWageTypographyForm from '../TippedMinWageTypographyForm/TippedMinWageTypographyForm';
import SchoolDayDefinitionTypographyForm from '../SchoolDayDefinitionTypographyForm/SchoolDayDefinitionTypographyForm';

interface RuleTypographyFormProps {
  formRuleIndex: number;
  staticSx?: SxProps<Theme>;
  templateString: string;
  onSelectChange?: (rule: LaborRule) => void;
}

const getOptions = (match: string, t: TFunction<'translation', undefined>) => {
  let options: {
    label: string;
    value: string | number;
  }[];

  if (match === 'minAge' || match === 'maxAge') {
    const allAges = getNumbers(ageRange.minAge, ageRange.maxAge);
    options = allAges.map((age: number) => ({
      label: age.toString(),
      value: age.toString(),
    }));
  } else {
    options = LaborRuleSelectValueKeys[match].map((key: string | number) => ({
      label: t(`laborRules.selectValues.${match}.${key}`),
      value: selectValueMap[key],
    }));
  }

  return options;
};

export const updateRule = (
  name: string,
  value: string | string[],
  onSelectChange: ((rule: LaborRule) => void) | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentConfig: any
) => {
  if (onSelectChange) {
    // Update the rule object and let the parent know so that view gets the right template string
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldName = name.split('.').at(-1)!;
    const updatedRule = { ...currentConfig.rule };
    if (isRightToRestRule(currentConfig.rule as LaborRule)) {
      updatedRule.paymentType = { ...(currentConfig.rule as RightToRestRule).paymentType };
      updatedRule.paymentType[fieldName] = value;
    } else {
      updatedRule[fieldName] = value;
    }

    onSelectChange(updatedRule);
  }
};

/** Template strings formatted with {{parameters}} will access the
 * properties of the rule object with the string in the brackets (so
 * they should match) */
export default function RuleTypographyForm(props: RuleTypographyFormProps) {
  const { formRuleIndex, staticSx, templateString, onSelectChange } = props;
  const formMethods = useFormContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const configsWatch = formMethods.watch('rules');
  const currentConfig = configsWatch[formRuleIndex];

  const handleSelectChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    updateRule(name, value, onSelectChange, currentConfig);
  };

  if (isTippedMinWageRule(currentConfig.rule)) {
    return (
      <TippedMinWageTypographyForm
        templateString={t('laborRules.rules.MinimumWageRule.Tipped.template')}
        rule={currentConfig.rule as MinimumWageRule}
      />
    );
  }

  if (isMinorDefinedSchoolDaysRule(currentConfig.rule)) {
    return <SchoolDayDefinitionTypographyForm rule={currentConfig.rule as MinorProhibitedHoursRule} />;
  }

  return (
    <Box>
      {getRuleTokens(templateString).map((match, index) => {
        // If there's a second non-falsey element we're working with a dynamic values
        if (match[1]) {
          switch (LaborRulePropertyTypeMappings[match[1]]) {
            case RuleTypographyInputType.SELECT:
              return (
                <Box
                  component='span'
                  key={match[1]}
                  sx={{
                    minWidth: 100,
                    width: 'fit-content',
                    display: 'inline-block',
                    marginLeft: 1,
                    verticalAlign: 'middle',
                  }}
                >
                  {createFormTextField(
                    {
                      name: `rules[${formRuleIndex}].rule.${match[1]}`,
                      variant: 'select',
                      dataTestId: `select-${match[1]}-${formRuleIndex}`,
                      label: '',
                      required: true,
                      options: getOptions(match[1], t),
                      onChange: handleSelectChange,
                    },
                    { t, tKey: match[1] },
                    formMethods.register,
                    {
                      '& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input': {
                        textAlign: 'center',
                      },
                    },
                    t(`laborRules.dropdownAriaLabels.${match[1]}`)
                  )}
                </Box>
              );
            case RuleTypographyInputType.TIME:
              return (
                <Box
                  component='span'
                  key={match[1]}
                  sx={{ display: 'inline-block', minWidth: 110, mx: 1, verticalAlign: 'middle' }}
                >
                  {createFormTextField(
                    {
                      name: `rules.${formRuleIndex}.rule.${match[1]}`,
                      variant: 'time',
                      dataTestId: `time-picker-${match[1]}-${formRuleIndex}`,
                      label: '',
                      lightMode: theme.palette.mode === 'light' && !!configsWatch,
                    },
                    { t, tKey: match[1] },
                    formMethods.register
                  )}
                </Box>
              );
            default:
              // eslint-disable-next-line no-case-declarations
              const { inputType, startAdornmentType } =
                LaborRulePropertyTypeMappings[match[1]] === RuleTypographyInputType.CURRENCY
                  ? { inputType: RuleTypographyInputType.NUMBER, startAdornmentType: StartAdornmentType.DOLLAR_SIGN }
                  : { inputType: LaborRulePropertyTypeMappings[match[1]], startAdornmentType: null };
              return (
                <span
                  key={match[1]}
                  style={{
                    maxWidth: 100,
                    display: 'inline-block',
                    marginLeft: 1,
                    marginRight: 1,
                    verticalAlign: 'middle',
                  }}
                >
                  {createFormTextField(
                    {
                      name: `rules[${formRuleIndex}].rule.${match[1]}`,
                      variant: 'standard',
                      label: '',
                      required: true,
                      dataTestId: `textfield-${match[1]}-${formRuleIndex}`,
                      inputType,
                      startAdornmentType,
                    },
                    { t, tKey: '' },
                    formMethods.register,
                    { input: { textAlign: 'center' } },
                    t('laborRules.numericInputAriaLabel')
                  )}
                </span>
              );
          }
        }
        return (
          <Box
            component='span'
            key={`${match[0]}`}
            sx={staticSx}
            data-testid={`${currentConfig.rule.type}-rule-text-value-2`}
          >
            {match[0]}
          </Box>
        );
      })}
    </Box>
  );
}

RuleTypographyForm.defaultProps = {
  staticSx: {},
  onSelectChange: undefined,
};
