import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Global } from 'src/constants/global';
import { UserSettingKeys } from 'src/constants/user-settings-keys';
import { IOrganizationContext, useRccContext } from 'src/contexts';
import { OrganizationContext } from 'src/contexts/OrganizationContext';
import { useUserSettingsContext } from 'src/contexts/UserSettingsContext';
import {
    useGetEffectiveOrganizations,
    useGetOrganizationByName,
} from 'src/fetchers/organization.fetchers';
import { Organization } from 'src/types/organization.type';
import { deleteSearchParam, getSearchParam, setSearchParam, useLocation } from 'src/utils/search-params.utils';
import { QueryParamKeys } from 'src/types';

const checkOrgExists = (orgs: Organization[], orgToCheck: string): boolean => {
    return orgs.some((org) => org.organizationName === orgToCheck);
};

export const OrganizationContextProvider = ({ children }: { children: ReactNode }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams, setSearchParams] = useState<URLSearchParams>();
    const [error, setError] = useState();
    const { userSettings, getUserSettingByKey } = useUserSettingsContext();
    const { isAuthenticated, isInternalUser } = useRccContext();
    const [organizationName, setOrganizationName] = useState<string>();
    const [organization, setOrganization] = useState<Organization>();
    const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);
    const { userOrganizations, isLoadingEffectiveOrgs, errorOrgs } = useGetEffectiveOrganizations(
        isAuthenticated,
        isInternalUser,
    );
    const location = useLocation();


    const updateProviderOrganization = useCallback(
        (org: Organization | null) => {
            Global.NepOrganization = org?.organizationName;
            setOrganization(org);
        },
        [setOrganization],
    );

    const { organizationData, isLoadingOrg, errorOrg } = useGetOrganizationByName(
        organizationName,
        {
            onError: () => {
                
                if (getSearchParam(QueryParamKeys.organization)) {
                    deleteSearchParam(QueryParamKeys.organization);
                    setSearchParams(searchParams);
                }

                if (isInternalUser) {
                    setIsOrgDialogOpen(true);
                    return;
                }

                const erroredOrg = userOrganizations.find(
                    (org) => organizationName === org.organizationName,
                );

                updateProviderOrganization(erroredOrg);
            },
            keepPreviousData: false,
        },
    );

    useEffect(
        function setActiveOrganization() {
            if (!organizationData) return;
            updateProviderOrganization(organizationData);
        },
        [organizationData, updateProviderOrganization],
    );

    useEffect(
        function setActiveParams() {
           if (
                organization &&
                getSearchParam(QueryParamKeys.organization) !== organization.organizationName
            ) {
                setSearchParam(QueryParamKeys.organization, organization.organizationName);
                setSearchParams(searchParams);
            }
        },
        [organization, location],
    );

    useEffect(
        function resetOrganizationData() {
            if (!isAuthenticated) {
                setOrganizationName(null);
                Global.NepOrganization = '';
            }
        },
        [isAuthenticated, setOrganizationName],
    );

    useEffect(
        function setLoadingState() {
            setIsLoading(
                isLoadingOrg ||
                    isLoadingEffectiveOrgs ||
                    (userOrganizations === undefined && !isInternalUser),
            );
        },
        [isLoadingOrg, isLoadingEffectiveOrgs, setIsLoading, userOrganizations, isInternalUser],
    );

    useEffect(
        function setErrorState() {
            setError(errorOrg || errorOrgs);
        },
        [errorOrg, errorOrgs, setError],
    );

    const updateOrganizationName = useCallback(
        (org: string) => {
            setOrganizationName((previousOrgName: string) =>
                org == previousOrgName ? previousOrgName : org,
            );
        },
        [setOrganizationName],
    );

    useEffect(
        function chooseDefaultOrg() {
            if (!!organization || isInternalUser == undefined || !userSettings) return;

            const paramOrgId = getSearchParam(QueryParamKeys.organization);
            const defaultOrg = getUserSettingByKey(UserSettingKeys.DefaultOrg);

            if (!isInternalUser && userOrganizations?.length > 0) {
                if (paramOrgId && checkOrgExists(userOrganizations, paramOrgId)) {
                    updateOrganizationName(paramOrgId);
                } else if (defaultOrg && checkOrgExists(userOrganizations, defaultOrg)) {
                    updateOrganizationName(defaultOrg);
                } else {
                    updateOrganizationName(userOrganizations[0].organizationName);
                }
            } else if (!!isInternalUser) {
                if (paramOrgId) {
                    updateOrganizationName(paramOrgId);
                } else if (defaultOrg) {
                    updateOrganizationName(defaultOrg);
                } else {
                    setIsOrgDialogOpen(true);
                }
            }
        },
        [
            isInternalUser,
            userOrganizations,
            organization,
            userSettings,
            getUserSettingByKey,
            updateOrganizationName,
            setIsOrgDialogOpen,
        ],
    );

    const contextValue: IOrganizationContext = useMemo(
        () => ({
            organization,
            isOrgDialogOpen,
            setIsOrgDialogOpen,
            userOrganizations: userOrganizations ?? [],
            updateOrganization: updateOrganizationName,
            isLoading,
            error,
        }),
        [
            organization,
            isOrgDialogOpen,
            setIsOrgDialogOpen,
            userOrganizations,
            updateOrganizationName,
            isLoading,
            error,
        ],
    );

    return (
        <OrganizationContext.Provider value={contextValue}>{children}</OrganizationContext.Provider>
    );
};
