import { ReactNode } from 'react';

export type NavigationViewProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  path: string;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  level?: number;
};

function NavigationView(
  props: {
    // eslint-disable-next-line react/require-default-props
    children?: ReactNode;
  } & NavigationViewProps
) {
  const { children } = props;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default NavigationView;
