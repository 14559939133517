import { Res } from '@cbo/shared-library';
import { Regions, TRegion } from '../../config';
import { DateFormat } from '../../models/config';

/**
 * Returns the date format, combining regional preferences with the user's saved preferences
 * @param preferences
 * @returns Returns an instance of DateFormat that includes locale and format options
 */
export const getDateFormat = (preferences?: Res.Admin.UserPreferences | null): DateFormat => {
  const region: TRegion = (preferences?.region as TRegion) || 'en-US';
  const locale: string = Regions[region].locale || region;
  return { dateFormatString: Regions[region]?.formatting?.date?.dateFormatString, locale };
};

/**
 * Formats dates based on regional preferences and the user's saved preferences
 * @param value
 * @param preferences
 * @param format
 * @param reverseTzOffset
 * @returns Returns the specified date (value) as a formatted string
 */
export const formatDate = (
  value: string | number | Date,
  preferences?: Res.Admin.UserPreferences | null,
  format?: DateFormat,
  reverseTzOffset = false
): string => {
  const { options, locale } = format || getDateFormat(preferences);
  const opts: Intl.DateTimeFormatOptions = options as Intl.DateTimeFormatOptions;
  let date = new Date(value);
  if (reverseTzOffset) {
    const splitDate = new Date((value as string).split('T')[0]); // return date-only Date object (eg 2022-02-01)
    const userTzOffset = date.getTimezoneOffset() * 60000;
    date = new Date(splitDate.getTime() + userTzOffset); // account for TZ offset in browser
  }

  return Intl.DateTimeFormat(locale, opts).format(date);
};

/**
 * looks at a users region to return the datetime format string, default to USA
 * @param preferences
 * @returns Returns datetime format string for given local and format options
 */
export const getBQDateTimeFormatString = (
  preferences?: Res.Admin.UserPreferences,
  options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }
): string => {
  const region: TRegion = (preferences?.region as TRegion) || 'en-US';
  const locale: string = Regions[region].locale || region;
  const hour12: boolean = Regions[region].formatting?.time?.options?.hour12 || false;

  const formatObj = new Intl.DateTimeFormat(locale, options).formatToParts(Date.now());

  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case 'hour':
          return hour12 ? '%l' : '%H';
        case 'minute':
          return '%M';
        case 'second':
          return '%E';
        case 'day':
          return '%d';
        case 'month':
          return '%m';
        case 'year':
          return '%G';
        case 'dayPeriod':
          return '%p';
        default:
          return obj.value;
      }
    })
    .join('');
};

// Matches a valid iso string of yyyy-mm-dd format
// eslint-disable-next-line no-useless-escape
export const ISO_DATE_STRING_REGEX = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/g;

/**
 * appends 0 hours, minutes & seconds to a date string
 * @param date
 * @returns Returns a new string with time appended
 */
export const formatDateStringWithDefaultTime = (date: string): string => `${date}T00:00:00`;

export const convertDateStringToLocalISO = (dateString?: string) => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString.split('Z')[0]);
  return new Date(date.setHours(0, 0, 0, 0)).toISOString();
};

export const formatDayJsDateInput = (
  preferences?: Res.Admin.UserPreferences | null,
  options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }
): string => {
  const region: TRegion = (preferences?.region as TRegion) || 'en-US';
  const locale: string = Regions[region].locale || region;

  const formatObj = new Intl.DateTimeFormat(locale, options).formatToParts(Date.now());
  // FIX: This is hard-coding day and month lengths to 2-digit even if preference is 'd/m/yyyy'. Need to fix.
  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case 'day':
          return 'DD';
        case 'month':
          return 'MM';
        case 'year':
          return 'YYYY';
        default:
          return obj.value;
      }
    })
    .join('');
};

export const is24HourFormat = (preferences?: Res.Admin.UserPreferences | null) => {
  const region: TRegion = (preferences?.region as TRegion) || 'en-US';

  return !Regions[region].formatting.time?.options?.hour12;
};

// MUI DateTimePicker v5 appears to only accept 12 or 24 time formats, not supportive of the '00 h 00' format
export const getDateTimeFormat = (preferences?: Res.Admin.UserPreferences | null, inputFormat?: string) => {
  const dateFormat = inputFormat ?? formatDayJsDateInput(preferences);

  if (preferences && is24HourFormat(preferences)) {
    return `${dateFormat} HH:mm`;
  }

  return `${dateFormat} hh:mm A`;
};

// TODO This should be expanded to handle more regions than those at launch
const resolveTimeFormatForDisplay = (preferences: Res.Admin.UserPreferences | null | undefined) => {
  if (preferences?.region === 'en-US') {
    return 'hh:mm (A|P)M';
  }
  return 'hh:mm';
};

export const getDateTimeFormatForDisplay = (preferences?: Res.Admin.UserPreferences | null, inputFormat?: string) => {
  const dateFormat = inputFormat ?? formatDayJsDateInput(preferences);
  const timeFormat = inputFormat ?? resolveTimeFormatForDisplay(preferences);

  return `${dateFormat} ${timeFormat}`;
};
