import { UserConfigurationSetting, UserSettings } from 'src/types/user-settings.type';
import { ApiError } from '..';
import { useContext, createContext } from 'react';
import { UserSettingKeys } from 'src/constants/user-settings-keys';

export type IUserSettingsContext = {
    userSettings: UserSettings;
    isUserSettingsLoading: boolean;
    isUserSettingsMutating: boolean;
    isUserSettingsValidating: boolean;
    userSettingsMutationError: ApiError;
    getUserSettingsError: ApiError;
    updateUserSettings: (setting: UserConfigurationSetting) => void;
    getUserSettingByKey: (key: UserSettingKeys) => string | undefined;
};

export const UserSettingsContext = createContext<IUserSettingsContext>({} as IUserSettingsContext);

export const useUserSettingsContext = () => useContext(UserSettingsContext);
