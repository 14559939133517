import { PersonaRole } from '@cbo/shared-library';
import { User } from '../models/User';

const checkUserRoleForEmployment = (user: User): boolean => {
  const userRolesToCheck = [PersonaRole.ASSISTANT_MANAGER, PersonaRole.EMPLOYEE, PersonaRole.MANAGER];
  const userPersonas = user.bslAuth?.personas || [];

  return userPersonas.some((item) => userRolesToCheck.includes(item));
};

export default checkUserRoleForEmployment;
