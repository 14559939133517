/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material';
import {
  gridColumnPositionsSelector,
  gridColumnsTotalWidthSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { useMemo } from 'react';

// Custom loading overlay using skeletons
// see https://github.com/mui/mui-x/issues/1685 for details

const SkeletonCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

function CustomSkeletonLoadingOverlay() {
  const apiRef = useGridApiContext();

  // @ts-expect-error Function signature expects to be called with parameters, but the implementation suggests otherwise
  const rowHeight = apiRef.current.unstable_getRowHeight();

  const totalWidth = gridColumnsTotalWidthSelector(apiRef);
  const positions = gridColumnPositionsSelector(apiRef);
  const inViewportCount = useMemo(
    () => positions.filter((value) => value <= totalWidth).length,
    [totalWidth, positions]
  );
  const columns = apiRef.current.getVisibleColumns().slice(0, inViewportCount);

  const children = useMemo(() => {
    const array: React.ReactNode[] = [];

    // Create 10 rows
    for (let i = 0; i < 10; i += 1) {
      columns.forEach((c) => {
        array.push(
          <SkeletonCell key={`column-${i}-${c.field}`} sx={{ justifyContent: c.align }}>
            <Skeleton sx={{ mx: 1 }} width='80%' />
          </SkeletonCell>
        );
      });
      array.push(<SkeletonCell key={`fill-${i}`} />);
    }
    return array;
  }, [columns]);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `${columns.map(({ computedWidth }) => `${computedWidth}px`).join(' ')} 1fr`,
        gridAutoRows: rowHeight,
      }}
      data-testid='data-grid-custom-skeleton-loading-overlay'
    >
      {children}
    </div>
  );
}

export default CustomSkeletonLoadingOverlay;
