import Close from '@mui/icons-material/Close';
import {
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useEffect, useRef, useState } from 'react';
import { useOrganizationContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { Organization } from 'src/types';
import { OrganizationCheckerInput } from '../OrganizationCheckerInput/OrganizationCheckerInput';

const OrganizationCheckerInputWrapper = ({
    onCtaClick,
}: {
    onCtaClick: (organization: Organization) => void;
}) => {
    const [searchTerm, setSearchTerm] = useState<string>();
    const [activeTerm, setActiveTerm] = useState<string>();
    const isInitialLoad = useRef(true);
    const inputRef = useRef<HTMLInputElement>();
    const { organization, isLoading } = useOrganizationContext();

    useEffect(() => {
        if (isInitialLoad.current && organization) {
            const orgName = organization.organizationName;
            setSearchTerm(orgName);
            setActiveTerm(orgName);
            inputRef.current.value = orgName;
            isInitialLoad.current = false;
        }
    }, [organization]);

    return (
        <OrganizationCheckerInput
            ref={inputRef}
            onCtaClick={onCtaClick}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            activeTerm={activeTerm}
            setActiveTerm={setActiveTerm}
            isDisabled={isLoading}
        />
    );
};

export const OrganizationInternalDialog = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) => {
    const { t } = useTranslationNs();
    const { updateOrganization } = useOrganizationContext();

    const ctaClickHandler = (organization: Organization) => {
        updateOrganization(organization.organizationName);
        onClose();
    };
    
    return (
         <Dialog
             sx={{
                 '& .MuiPaper-root': {
                    borderRadius: '24px',
                    width: '450px',
                    maxWidth: 'calc(100% - 32px)',
                    py: '12px',
                 }
             }}
             open={isOpen}
             onClose={onClose}
         >
             <Stack
                 justifyContent='space-between'
                 alignItems='center'
                 direction='row'
                 padding='16px 10px 16px 24px'
             >
                 <DialogTitle sx={{ padding: 0, fontWeight: 700 }} variant='h6'>
                     {t('ORG_CONTEXT.MENU.TITLE')}
                 </DialogTitle>
                 <IconButton onClick={onClose}>
                     <Close />
                 </IconButton>
             </Stack>
             <DialogContent>
                 <OrganizationCheckerInputWrapper onCtaClick={ctaClickHandler} />
             </DialogContent>
         </Dialog>
     );
};
