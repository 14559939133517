export const prependHttp = (url: string, { https = true } = {}) => {
  if (typeof url !== 'string') {
    throw new TypeError(`Expected \`url\` to be of type \`string\`, got \`${typeof url}\``);
  }

  const trimmedUrl = url.trim();
  return trimmedUrl.replace(/^(?!(?:\w+?:)?\/\/)/, https ? 'https://' : 'http://');
};

export const isExternalLink = (url: string): boolean => {
  const parser = document.createElement('a');
  parser.href = url;
  const isExternal = parser.hostname !== window.location.hostname;

  return isExternal;
};

export const getHostName = (url: string): string => {
  if (typeof url !== 'string') {
    return '';
  }

  const parser = document.createElement('a');
  parser.href = url;

  return parser.hostname;
};
