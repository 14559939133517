/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { MinorProhibitedHoursRule } from '../models/LaborRuleConfiguration';
import SelectDaysField from '../../labor/Scheduling/SelectDaysField/SelectDaysField';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import { getFullDate } from '../utils/utils';

interface SchoolDayDefinitionTypographyFormProps {
  rule: MinorProhibitedHoursRule;
}

export default function SchoolDayDefinitionTypographyForm(props: SchoolDayDefinitionTypographyFormProps) {
  const { t } = useTranslation();
  const { rule } = props;
  const prefix = 'laborRules.rules.MinorSchoolDayDefinitionRule';
  const formContext = useFormContext();
  const defaultDateRange: DateRange<Dayjs> = [null, null];
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>(defaultDateRange);
  const { setValue } = formContext;

  useEffect(() => {
    if (!rule.start || !rule.end) {
      setDateRange([dayjs(), dayjs()]);
      return;
    }
    const formattedStartDate = getFullDate(rule.start, true, t);
    const formattedEndDate = getFullDate(rule.end, true, t);
    setDateRange([dayjs(formattedStartDate), dayjs(formattedEndDate)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule]);

  const onDateRangeChange = (newValue: DateRange<Dayjs>) => {
    if (!newValue[0]?.isValid() || !newValue[1]?.isValid()) return;
    setDateRange(newValue);
    const start = newValue[0].format('MM-DD');
    const end = newValue[1].format('MM-DD');
    setValue('rules.0.rule.start', `--${start}`, { shouldDirty: true });
    setValue('rules.0.rule.end', `--${end}`, { shouldDirty: true });
  };

  const onSchoolDayChange = (newValue: string[]) => {
    setValue('rules.1.rule.schoolDays', newValue, { shouldDirty: true });
  };

  return (
    <Grid container>
      <Grid item container xs={12} sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
        <Grid item xs={2.35}>
          <Typography>{t(`${prefix}.SchoolDays.name`)}</Typography>
        </Grid>
        <Grid item sx={{ ml: 3 }}>
          <SelectDaysField
            selectedDays={rule.schoolDays}
            weekStart={0}
            isReadOnly={false}
            handleChange={onSchoolDayChange}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
        <Grid item xs={2.5}>
          <Typography sx={{ mr: 4 }}>{t(`${prefix}.SchoolNotInSession.name`)}</Typography>
        </Grid>
        <Grid item sx={{ ml: 2.75 }}>
          <DateRangePicker
            value={dateRange}
            onChange={onDateRangeChange}
            localeText={{ start: t('laborRules.rulesGuidance.start'), end: t('laborRules.rulesGuidance.end') }}
            variant='standard'
            useDefaultAnchor
            displaySeparator={false}
            inputFormat='MM-DD'
            useDayjsAdapter
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
