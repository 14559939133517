import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';
import { Res } from '@cbo/shared-library';
import DeltaChipIndicator from '../DeltaIndicatorChip/DeltaIndicatorChip';
import { MobileSize } from '../../admin/DashboardPage/ScorecardContainer/ScorecardContainer';
import { formatCurrency } from '../../utils/formatters';
import ComparisonRange from '../../config/comparisonRange';
import { DateComparisonRanges } from '../GlobalFilterBar/types';

export interface SingleValueCardProps {
  title: string;
  value: string | number;
  loading: boolean;
  comparisonValue?: string | number;
  comparisonRange?: ComparisonRange | DateComparisonRanges | null;
  positiveResultsPreferred?: boolean;
  currency?: boolean;
  percentage?: boolean;
  url?: string;
  mobileSize?: MobileSize;
  width?: string;
  preferences?: Res.Admin.UserPreferences | null;
  testId?: string;
  height?: string;
  isClickable?: boolean;
}

export default function SingleValueCard({
  title,
  value,
  loading,
  comparisonRange,
  comparisonValue,
  positiveResultsPreferred,
  currency,
  percentage,
  url,
  mobileSize,
  width,
  height,
  preferences,
  testId,
  isClickable,
}: SingleValueCardProps) {
  let formattedValue;

  if (currency) {
    formattedValue = formatCurrency(Number(value), preferences);
  } else if (percentage) {
    formattedValue = `${Number(value).toFixed(2)}%`;
  } else {
    formattedValue = value;
  }

  const clickableSx = {
    '&:hover': {
      boxShadow:
        '0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    },
  };

  const commonSx = {
    position: 'relative',
    display: 'flex',
    flexDirection: mobileSize === 'small' ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: height || '142px',
    width: mobileSize === 'small' ? width || '100%' : '185px',
    overflow: 'clip',
    flex: mobileSize && mobileSize !== 'small' ? '40%' : 'none',
    marginBottom: mobileSize === 'small' ? '-8px' : 0,
    border: 1,
    borderColor: '#B8B7B7',
    borderRadius: '8px',
  };

  return (
    <Paper
      sx={
        isClickable
          ? {
              ...commonSx,
              ...clickableSx,
            }
          : { ...commonSx }
      }
      data-testid={testId ? `single-value-card-small-${testId}` : 'single-value-card-small'}
    >
      {loading ? (
        <Skeleton
          sx={{
            height: '100%',
            width: '100%',
          }}
          variant='rounded'
          data-testid='single-value-card-small-skeleton'
        />
      ) : (
        <Box
          hidden={loading}
          sx={{
            position: 'relative',
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
            height: '100%',
          }}
          data-testid='single-value-card-small-content'
        >
          {url && (
            <Link
              href={url}
              title={url}
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
            />
          )}
          <Box
            data-testid='single-value-card-small-content'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              verticalAlign: 'middle',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: '500',
                letterSpacing: '0.15px',
                marginTop: '4px',
              }}
              data-testid='single-value-card-small-value'
            >
              {formattedValue}
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '1em',
                letterSpacing: '0.15px',
                marginTop: '4px',
                textAlign: 'center',
                whiteSpace: 'pre-line',
              }}
              data-testid='single-value-card-small-title'
            >
              {title}
            </Typography>
            {comparisonRange && (
              <DeltaChipIndicator
                comparisonRange={comparisonValue !== 0 ? comparisonRange : undefined}
                value={comparisonValue}
                isImprovement={positiveResultsPreferred ? Number(comparisonValue) > 0 : Number(comparisonValue) < 0}
                dataTestId='single-value-card-small-delta'
              />
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
}

SingleValueCard.defaultProps = {
  comparisonRange: undefined,
  comparisonValue: undefined,
  currency: false,
  percentage: false,
  mobileSize: undefined,
  positiveResultsPreferred: true,
  url: '',
  testId: undefined,
  width: undefined,
  height: undefined,
  preferences: null,
  isClickable: false,
};
