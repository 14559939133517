/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import type { MouseEvent as RxMouseEvent, ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ListItemButtonProps, Theme, styled } from '@mui/material';
import { getCustomDesignTheme, matchPath, normalizePath } from './navigationUtils';
import { NavigationPath, useNavigation } from './NavigationContext';

type NavigationItemContextType = {
  parent?: NavigationItemContextType;
  path: string;
  level: number;
};

const NavigationItemContext = createContext<NavigationItemContextType>({
  parent: undefined,
  path: '',
  level: 0,
});

export type NavigationItemProps = {
  selected?: boolean;
  children?: ReactNode;
  title?: ReactNode;
  path?: string;
  icon?: ReactNode;
} & Omit<ListItemButtonProps, 'title' | 'children'>;

export function NavigationItem(
  props: NavigationItemProps & {
    variant?: 'standard' | 'expandable' | 'drilldown';
  }
) {
  const {
    children,
    title,
    path: pathProp,
    selected: isSelectedProp,
    variant: variantProp,
    icon,
    sx,
    onClick,
    ...rest
  } = props;

  const { selection } = useNavigation();
  const parent = useContext(NavigationItemContext);

  const level = useMemo(() => parent.level + 1, [parent.level]);

  const path = useMemo(() => normalizePath(pathProp as NavigationPath), [pathProp]);

  const isSelected = useMemo(() => matchPath(selection, path) || isSelectedProp, [selection, path, isSelectedProp]);

  const [isExpanded, setExpanded] = useState(isSelected);

  const variant = useMemo(() => variantProp || (children ? 'expandable' : 'standard'), [children]);
  // Ensure top level expandable nav items get expanded if routed from an external action
  useEffect(() => {
    if (isSelected && variant === 'expandable') {
      setExpanded(true);
    }
  }, [isSelected]);

  const handleClick = useCallback(
    (e: RxMouseEvent<HTMLDivElement, MouseEvent>) => {
      if (onClick) {
        return onClick(e);
      }
      return setExpanded((x) => !x);
    },
    [onClick, setExpanded, path]
  );

  const item = useMemo(
    () => ({
      parent,
      level,
      path,
    }),
    [parent, level, path]
  );

  const NavItemButton = styled(ListItemButton)(({ theme }: { theme: Theme }) => {
    const { mode } = theme.palette;
    const customDesignComponentStyles = getCustomDesignTheme(mode);
    return {
      borderRadius: '0.5rem',
      padding: 1,
      paddingTop: level > 1 ? '6px' : '9px',
      paddingBottom: level > 1 ? '6px' : '9px',
      '.MuiTypography-root': {
        color: theme.palette.text.secondary,
      },
      ...(variant === 'expandable' || variant === 'drilldown'
        ? {
            '&.Mui-selected': {
              background: 'transparent',
              color: theme.palette.text.secondary,
            },
            '&.Mui-selected:hover, &:hover': {
              background: customDesignComponentStyles.emptyHovered,
              color: theme.palette.text.secondary,
            },
          }
        : {
            '&:hover': {
              background: customDesignComponentStyles.emptyHovered,
              color: theme.palette.text.secondary,
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              background: customDesignComponentStyles.emptyActive,
            },
            '&.Mui-selected .MuiTypography-root, &.Mui-selected:hover .MuiTypography-root': {
              color: customDesignComponentStyles.mainActive,
            },
          }),
    };
  });

  return (
    <NavigationItemContext.Provider value={item}>
      <Box>
        <NavItemButton selected={isSelected} {...rest} onClick={handleClick}>
          <ListItemAvatar
            sx={{
              minWidth: 18,
              mr: '8px',
              display: 'flex',
              color: 'text.secondary',
              justifyContent: 'center',
              alignItems: 'center',
              '& .MuiSvgIcon-root': {
                width: '18px',
                height: '18px',
              },
            }}
          >
            {icon}
          </ListItemAvatar>
          <ListItemText sx={{ my: 0 }}>
            {typeof title === 'string' ? (
              <Typography
                variant={level > 1 ? 'body2' : 'subtitle2'}
                sx={{
                  color: 'text.secondary',
                }}
              >
                {title}
              </Typography>
            ) : (
              title
            )}
          </ListItemText>
          {/* eslint-disable-next-line no-nested-ternary */}
          {variant === 'expandable' ? (
            <ListItemSecondaryAction>
              {isExpanded ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
            </ListItemSecondaryAction>
          ) : variant === 'drilldown' ? (
            <ListItemSecondaryAction>
              <ArrowRightOutlinedIcon />
            </ListItemSecondaryAction>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}
        </NavItemButton>
        {variant === 'expandable' && !!children && (
          <Collapse in={isExpanded}>
            <Stack p={1} pr={0} spacing={1}>
              {children}
            </Stack>
          </Collapse>
        )}
      </Box>
    </NavigationItemContext.Provider>
  );
}
