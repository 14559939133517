import { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useGridApiContext, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { SxProps } from '@mui/system';
import { Link } from 'react-router-dom';

interface CustomTreeGroupCellProps extends GridRenderCellParams {
  cellText: string;
  cellLink?: string;
  isLink?: boolean;
  showDescendantCount?: boolean;
  textStyle?: SxProps;
}

function CustomTreeGroupCell(params: CustomTreeGroupCellProps) {
  const apiRef = useGridApiContext();
  const { cellText, cellLink, isLink, showDescendantCount, textStyle, ...rest } = params;

  const isNavigationKey = (key: string) =>
    key === 'Home' || key === 'End' || key.indexOf('Arrow') === 0 || key.indexOf('Page') === 0 || key === ' ';

  const handleKeyDown: ButtonProps['onKeyDown'] = (event) => {
    if (event.key === ' ') {
      event.stopPropagation();
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent('cellNavigationKeyDown', rest, event);
    }
  };

  const handleClick: ButtonProps['onClick'] = (event) => {
    apiRef.current.setRowChildrenExpansion(rest.id, !rest.rowNode.childrenExpanded);
    apiRef.current.setCellFocus(rest.id, rest.field);
    event.stopPropagation();
  };

  return (
    <Box
      sx={{ marginLeft: rest.rowNode.depth * 5, display: 'flex', alignItems: 'center' }}
      data-testid='tree-group-cell'
    >
      {rest.rowNode.children && (
        <IconButton onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={-1}>
          {rest.rowNode.childrenExpanded ? (
            <ExpandMoreIcon data-testid='expanded-row-icon' />
          ) : (
            <ChevronRightIcon data-testid='right-arrow-icon' />
          )}
        </IconButton>
      )}
      {isLink && cellLink ? (
        <Link to={cellLink}>
          <Typography
            variant='body2'
            color='text/primary'
            sx={{ marginLeft: rest.rowNode.children ? '5px' : '45px', ...textStyle }}
          >
            {cellText}
            {showDescendantCount && rest.rowNode.children && rest.rowNode.children.length > 0
              ? ` (${rest.rowNode.children.length})`
              : ''}
          </Typography>
        </Link>
      ) : (
        <Typography
          variant='body2'
          color='text/primary'
          sx={{ marginLeft: rest.rowNode.children ? '5px' : '45px', ...textStyle }}
        >
          {cellText}
        </Typography>
      )}
    </Box>
  );
}

export default CustomTreeGroupCell;
