enum ComparisonRange {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  BUSINESS_DATE = 'businessDate',
  PREVIOUS_PERIOD = 'previousPeriod',
  SAME_DATE = 'sameDate',
  SAME_DAY = 'sameDay',
  SALES_FORECAST = 'salesForecast',
  SCHEDULED_HOURS = 'scheduledHours',
}

export default ComparisonRange;
